import React, { useContext } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { LanguageContext, CustomerContext } from "../../../../contexts";
import { useHistory } from "react-router-dom";
const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(9),
  },
  button: {
    width: "80%",
    marginTop: 50,
    maxWidth: 300,
    //border: "4px solid currentColor",
    borderRadius: 0,
    height: "auto",
    //padding: theme.spacing(2, 5),
  },
  TextContainer: {
    width: "80%",
    marginTop: 50,
    borderRadius: 0,
  },
  link: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  buoy: {
    width: 60,
  },
  title: {
    textAlign: "center",
    fontSize: 45,
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    [theme.breakpoints.down(450)]: {
      fontSize: 30,
    },
  },
  description: {
    textAlign: "center",
    fontSize: 20,
    marginTop: 20,
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.regular,
    opacity: 0.7,
    marginBottom: 20,
    [theme.breakpoints.down(450)]: {
      fontSize: 18,
    },
  },
  benefits: {
    fontSize: 22,
    fontFamily: "Roboto-Bold",
    opacity: 0.7,
    textAlign: "left",
    marginLeft: "35%",
    color: "#ff9800",
    marginTop: 10,
    [theme.breakpoints.down(450)]: {
      fontSize: 18,
      marginLeft: 10,
    },
  },
});

function ProductSmokingHero(props) {
  const { classes } = props;
  const { onToggleShopSearch } = useContext(CustomerContext);
  const { dictionary } = useContext(LanguageContext);
  const history = useHistory();
  return (
    <Container className={classes.root} component="section">
      <div className={classes.TextContainer}>
        <Typography className={classes.title}>
          {dictionary.whatIsAllura}
        </Typography>
        <Typography className={classes.description}>
          {dictionary.alluraDescription}
        </Typography>
      </div>
    </Container>
  );
}

ProductSmokingHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles as any)(ProductSmokingHero);
