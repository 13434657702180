import { app } from "../config";

export const resetPass = async ({
  dispatch,
  toggleContinue,
  toggleActivity,
}) => {
  dispatch({ type: "REQUEST" });
  toggleActivity(true, "sending");
  app
    .auth()
    .sendPasswordResetEmail(app.auth().currentUser!.email as any)
    .then(() => {
      toggleActivity(false);
      setTimeout(() => {
        toggleContinue("sendSuccess", "recoverSent", true);
      }, 1000);
    })
    .catch(function (error) {
      dispatch({ type: "REQUEST_FAIL" });
      toggleActivity(false);
      toggleContinue("requestFail", error.code, true);
    });
};
