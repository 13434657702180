import React, { useContext, useEffect, useState } from "react";
import {
  createStyles,
  makeStyles,
  useTheme,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import {
  Checkbox,
  InputBase,
  ListItemText,
  TextField,
  Typography,
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import { AccountContext, DashboardContext } from "../../contexts";
import { BusinessDashboard } from "../../pages";
const CssTextField = withStyles((theme) => ({
  root: theme.outlineTextfield,
}))(TextField);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      // margin: theme.spacing(1),
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      //marginTop: theme.spacing(3),
    },
    label: {
      color: "black",
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      fontSize: 13,
      marginBottom: 5,
    },
    listItem: {
      color: "black",
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.regular,
      fontSize: 15,
    },
  })
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// const spa = [
//   { id: 1, en: "steam/sauna", ar: "sauna" },
//   { id: 2, en: "bath treatment", ar: "bath" },
//   { id: 3, en: "scrub", ar: "scrub" },
//   { id: 4, en: "massage", ar: "massage" },
//   { id: 5, en: "facial", ar: "facial" },
// ] as any;

// const photography = [
//   { id: 1, en: "Wedding Photographer", ar: "Photographer" },
//   { id: 2, en: "Photo Booth Rental", ar: "Photo" },
//   { id: 3, en: "Portrait Photography", ar: "Portrait" },
//   { id: 4, en: "Real Estate Photographer", ar: "Estate" },
//   { id: 5, en: "Event Photographer", ar: "Event" },
// ] as any;

// const saloon = [
//   { id: 1, en: "Haircuts", ar: "Haircuts" },
//   { id: 2, en: "Hair Coloring", ar: "Coloring" },
//   { id: 3, en: "waxing", ar: "waxing" },
//   { id: 4, en: "nail treatments", ar: "nail" },
//   { id: 5, en: "skin care", ar: "skin" },
//   { id: 6, en: "tanning", ar: "tanning" },
//   { id: 7, en: "massage", ar: "massage" },
// ] as any;

// const regions = [
//   { name: "haircuts", priceFrom: 0 },
//   { name: "hairColoring", priceFrom: 0 },
//   { name: "waxing", priceFrom: 0 },
//   { name: "nailTreatment", priceFrom: 0 },
//   { name: "skincare", priceFrom: 0 },
//   { name: "tanning", priceFrom: 0 },
//   { name: "massage", priceFrom: 0 },
//   { name: "steam", priceFrom: 0 },
//   { name: "bathTreatment", priceFrom: 0 },
//   { name: "scrub", priceFrom: 0 },
//   { name: "facial", priceFrom: 0 },
//   { name: "weddingPhotos", priceFrom: 0 },
//   { name: "kidsPhotos", priceFrom: 0 },
//   { name: "gradPhotos", priceFrom: 0 },
//   { name: "eventPhotos", priceFrom: 0 },
//   { name: "portrait", priceFrom: 0 },
//   { name: "weddingPhotos", priceFrom: 0 },
//   { name: "realestatePhotos", priceFrom: 0 },
// ] as any;

const regions = [
  "riyadh",
  "makkah",
  "madinah",
  "qassim",
  "easternProvince",
  "asir",
  "tabuk",
  "hail",
  "northernBorders",
  "jazan",
  "najran",
  "bahah",
  "jawf",
] as any;

// const regions = [
//   { haircuts: { priceFrom: 0, name: "haircuts" } },
//   { eventPhotos: { priceFrom: 0, name: "eventPhotos" } },
//   { kidsPhotos: { priceFrom: 0, name: "kidsPhotos" } },
//   { weddingPhotos: { priceFrom: 0, name: "weddingPhotos" } },
// ] as any;

export default function MultipleSelect({ form, dictionary }) {
  const classes = useStyles();

  return (
    <FormControl fullWidth={true} className={classes.formControl}>
      <Typography className={classes.label}>
        {dictionary.businessRegion}
      </Typography>
      <Controller
        control={form.control}
        name="businessRegion"
        rules={{ required: true, validate: (value) => value.length >= 3 }}
        defaultValue={[]}
        render={({ field }) => {
          return (
            <CssTextField
              {...field}
              select
              variant="outlined"
              SelectProps={{
                classes: { root: classes.listItem },
                multiple: false,
                renderValue: (selected: any) => {
                  return dictionary[selected];
                },
              }}
            >
              {regions.map((region, i) => (
                <MenuItem key={i} value={region}>
                  <Checkbox checked={field.value.includes(region)} />
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography className={classes.listItem}>
                        {dictionary[region]}
                      </Typography>
                    }
                    className={classes.listItem}
                  />
                </MenuItem>
              ))}
            </CssTextField>
          );
        }}
      />
      {form.formState.errors.businessRegion && (
        <Typography
          className={`form-error-text animate__animated animate__headShake`}
        >
          {dictionary.invalidBusinessRegion}
        </Typography>
      )}
    </FormControl>
  );
}
