import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import ReactDOM from "react-dom";
import { Images } from "../themes";

export const useStyles = makeStyles((theme) => ({
  video: {
    position: "relative",
    width: "100%",
    height: "65vh",
    objectFit: "cover",
    background: "#1b1e3e",
    filter: "brightness(50%)",
  },
  image: {
    position: "relative",
    height: 400,
    width: "100vw",
    // backgroundImage: `url(${Images.dark_bg})`,
    //backgroundPosition: "center",
    // backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    // resizeMode: "stretch",
  },
}));

const BackgroundVideo = () => {
  const classes = useStyles();
  let video = ReactDOM.findDOMNode(this) as any;
  const [index, setIndex] = useState(0);
  const videos = [
    "https://storage.coverr.co/videos/3Ngn8eIJ5OE6QDAR4XNv5ZCwen6A01jE6?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6Ijg3NjdFMzIzRjlGQzEzN0E4QTAyIiwiaWF0IjoxNjEyNzExNTQ4fQ.Yo7sYd6OBeylr-H__bttUogKl6ehlU583bRIGvj8A94",
    "https://storage.coverr.co/videos/GS2pP025ITmoYs5fp5214fxKmjSRHs1X02?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6Ijg3NjdFMzIzRjlGQzEzN0E4QTAyIiwiaWF0IjoxNjE0NzkxNDA0fQ.StvTg6AUSyL5muGD5mMeggn1ayKZQ1W9GVBtK4s37gs",
    "https://storage.coverr.co/videos/twSb9twe00gkX61K7dRQu5bAhO01y3I8YW?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6Ijg3NjdFMzIzRjlGQzEzN0E4QTAyIiwiaWF0IjoxNjE0NzkxODMxfQ.JSZd1dzUERAixkDTazKAPz7lK8ryAE4KH8d1trBMj9E",
    "https://storage.coverr.co/videos/4Jon02QraYK9fxy53D3STP4iYSchDzDVI?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6Ijg3NjdFMzIzRjlGQzEzN0E4QTAyIiwiaWF0IjoxNjE0NzkxODQ2fQ.3Crb0gqkAztImWunxwp6m3RgXCcZqTAqpLe7dv5-A1c",
    "https://storage.coverr.co/videos/qd34lvILiZ4LHXLXCxLGXGnC4009D2YXr?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6Ijg3NjdFMzIzRjlGQzEzN0E4QTAyIiwiaWF0IjoxNjE0NzkzOTUxfQ.0f4s2-GMGyZ4sa5bAdH0zJS08rOHtvPx_ahnMmVIIV8",
    "https://storage.coverr.co/videos/8htqeKlsTmHPeQwLXwRJG2bXsvaiXZs6?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6Ijg3NjdFMzIzRjlGQzEzN0E4QTAyIiwiaWF0IjoxNjE0NzkzOTU2fQ.PZiF341z0363G_-TB-mhb1ERqgQ1_ZRf1X4DYUzbdrc",
    "https://storage.coverr.co/videos/oN2J016V1mOI8q019YCGJrCqfncgwWEjYb?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6Ijg3NjdFMzIzRjlGQzEzN0E4QTAyIiwiaWF0IjoxNjE0Nzk0MDA0fQ.KdHp46yzbbMU6wNgxGrBEQPYYVOlk-Va7gbRgD0A33c",
    "https://storage.coverr.co/videos/KwomSWl00OtjZr5uMZ1QIFJwQZcPWyqK2?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6Ijg3NjdFMzIzRjlGQzEzN0E4QTAyIiwiaWF0IjoxNjE0Nzk0MDA5fQ.tozJni8YFaNloaN_f68rTrxueDsSBTSXAtx1TNeoYHk",
  ];
  const nextVideo = () => {
    if (index === videos.length - 1) return setIndex(0);

    setIndex(index + 1);
  };
  return (
    <div style={{ backgroundColor: "white" }}>
      {window.innerWidth >= 1000 ? (
        <video
          src={videos[index]}
          onEnded={() => {
            nextVideo();
          }}
          autoPlay
          muted
          className={classes.video}
        />
      ) : (
        <div className={classes.image} />
      )}
    </div>
  );
};

export default BackgroundVideo;
