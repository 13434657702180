import { app, db } from "../config";

export const updateLanguage = async (
  language,
  { toggleContinue, toggleToast }
) => {
  // if (
  //   state.businessOwner.personalPhone === submit.data.personalPhone &&
  //   state.businessOwner.email === submit.data.email
  // )
  //   return toggleContinue("warn", "noChanges", true);
  // let newLanguage = language === "ar" ? "en" : "ar";
  let date = Date.now().toString();
  let body = {
    uid: app.auth().currentUser!.uid,
    language: language,
    updatedAt: date,
  };

  db.collection("business_owners")
    .doc(app.auth().currentUser!.uid)
    .update(body)
    .then(() => {
      toggleToast("updated", "success");
    })
    .catch(function (error) {
      console.log(error.code);
      toggleContinue("requestFail", error.code, true);
    });
};
