import React, { useContext } from "react";
import {
  Button,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { ShopSearchBox, Typewriter, WelcomeToolbar } from "..";
import { Images } from "../../themes";
import { LanguageContext } from "../../contexts";
const useStyles = makeStyles((theme) => ({
  root: { paddingBottom: 100 },
  support: {
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    fontSize: 15,
    color: "#1b1e3e",
    textAlign: "center",
    marginTop: 5,
  },
  location: {
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.regular,
    fontSize: 15,
    color: "#1b1e3e",
    textAlign: "center",
    marginTop: 150,
  },
  disclaimer: {
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.regular,
    fontSize: 10,
    color: "#1b1e3e",
    textAlign: "center",
  },
}));
const FooterSection = () => {
  const classes = useStyles();
  const { dictionary } = useContext(LanguageContext);

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={5}
        style={{ marginTop: "5%", paddingRight: "10%", paddingLeft: "10%" }}
      >
        <Grid item sm={12} xs={12}>
          <Typography className={classes.location}>
            {dictionary.made}
          </Typography>
        </Grid>
        <Grid item sm={12} xs={12} style={{ maxWidth: 500 }}>
          <Typography className={classes.disclaimer}>
            {dictionary.disclamer}
          </Typography>
        </Grid>
        <Grid item sm={12} xs={12}>
          <Typography className={classes.support}>
            {dictionary.supportemail}
          </Typography>
          <Typography className={classes.support}>
            support@allura.app
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default FooterSection;
