import React, { useContext } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {
  InputBase,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import { AccountContext } from "../../contexts";
const CssTextField = withStyles((theme) => ({
  root: theme.outlineTextfield,
}))(TextField);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    label: {
      color: "black",
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      fontSize: 13,
      marginBottom: 5,
    },
    listItem: {
      color: "black",
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.regular,
      fontSize: 15,
    },
  })
);

export default function SimpleSelect({ form, dictionary }) {
  const classes = useStyles();

  const items = [] as any;
  for (let i = 0; i < 7; i++) {
    var date = new Date();
    date.setDate(date.getDate() + i);
    const month = date.toLocaleString("default", { month: "long" });
    const dateValue =
      date.getFullYear() +
      "-" +
      ((date.getMonth() % 12) + 1) +
      "-" +
      date.getDate();
    if (i == 0) {
      items.push(
        <MenuItem className={classes.listItem} value={dateValue} key={i}>
          {dictionary.today +
            " (" +
            dictionary[month] +
            " " +
            date.getDate() +
            ") "}
        </MenuItem>
      );
    } else {
      items.push(
        <MenuItem className={classes.listItem} value={dateValue} key={i}>
          {dictionary[month] + " " + date.getDate()}
        </MenuItem>
      );
    }
  }
  return (
    <FormControl fullWidth={true} className={classes.formControl}>
      <Typography className={classes.label}>{dictionary.setDay}</Typography>
      <Controller
        control={form.control}
        name="bookingDate"
        rules={{ required: true }}
        defaultValue={1}
        render={({ field }) => {
          return (
            <CssTextField
              {...field}
              select
              variant="outlined"
              className={classes.listItem}
              SelectProps={{
                classes: { root: classes.listItem },
                multiple: false,
              }}
            >
              {items}
            </CssTextField>
          );
        }}
      />
      {form.formState.errors.bookingDate && (
        <Typography
          className={`form-error-text animate__animated animate__headShake`}
        >
          {dictionary.fieldRequired}
        </Typography>
      )}
    </FormControl>
  );
}
