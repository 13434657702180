import { app, db } from "..";

export const searchBusinesses = async (text, { dispatch, toggleContinue }) => {
  // dispatch({
  //   type: "TOGGLE_LOADING_LIST",
  //   isMoreItems: true,
  // });
  console.log("Searching ...")
  const cleanText = text.toLowerCase();
  const regex = /^[\u0621-\u064A\u0660-\u0669 ]+$/.test(cleanText);
  const startFrom = regex ? "businessNameAr" : "businessNameEn_insensitive";
  const endTo = regex ? "\u064A" : "\uf8ff";
  const ordering = regex ? "businessNameAr" : "businessNameEn_insensitive";
  db.collection("businesses")
    .where(startFrom, ">=", cleanText)
    .where(startFrom, "<=", cleanText + endTo)
    .orderBy(ordering)
    .limit(3)
    .get({ source: "server" }) // this "source" is for forcing data from server instead of cache
    .then(async (querySnapshot) => {
      let items = [] as any;
      if (querySnapshot.docs[0]) {
        items = items.concat(
          querySnapshot.docs.map((doc) => {
            return Object.assign({}, doc.data());
          })
        );
      }
      dispatch({
        type: "SET_MULTI_BUSINESS",
        payload: items,
        isMoreItems: querySnapshot.docs[0] ? true : false,
      });
    })
    .catch(function (error) {
      toggleContinue("requestFail", error.code, true);
    });
};
