import React, { createContext, useReducer, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  signup,
  signin,
  recover,
  resetPass,
  updateContact,
  getBusinessOwnerInfo,
  deleteAccount,
} from "../services";
import { LanguageContext, NotificationContext } from ".";
import { AuthContext } from "./AuthContext";
import { ThemeContext } from "./ThemeContext";

const initialState = {
  businessOwner: null as any,
  businessRegisterStep: 1,
  businessLocation: { lat: 0, lng: 0 },
  registerForm: false,
  loginForm: false,
  recoverForm: false,
  isLoading: false,
  multiStepFormData: {
    businessRegion: [],
    businessServices: [],
    businessType: [],
  },
  onSetBusinessLocation: (...params) => {},
  onRegisterUser: (params) => {},
  onLoginUser: (params) => {},
  onLogoutUser: () => {},
  onRecoverUser: (params) => {},
  onDeleteUser: (params) => {},
  onResetPass: () => {},
  onUpdateContact: (params) => {},
  onGetBusinessOwnerInfo: () => {},
  toggleBusinessRegisterStep: (params) => {},
  onSetMultForm: (params) => {},
};

export const AccountContext = createContext(initialState);

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "REQUEST_SUCCESS":
      return {
        ...state,
        isLoading: false,
        businessOwner: action!.payload,
      };
    case "REQUEST_FAIL":
      return {
        ...state,
        isLoading: false,
        businessRegisterStep: 1,
      };
    case "REQUEST":
      return { ...state, isLoading: true };
    case "BUSINESS_REGISTER_STEP":
      return {
        ...state,
        businessRegisterStep: action.payload,
      };
    case "SET_BUSINESS_LOCATION":
      return {
        ...state,
        businessLocation: {
          lat: action.payload.lat,
          lng: action.payload.lng,
        },
      };
    case "SET_MULTI_FORM":
      return {
        ...state,
        multiStepFormData: {
          ...state.multiStepFormData,
          ...action.payload,
        },
      };
    case "RESET":
      return initialState;
    default:
      break;
  }
  return state;
};

export const AccountContextProvider = (props: any) => {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { checkTokenStatus, clearToken } = useContext(AuthContext);
  const { dictionary, language, onChangeLanguage } =
    useContext(LanguageContext);
  const { isDarkMode } = useContext(ThemeContext);
  const { toggleContinue, toggleToast, toggleActivity, toggleConfirm } =
    useContext(NotificationContext);
  let defaultParams = {
    dispatch,
    state,
    dictionary,
    history,
    toggleContinue,
    toggleToast,
    toggleActivity,
    checkTokenStatus,
    language,
    isDarkMode,
    onChangeLanguage,
  };

  state.onSetBusinessLocation = (lat, lng) => {
    dispatch({
      type: "SET_BUSINESS_LOCATION",
      payload: {
        lat: lat,
        lng: lng,
      },
    });
  };

  state.onSetMultForm = (data) => {
    dispatch({ type: "SET_MULTI_FORM", payload: data });
  };

  state.toggleBusinessRegisterStep = (data) => {
    dispatch({ type: "BUSINESS_REGISTER_STEP", payload: data });
  };

  state.onRegisterUser = (data) => {
    signup(data, defaultParams);
  };

  state.onLoginUser = (submit) => {
    signin(submit, defaultParams);
  };

  state.onLogoutUser = () => {
    history.push(`/welcome`);
    clearToken();
  };

  state.onRecoverUser = (submit) => {
    recover(submit, defaultParams);
  };

  state.onUpdateContact = (submit) => {
    updateContact(submit, defaultParams);
  };

  state.onGetBusinessOwnerInfo = () => {
    getBusinessOwnerInfo(defaultParams);
  };
  state.onResetPass = () => {
    resetPass(defaultParams);
  };

  state.onDeleteUser = (submit) => {
    deleteAccount(submit, defaultParams);
  };
  return (
    <AccountContext.Provider
      value={{
        ...state,
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};
