import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  createStyles,
  Grid,
  IconButton,
  List,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { DashboardContext } from "../../../contexts";
import { useForm } from "react-hook-form";

import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import {
  BusinessNameArTextfield,
  BusinessNameEnTextfield,
  BusinessTypeSelect,
  BusinessPhoneTextfield,
  BusinessEmailTextfield,
  AlluraLinkTextfield,
  BusinessRegionSelect,
} from "../..";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      textAlign: "center",
      fontSize: 25,
      margin: 10,
    },
    details: {
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.regular,
      textAlign: "center",
    },
    button: {
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      textAlign: "center",
      margin: 10,
    },
    paper: { borderRadius: 25, paddingBottom: 20 },
    basicInfoEditButtonContainer: {
      position: "absolute",
      minWidth: 275,
      maxWidth: 670,
      width: "90vw",
      marginTop: 10,
      [theme.breakpoints.down(850)]: {
        fontSize: 17,
        minWidth: 275,
        maxWidth: 380,
        width: "87vw",
        marginTop: 10,
      },
    },
    basicInfoEditButton: {
      backgroundColor: "#66bb6a",
      "&:hover": {
        backgroundColor: "#66bb6a",
      },
    },
  })
);

export default function ScrollDialog({ business, dictionary }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { onEditInfo, onDeleteBusiness } = useContext(DashboardContext);
  const handleClickOpen = () => () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = () => {
    onDeleteBusiness(business);
  };
  const form = useForm({
    defaultValues: {
      bid: business.bid,
      businessNameEn: business.businessNameEn,
      businessNameAr: business.businessNameAr,
      businessType: business.businessType,
      businessPhone: business.businessPhone,
      businessEmail: business.businessEmail,
      businessRegion: business.businessRegion,
      link: business.link,
    },
    reValidateMode: "onBlur",
    shouldUnregister: false,
  });
  const onSubmit = (data) => {
    console.log(data);
    data.businessNameEn_insensitive = data.businessNameEn.toLowerCase();
    const submit = { data, form, business };
    onEditInfo(submit);
    handleClose();
  };
  return (
    <div>
      <input type="hidden" {...form.register("bid")} />
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
        spacing={0}
        className={classes.basicInfoEditButtonContainer}
      >
        <Grid item>
          <IconButton
            aria-label="edit"
            className={classes.basicInfoEditButton}
            onClick={handleClickOpen()}
          >
            <EditIcon style={{ color: "white" }} />
          </IconButton>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <IconButton
          aria-label="close"
          style={{ position: "absolute", top: 0, right: 0 }}
          onClick={handleClose}
        >
          <CloseIcon style={{ fontSize: 40 }} />
        </IconButton>
        <DialogTitle id="max-width-dialog-title">
          <Typography className={classes.title}>
            {dictionary["basicInfo"]}
          </Typography>
          <div style={{ paddingRight: "20%", paddingLeft: "20%" }}>
            <Button
              onClick={handleDelete}
              variant="outlined"
              className="btn-danger"
              // startIcon={<DeleteIcon />}
              style={{
                position: "relative",
                //width: 150,
                height: 40,
                borderRadius: 50,
              }}
            >
              {dictionary.deleteBusiness}
            </Button>
          </div>
        </DialogTitle>
        <DialogContent dividers={true}>
          <List>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={3}
            >
              <Grid item xs={12} sm={6} style={{ width: "95%" }}>
                <BusinessNameArTextfield form={form} dictionary={dictionary} />
              </Grid>
              <Grid item xs={12} sm={6} style={{ width: "95%" }}>
                <BusinessNameEnTextfield form={form} dictionary={dictionary} />
              </Grid>
              <Grid item xs={12} sm={6} style={{ width: "95%" }}>
                <BusinessPhoneTextfield form={form} dictionary={dictionary} />
              </Grid>
              <Grid item xs={12} sm={6} style={{ width: "95%" }}>
                <BusinessEmailTextfield form={form} dictionary={dictionary} />
              </Grid>
              <Grid item xs={12} style={{ width: "95%" }}>
                <AlluraLinkTextfield form={form} dictionary={dictionary} />
              </Grid>
              <Grid item xs={12} style={{ width: "95%" }}>
                <BusinessRegionSelect form={form} dictionary={dictionary} />
              </Grid>
              <Grid item xs={12} style={{ width: "95%" }}>
                <BusinessTypeSelect form={form} dictionary={dictionary} />
              </Grid>
            </Grid>
          </List>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid item style={{ width: "60%", minWidth: 250 }}>
              <Button
                onClick={form.handleSubmit(onSubmit)}
                variant="outlined"
                className="btn-primary"
              >
                {dictionary.confirm}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}
