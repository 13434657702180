import React, { useContext } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {
  InputBase,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import { AccountContext } from "../../contexts";
const CssTextField = withStyles((theme) => ({
  root: theme.outlineTextfield,
}))(TextField);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    label: {
      color: "black",
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      fontSize: 13,
      marginBottom: 5,
    },
    listItem: {
      color: "black",
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.regular,
      fontSize: 15,
    },
  })
);

export default function SimpleSelect({ form, dictionary }) {
  const classes = useStyles();

  // const { onSetMultForm, multiStepFormData } = useContext(AccountContext);
  // const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
  //   onSetMultForm({
  //     businessServices: [],
  //     businessType: event.target.value,
  //   });
  //   // console.log(multiStepFormData);
  // };
  const items = [] as any;
  for (let i = 0; i < 15; i++) {
    items.push(
      <MenuItem className={classes.listItem} value={i + 1} key={i}>
        {i + 1} {dictionary.booking}
      </MenuItem>
    );
  }
  return (
    <FormControl fullWidth={true} className={classes.formControl}>
      <Typography className={classes.label}>
        {dictionary.maxBookings}
      </Typography>
      <Controller
        control={form.control}
        name="bookingMax"
        rules={{ required: true }}
        defaultValue=""
        render={({ field }) => {
          return (
            <CssTextField
              {...field}
              select
              variant="outlined"
              className={classes.listItem}
              SelectProps={{
                classes: { root: classes.listItem },
                multiple: false,
              }}
            >
              {items}
            </CssTextField>
          );
        }}
      />
      {form.formState.errors.bookingMax && (
        <Typography
          className={`form-error-text animate__animated animate__headShake`}
        >
          {dictionary.fieldRequired}
        </Typography>
      )}
    </FormControl>
  );
}
