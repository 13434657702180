import React, { useContext } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { LanguageContext, NotificationContext } from "../../contexts";
import Typography from "@material-ui/core/Typography";

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  text: {
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
  },
}));

export default function CustomizedSnackbars() {
  const classes = useStyles();
  const { dictionary } = useContext(LanguageContext);
  const { toggleToast, messageDetails, isToast, toastType } =
    useContext(NotificationContext);
  return (
    <div className={classes.root}>
      <Snackbar
        open={isToast}
        autoHideDuration={2000}
        onClose={() => toggleToast(messageDetails, toastType)}
      >
        <Alert
          onClose={() => toggleToast(messageDetails, toastType)}
          severity={toastType}
        >
          <Typography className={classes.text}>
            {dictionary[messageDetails]}
          </Typography>
        </Alert>
      </Snackbar>
    </div>
  );
}
