import React from "react";
import {
  Button,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { ShopSearchBox, Typewriter, WelcomeToolbar } from "..";
import { Images } from "../../themes";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    fontFamily: "NotoSansJP-Regular",
    fontSize: 60,
    color: "#1b1e3e",
    textAlign: "center",
    marginTop: 150,
    [theme.breakpoints.down(1000)]: {
      fontSize: 40,
    },
    [theme.breakpoints.down(500)]: {
      fontSize: 30,
    },
  },
  subtext: {
    fontFamily: "NotoSansJP-Light",
    fontSize: 30,
    color: "#1b1e3e",
    textAlign: "center",
    [theme.breakpoints.down(1000)]: {
      fontSize: 24,
    },
    [theme.breakpoints.down(500)]: {
      fontSize: 16,
    },
  },
}));
const GetStartedSection = ({ dictionary }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <Grid
        container
        justify="center"
        alignItems="center"
        spacing={5}
        style={{ marginTop: "5%", paddingRight: "10%", paddingLeft: "10%" }}
      >
        <Grid item sm={12} xs={12}>
          <Typography className={classes.title}>
            Share your business with the world.
          </Typography>
        </Grid>
        <Grid item sm={12} xs={12}>
          <Typography className={classes.subtext}>its totally free</Typography>
        </Grid>
        <Grid item sm={3} xs={10} style={{ minWidth: 300 }}>
          <Button
            onClick={() => history.push(`/register`)}
            type="submit"
            variant="outlined"
            className="btn-primary"
          >
            GET STARTED
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default GetStartedSection;
