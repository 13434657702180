import React, { useContext, useEffect } from "react";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import { purple } from "@material-ui/core/colors";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch, { SwitchClassKey, SwitchProps } from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { DashboardContext } from "../../../contexts";
import { SubmitButton } from "../..";
import { Controller } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  statusText: {
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    fontSize: 13,
  },
}));

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 10,
      width: 50,
      height: 30,
      padding: 0,
      display: "flex",
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      transform: "translateX(4px) translateY(3px)",
      "&$checked": {
        transform: "translateX(23px) translateY(3px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 20,
      height: 20,
      boxShadow: "none",
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 50,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  })
)(Switch);

export default function CustomizedSwitches({ day, dictionary, form }) {
  const classes = useStyles();
  const [state, setState] = React.useState(dictionary.workDay);
  const checkStatus = (checked) => {
    checked ? setState(dictionary.workDay) : setState(dictionary.dayOff);
  };
  useEffect(() => {
    checkStatus(day.openValue);
  }, [form.control]);
  return (
    <div style={{ textAlign: "center" }}>
      <FormControlLabel
        control={
          <Controller
            name={day.openName}
            control={form.control}
            defaultValue={day.openValue}
            render={({ field }) => (
              <AntSwitch
                {...field}
                defaultChecked={day.openValue}
                onChange={(e) => {
                  field.onChange(e.target.checked);
                  checkStatus(e.target.checked);
                }}
              />
            )}
          />
        }
        label={<span className={classes.statusText}>{state}</span>}
      />
    </div>
  );
}
