import { app } from "../config";

export const recover = async (
  submit,
  { dispatch, history, toggleContinue, toggleActivity }
) => {
  dispatch({ type: "REQUEST" });
  toggleActivity(true, "sending");
  app
    .auth()
    .sendPasswordResetEmail(submit.data.email)
    .then(() => {
      history.push(`/`);
      dispatch({
        type: "REQUEST_SUCCESS",
      });
      toggleActivity(false);
      setTimeout(() => {
        toggleContinue("sendSuccess", "recoverSent", true);
      }, 1000);

      submit.form.reset();
    })
    .catch(function (error) {
      dispatch({ type: "REQUEST_FAIL" });
      toggleActivity(false);
      toggleContinue("requestFail", error.code, true);
    });
};
