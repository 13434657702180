import React, { useContext } from "react";
import { AccountContext } from "../contexts";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

const TransitionButton = ({ title, transition }) => {
  const history = useHistory();

  return (
    <Button
      className="btn-clear"
      variant="contained"
      onClick={() => history.push(`/${transition}`)}
    >
      {title}
    </Button>
  );
};

export default TransitionButton;
