import { app, db } from "../config";

export const deleteAccount = async (
  submit,
  { dispatch, history, toggleContinue, toggleActivity, toggleToast }
) => {
  dispatch({ type: "REQUEST" });
  toggleActivity(true, "deleting");
  const p0 = app
    .auth()
    .signInWithEmailAndPassword(
      app.auth().currentUser!.email as any,
      submit.data.password
    )
    .then(function (userCredential) {
      app.auth().currentUser!.delete();
    });
  const p1 = db
    .collection("business_owners")
    .doc(app.auth().currentUser!.uid)
    .delete();
  const p2 = db
    .collection("businesses")
    .where("uid", "==", app.auth().currentUser!.uid)
    .get()
    .then(function (querySnapshot) {
      // Once we get the results, begin a batch
      var batch = db.batch();

      querySnapshot.forEach(function (doc) {
        // For each doc, add a delete operation to the batch
        batch.delete(doc.ref);
      });

      // Commit the batch
      batch.commit();
    });
  const p3 = db
    .collection("links")
    .where("uid", "==", app.auth().currentUser!.uid)
    .limit(5)
    .get()
    .then(function (querySnapshot) {
      // Once we get the results, begin a batch
      var batch = db.batch();

      querySnapshot.forEach(function (doc) {
        // For each doc, add a delete operation to the batch
        batch.delete(doc.ref);
      });
      batch.commit();
    });
  const promises = [p0, p1, p2, p3] as any;
  const finalPromise = Promise.all(promises);
  finalPromise
    .then(() => {
      toggleActivity(false);
      toggleToast("deleted", "success");
      history.replace(`/`);
    })
    .catch(function (error) {
      toggleActivity(false);
      dispatch({ type: "REQUEST_FAIL" });
      console.log(error.code);
      toggleContinue("requestFail", error.code, true);
    });
};
