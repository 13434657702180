import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: "center",
    fontFamily: "YouthTouch-Regular",
    fontSize: 200,
    color: theme.colors.primary
  },
  titleGrid: {
    height: "30vh",
    width: "100vw",
  },
}));

const Logo = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      justify="center"
      alignItems="flex-end"
      className={classes.titleGrid}
    >
      <Grid item>
        <Typography className={classes.title}>Muvi</Typography>
      </Grid>
    </Grid>
  );
};

export default Logo;
