// @ts-nocheck
import React, { useContext } from "react";
import { makeStyles, withStyles, Theme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Grid, IconButton, List } from "@material-ui/core";
import { Images } from "../../themes";
import { BookingsTab, InfoTab } from "..";
import EditAccountModal from "./account/EditAccountModal";
import EditAccount from "./account/EditAccount";
import { AccountContext, DashboardContext } from "../../contexts";
import AddBusinessButton from "./businesses/AddBusinessButton";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ height: "90vh", width: "100%", backgroundColor: "#F5F6F8" }}
    >
      {children}
    </div>
  );
}
interface StyledTabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

const StyledTabs = withStyles((theme) => ({
  root: {
    paddingTop: 0,
    // paddingLeft: 38,
    // paddingRight: 38,
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: "black",
    },
  },
}))((props: StyledTabsProps) => (
  <Tabs
    variant="fullWidth"
    {...props}
    TabIndicatorProps={{ children: <span /> }}
  />
));

interface StyledTabProps {
  label: string;
}

const StyledTab = withStyles((theme: Theme) => ({
  root: {
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    textTransform: "none",
    color: "black",
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    bottomBorderWidth: 3,
    bottomBorderColor: "blue",
    "&:focus": {
      opacity: 1,
    },
  },
}))(Tab);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100vw",
    [theme.breakpoints.up(850)]: {
      display: "none",
    },
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  demo2: {
    backgroundColor: theme.palette.background.paper,
  },
  businessStoreLink: {
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    color: "#5c6bc0",
    marginLeft: 60,
    marginTop: 40,
  },
  shareButton: {
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    //backgroundColor: "transparent",
    borderColor: "black",
    color: "black",
    borderWidth: 2,
    borderRadius: 5,
    marginLeft: 30,
    marginRight: 10,
    opacity: 0.7,
    height: 30,
    fontSize: 13,
    textTransform: "none",
  },
}));

export default function CustomizedTabs({
  dictionary,
  history,
  verticalTab,
  onLogoutUser,
  toggleConfirm,
}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { businessOwner } = useContext(AccountContext);
  const { businesses, isMoreItems } = useContext(DashboardContext);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <div className={classes.demo2}>
        {/* <EditAccountModal /> */}
        {/* <Grid
          direction="row"
          container
          justify="flex-end"
          alignItems="center"
          style={{
            position: "absolute",
            top: 10,
            right: 20,
            width: 50,
          }}
          spacing={2}
        >
          <Grid item>
            <IconButton>
              <img
                onClick={() => {
                  toggleConfirm("logout", "logout?", onLogoutUser);
                }}
                src={Images.exit}
                style={{
                  width: 40,
                  height: 40,
                }}
              />
            </IconButton>
          </Grid>
        </Grid> */}
        {/* <Grid direction="column" container justify="center" alignItems="center">
          <Grid item>
            <img
              src={Images.logo}
              style={{
                width: 70,
                height: 70,
                marginBottom: 20,
                marginTop: 20,
              }}
            />
          </Grid>
        </Grid> */}
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="styled tabs example"
        >
          <StyledTab
            label={dictionary.businesses}
            icon={
              <img
                src={Images.shop}
                style={{
                  marginTop: 10,
                  width: 30,
                  height: 30,
                  marginBottom: 10,
                }}
              />
            }
          />

          <StyledTab
            label={dictionary.bookings}
            icon={
              <img
                src={Images.appointment}
                style={{
                  marginTop: 10,
                  width: 30,
                  height: 30,
                  marginBottom: 10,
                }}
              />
            }
          />
          <StyledTab
            label={dictionary.account}
            icon={
              <img
                src={Images.user}
                style={{
                  marginTop: 10,
                  width: 30,
                  height: 30,
                  marginBottom: 10,
                }}
              />
            }
          />
        </StyledTabs>
        {/* <Typography className={classes.padding} /> */}
        <TabPanel value={value} index={0}>
          <List style={{ maxHeight: "100%", overflow: "auto" }}>
            <InfoTab dictionary={dictionary} history={history} />
          </List>
          {/* {!isMoreItems && <AddBusinessButton />} */}
        </TabPanel>
        <TabPanel value={value} index={1}>
          <List style={{ maxHeight: "100%", overflow: "auto" }}>
            <BookingsTab dictionary={dictionary} history={history} />
          </List>
        </TabPanel>
        <TabPanel value={value} index={2}>
          {businessOwner && <EditAccount />}
        </TabPanel>
        <TabPanel value={value} index={3}></TabPanel>
      </div>
    </div>
  );
}
