import { db, app } from "../config";

export const editInfo = async (
  submit,
  { dispatch, state, toggleContinue, toggleToast, toggleActivity }
) => {
  dispatch({
    type: "REQUEST",
  });
  let date = Date.now().toString();
  let body = {
    ...submit.data,
    updatedAt: date,
  };
  let linkBody = {
    bid: submit.data.bid,
    uid: app.auth().currentUser!.uid,
  };
  let promises = [] as any;
  const p1 = db.collection("businesses").doc(submit.data.bid).update(body);
  const p2 = db.collection("businesses_history").add(body);
  promises = [p1, p2];

  if (!submit.business)
    return updateBusinessInfoOnly(
      promises,
      state,
      submit,
      dispatch,
      toggleContinue,
      toggleToast,
      body
    );

  if (submit?.business.link == submit?.data.link.toLowerCase()) {
    updateBusinessInfoOnly(
      promises,
      state,
      submit,
      dispatch,
      toggleContinue,
      toggleToast,
      body
    );
  } else {
    Object.assign(linkBody, { link: submit.data.link.toLowerCase() });
    db.collection("links")
      .doc(submit.data.link.toLowerCase())
      .get()
      .then((doc) => {
        if (!doc.exists) {
          const p3 = db
            .collection("links")
            .where("link", "==", submit.business.link)
            .limit(5)
            .get()
            .then(function (querySnapshot) {
              var batch = db.batch();
              querySnapshot.forEach(function (doc) {
                batch.delete(doc.ref);
              });
              batch.commit();
            })
            .then(() => {
              db.collection("links")
                .doc(submit.data.link.toLowerCase())
                .set(linkBody)
                .then(() => {
                  db.collection("links_history").add(linkBody);
                });
            });
          // const p4 = db
          //   .collection("links")
          //   .doc(submit.data.link.toLowerCase())
          //   .set(linkBody);
          // const p5 = db.collection("links_history").add(linkBody);
          promises = [p1, p2, p3];
          const finalPromise = Promise.all(promises);
          finalPromise
            .then(() => {
              let updatedList = state.businesses;
              let index = updatedList.findIndex(
                (x) => x.bid === submit.data.bid
              );
              Object.assign(updatedList[index], body);
              dispatch({
                type: "UPDATE_LIST",
                payload: updatedList,
              });
              toggleToast("updated", "success");
            })
            .catch((error) => {
              console.log(error);
              //toggleActivity(false);
              dispatch({ type: "REQUEST_FAIL" });
              toggleContinue("requestFail", error.code, true);
            });
        } else {
          toggleContinue("requestFail", "linkExists", true);
        }
      });
  }
};

const updateBusinessInfoOnly = (
  promises,
  state,
  submit,
  dispatch,
  toggleContinue,
  toggleToast,
  body
) => {
  const finalPromise = Promise.all(promises);
  finalPromise
    .then(() => {
      let updatedList = state.businesses;
      let index = updatedList.findIndex((x) => x.bid === submit.data.bid);
      Object.assign(updatedList[index], body);
      dispatch({
        type: "UPDATE_LIST",
        payload: updatedList,
      });
      toggleToast("updated", "success");
    })
    .catch((error) => {
      console.log(error);
      //toggleActivity(false);
      dispatch({ type: "REQUEST_FAIL" });
      toggleContinue("requestFail", error.code, true);
    });
};
