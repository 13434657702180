import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, withStyles } from "@material-ui/core";
import { Controller } from "react-hook-form";

const CssTextField = withStyles((theme) => ({
  root: theme.outlineTextfield,
}))(TextField);

const PasswordAgainTextfield = ({ form, dictionary }) => {
  const handleChange = (e) => {
    e.preventDefault();
  };
  return (
    <div>
      <Controller
        name="passwordAgain"
        control={form.control}
        rules={{
          required: true,
          validate: (value) => value === form.watch("password"),
        }}
        render={({ field }) => (
          <CssTextField
            {...field}
            onCut={handleChange}
            onCopy={handleChange}
            onPaste={handleChange}
            type="password"
            fullWidth={true}
            label={dictionary.passwordAgain}
            variant="outlined"
          />
        )}
      />
      {form.formState.errors.passwordAgain && (
        <Typography
          className={`form-error-text animate__animated animate__headShake`}
        >
          {dictionary.invalidPasswordAgain}
        </Typography>
      )}
    </div>
  );
};

export default PasswordAgainTextfield;
