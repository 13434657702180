import React, { createContext, useContext, useEffect, useReducer } from "react";
import { dictionaryList } from "../services/languages";
import { updateLanguage, app } from "../services";
import { NotificationContext } from ".";
import { fonts } from "../themes";
import { AccountContext } from "./AccountContext";
import { AuthContext } from "./AuthContext";
import firebase from "firebase";

const initialState = {
  direction: "rtl",
  language: "ar",
  font: fonts["ar"],
  dictionary: dictionaryList.ar,
  onChangeLanguage: (lang: string) => {},
  onSetNewLanguage: (lang: string) => {},
};

export const LanguageContext = createContext(initialState);

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "SET_LANGUAGE":
      return {
        ...state,
        language: action.language,
        direction: action.direction,
        font: action.font,
        dictionary: action.dictionary,
      };
    default:
      break;
  }
  return state;
};

export const LanguageContextProvider = (props: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { toggleContinue, toggleToast } = useContext(NotificationContext);

  let defaultParams = {
    toggleContinue,
    toggleToast,
  };
  const onChangeLanguage = (lang: string) => {
    const newDirection = lang === "ar" ? "rtl" : "ltr";
    const newFont = (fonts as any)[lang];
    const newDictionary = (dictionaryList as any)[lang];
    window.localStorage.setItem("language", lang);
    dispatch({
      type: "SET_LANGUAGE",
      language: lang,
      direction: newDirection,
      font: newFont,
      dictionary: newDictionary,
    });
  };

  const onSetNewLanguage = (lang: string) => {
    onChangeLanguage(lang);
    if (!firebase.auth().currentUser) return;
    updateLanguage(lang, defaultParams);
  };

  React.useLayoutEffect(() => {
    document.body.setAttribute("dir", state.direction);
  }, [state.direction]);

  useEffect(() => {
    function getLocallyStoredLanguage() {
      const language = window.localStorage.getItem("language");
      if (!language) return;
      onChangeLanguage(language as string);
    }
    getLocallyStoredLanguage();
  }, []);

  // useEffect(() => {
  //   console.log("business owner value updated " + businessOwner);

  //   // function getBusinessOwnerLanguage() {
  //   //   app.auth().onAuthStateChanged(function (user) {
  //   //     // console.log("business owner lang : " + businessOwner);
  //   //     if (user && businessOwner.language) {
  //   //       // User is signed in.
  //   //       onChangeLanguage(businessOwner.language);
  //   //     }
  //   //   });
  //   // }
  //   // getBusinessOwnerLanguage();
  // }, [businessOwner]);
  return (
    <LanguageContext.Provider
      value={{ ...state, onChangeLanguage, onSetNewLanguage }}
    >
      {props.children}
    </LanguageContext.Provider>
  );
};
