import React, { useContext } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {
  InputBase,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import { AccountContext } from "../../contexts";
const CssTextField = withStyles((theme) => ({
  root: theme.outlineTextfield,
}))(TextField);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    label: {
      color: "black",
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      fontSize: 13,
      marginBottom: 5,
    },
    listItem: {
      color: "black",
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.regular,
      fontSize: 15,
    },
  })
);
function createSlots(slotConfig, classes, dictionary) {
  // Getting values from slotConfig using destructuring
  const { configSlotHours, configSlotMinutes, configSlotPreparation, timeArr } =
    slotConfig;

  // This is the default date that we are using to make use of javascript date functions
  // slotsArray will hold final slots
  // _timeArrStartTime is used to store start time date object from the timeArr
  // _timeArrEndTime is used to store end time date object from the timeArr
  // _tempSlotStartTime is used to create slots by adding config values and check that the time is less than the end time and lies withing the duration specified
  // _startSlot holds value of start date time of slot
  // _endSlot holds value of end date time of slot
  let defaultDate = new Date().toISOString().substring(0, 10);
  let slotsArray: any[] = [];
  let _timeArrStartTime;
  let _timeArrEndTime;
  let _tempSlotStartTime;
  let _endSlot;
  let _startSlot;

  // Loop over timeArr
  for (var i = 0; i < timeArr.length; i++) {
    // Creating time stamp using time from timeArr and default date
    _timeArrStartTime = new Date(
      defaultDate + " " + timeArr[i].startTime
    ).getTime();
    _timeArrEndTime = new Date(
      defaultDate + " " + timeArr[i].endTime
    ).getTime();
    _tempSlotStartTime = _timeArrStartTime;

    // Loop around till _tempSlotStartTime is less end time from timeArr
    while (
      new Date(_tempSlotStartTime).getTime() <
      new Date(_timeArrEndTime).getTime()
    ) {
      _endSlot = new Date(_tempSlotStartTime);
      _startSlot = new Date(_tempSlotStartTime);

      //Adding minutes and hours from config to create slot and overiding the value of _tempSlotStartTime
      _tempSlotStartTime = _endSlot.setHours(
        parseInt(_endSlot.getHours()) + parseInt(configSlotHours)
      );
      _tempSlotStartTime = _endSlot.setMinutes(
        parseInt(_endSlot.getMinutes()) + parseInt(configSlotMinutes)
      );

      // Check _tempSlotStartTime is less than end time after adding minutes and hours, if true push into slotsArr
      if (
        new Date(_tempSlotStartTime).getTime() <=
        new Date(_timeArrEndTime).getTime()
      ) {
        // DateTime object is converted to time with the help of javascript functions
        // If you want 24 hour format you can pass hour12 false
        var slot = {
          timeSlotStart: new Date(_startSlot).toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: false,
          }),
          timeSlotEnd: _endSlot.toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: false,
          }),
        };
        slotsArray.push(
          <MenuItem
            className={classes.listItem}
            value={slot.timeSlotStart}
            key={slot.timeSlotStart}
          >
            {slot.timeSlotStart}
          </MenuItem>
        );
      }
      // console.log(parseInt(slot.timeSlotStart.replace(":", "")));
      //preparation time is added in last to maintain the break period
      _tempSlotStartTime = _endSlot.setMinutes(
        _endSlot.getMinutes() + parseInt(configSlotPreparation)
      );
    }
  }

  return slotsArray;
}
export default function SimpleSelect({ form, dictionary }) {
  const classes = useStyles();
  var slotConfig = {
    configSlotHours: "00",
    configSlotMinutes: "00",
    configSlotPreparation: "30",
    timeArr: [
      { startTime: "08:00", endTime: "24:00" },
      // { startTime: "16:00", endTime: "20:00" },
    ],
  };
  var items = [] as any;
  createSlots(slotConfig, classes, dictionary);
  // console.log(JSON.stringify(createSlots(slotConfig, classes, dictionary)));
  return (
    <FormControl fullWidth={true} className={classes.formControl}>
      <Typography className={classes.label}>{dictionary.setTime}</Typography>
      <Controller
        control={form.control}
        name="bookingTime"
        rules={{ required: true }}
        defaultValue={""}
        render={({ field }) => {
          return (
            <CssTextField
              {...field}
              select
              variant="outlined"
              className={classes.listItem}
              SelectProps={{
                classes: { root: classes.listItem },
                multiple: false,
              }}
            >
              {createSlots(slotConfig, classes, dictionary)}
            </CssTextField>
          );
        }}
      />
      {form.formState.errors.bookingTime && (
        <Typography
          className={`form-error-text animate__animated animate__headShake`}
        >
          {dictionary.fieldRequired}
        </Typography>
      )}
    </FormControl>
  );
}
