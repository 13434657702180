import { app, db } from "..";

export const getBusinesses = async ({
  dispatch,
  state,
  toggleContinue,
  toggleActivity,
}) => {
  dispatch({
    type: "RESET",
  });
  toggleActivity(true, "fetching");
  db.collection("businesses")
    .where("uid", "==", app.auth().currentUser!.uid)
    .orderBy("updatedAt", "desc")
    .limit(3)
    .get()
    .then(async (querySnapshot) => {
      var items = [] as any;
      querySnapshot.docs.forEach((element) => items.push(element.data()));
      dispatch({
        type: "REQUEST_SUCCESS",
        payload: items,
        isMoreItems: false,
      });
      toggleActivity(false);
    })
    .catch(function (error) {
      dispatch({
        type: "REQUEST_FAIL",
      });
      toggleActivity(false);
      console.log(error);
      toggleContinue("requestFail", error.code, true);
    });
};
