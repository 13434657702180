import {
  Button,
  Card,
  createStyles,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import {
  AlluraLinkTextfield,
  BusinessLocationField,
  BusinessLocationMap,
  BusinessNameArTextfield,
  BusinessNameEnTextfield,
  BusinessPhoneTextfield,
  BusinessServicesSelect,
  BusinessTypeSelect,
  EmailTextfield,
  NameTextfield,
  PasswordAgainTextfield,
  PasswordTextfield,
  PersonalNumberTextfield,
  SubmitButton,
  BusinessRegionSelect,
} from "../";
import { AccountContext } from "../../contexts";

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      color: "black",
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      fontSize: 30,
      marginBottom: 5,
      textAlign: "center",
    },
    description: {
      color: "black",
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.regular,
      fontSize: 14,
      marginBottom: 5,
      paddingLeft: 20,
      paddingRight: 20,
      textAlign: "center",
    },
  })
);

const NewBusinessDetails = ({ dictionary }) => {
  const {
    toggleBusinessRegisterStep,
    multiStepFormData,
    onSetMultForm,
    onRegisterUser,
  } = useContext(AccountContext);
  const classes = useStyles();
  const form = useForm({
    defaultValues: multiStepFormData,
    reValidateMode: "onBlur",
    // shouldUnregister: false,
  });
  const onSubmit = (data) => {
    let prices = [] as any;
    data.businessServices.forEach((element) =>
      prices.push({ name: element, priceFrom: 0 })
    );
    prices = { businessPrices: prices };
    data = { ...data, ...prices };
    console.log(JSON.stringify(data));
    onSetMultForm(data);
    return onRegisterUser({ ...multiStepFormData, ...data });
  };
  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        style={{ minHeight: "100%" }}
        spacing={3}
      >
        <Grid item style={{ width: "90%" }}>
          <Typography className={classes.title}>
            {dictionary.businessDetails}
          </Typography>
          <Typography className={classes.description}>
            {dictionary.businessDetailsDescription}
          </Typography>
        </Grid>
        <Grid item style={{ width: "90%", minWidth: 250 }}>
          <AlluraLinkTextfield form={form} dictionary={dictionary} />
        </Grid>
        <Grid item style={{ width: "90%", minWidth: 250 }}>
          <BusinessNameEnTextfield form={form} dictionary={dictionary} />
        </Grid>
        <Grid item style={{ width: "90%", minWidth: 250 }}>
          <BusinessNameArTextfield form={form} dictionary={dictionary} />
        </Grid>
        <Grid item style={{ width: "90%", minWidth: 250 }}>
          <BusinessPhoneTextfield form={form} dictionary={dictionary} />
        </Grid>
        <Grid item style={{ width: "90%", minWidth: 250 }}>
          <BusinessTypeSelect form={form} dictionary={dictionary} />
        </Grid>
        <Grid item style={{ width: "90%", minWidth: 250 }}>
          <BusinessServicesSelect form={form} dictionary={dictionary} />
        </Grid>
        <Grid item style={{ width: "90%", minWidth: 250 }}>
          <BusinessRegionSelect form={form} dictionary={dictionary} />
        </Grid>
        <Grid item style={{ width: "90%", minWidth: 250 }}>
          <BusinessLocationMap form={form} dictionary={dictionary} />
        </Grid>
        <Grid item style={{ width: "60%", minWidth: 250 }}>
          <Button type="submit" variant="outlined" className="btn-primary">
            {dictionary.register}
          </Button>
        </Grid>
        <Grid item style={{ width: "60%", minWidth: 250 }}>
          <Button
            onClick={() => {
              // onSetMultForm(form.data);
              toggleBusinessRegisterStep(1);
            }}
            className="btn-clear"
            variant="contained"
          >
            {dictionary.back}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default NewBusinessDetails;
