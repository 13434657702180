// @ts-nocheck
import React, { useContext } from "react";
import {
  makeStyles,
  withStyles,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import { Button, Link, List } from "@material-ui/core";
import { InfoTab, BookingsTab } from "..";
import { DashboardContext } from "../../contexts";
import { useHistory } from "react-router-dom";
import AddBusinessButton from "./businesses/AddBusinessButton";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ height: "90vh", width: "100%", backgroundColor: "#F5F6F8" }}
    >
      {children}
    </div>
  );
}
interface StyledTabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

const StyledTabs = withStyles((theme) => ({
  root: {
    paddingTop: 30,
    paddingLeft: 50,
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: "black",
    },
  },
}))((props: StyledTabsProps) => (
  <Tabs
    // variant="fullWidth"
    {...props}
    TabIndicatorProps={{ children: <span /> }}
  />
));

interface StyledTabProps {
  label: string;
}

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      textTransform: "none",
      color: "black",
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),
      bottomBorderWidth: 3,
      bottomBorderColor: "blue",
      "&:focus": {
        opacity: 1,
      },
    },
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "white",
    flexGrow: 1,
    [theme.breakpoints.down(850)]: {
      display: "none",
    },
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo2: {
    backgroundColor: "blue",
  },
  businessStoreLink: {
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    color: "#5c6bc0",
    paddingLeft: 60,
    paddingTop: 40,
    // cursor: "pointer",
  },
  shareButton: {
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    //backgroundColor: "transparent",
    borderColor: "black",
    color: "black",
    borderWidth: 2,
    borderRadius: 5,
    marginLeft: 30,
    marginRight: 10,
    opacity: 0.7,
    height: 30,
    fontSize: 13,
    textTransform: "none",
  },
}));

export default function CustomizedTabs({ dictionary, history }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { businesses, isMoreItems } = useContext(DashboardContext);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  let url = window.location.host;
  return (
    <div className={classes.root}>
      {/* {businesses.length > 0 && (
        <Typography
          // onClick={() => history.push(businesses[0].link)}
          className={classes.businessStoreLink}
        >
          <span style={{ color: "black", opacity: 0.4 }}>My Allura Link: </span>
          <Link href={businesses[0].link}>
            {url + "/" + businesses[0].link}
          </Link>
          <Button
            //onClick={() => verticalTab(1)}
            className={classes.shareButton}
            variant="outlined"
          >
            Share
          </Button>
        </Typography>
      )} */}
      <div style={{ height: 30 }} />
      <StyledTabs
        value={value}
        onChange={handleChange}
        aria-label="styled tabs example"
      >
        <StyledTab label={dictionary.businesses} />
        {/* <StyledTab label={dictionary.bookings} /> */}
        {/* <StyledTab label="Media" />
        <StyledTab label="Themes" />
        */}
      </StyledTabs>
      {/* <Typography className={classes.padding} /> */}
      <TabPanel value={value} index={0}>
        <List style={{ maxHeight: "100%", overflow: "auto" }}>
          <InfoTab dictionary={dictionary} history={history} />
        </List>
        {/* {!isMoreItems && <AddBusinessButton />} */}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <List style={{ maxHeight: "100%", overflow: "auto" }}>
          <BookingsTab dictionary={dictionary} history={history} />
        </List>
      </TabPanel>
      <TabPanel value={value} index={2}></TabPanel>
      <TabPanel value={value} index={3}></TabPanel>
    </div>
  );
}
