import React, { useContext, useEffect } from "react";
import { NotificationContext } from "../../contexts";

const NotFound = () => {
  const { toggleActivity } = useContext(NotificationContext);
  useEffect(() => {
    toggleActivity(false);
  }, []);
  return (
    <div style={{ textAlign: "center" }}>
      <h1>SORRY PAGE NOT FOUND !!!</h1>
    </div>
  );
};

export default NotFound;
