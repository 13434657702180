import { Button, Card, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
  EmailTextfield,
  PasswordTextfield,
  TransitionButton,
} from "../../components";
import VerticalTabs from "../../components/dashboard/VerticalTabs";
import { LanguageContext } from "../../contexts";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    position: "fixed",
    right: 0,
    left: 0,
    top: 0,

    [theme.breakpoints.up(850)]: {
      position: "fixed",
      right: 0,
      left: 0,
      top: 0,
    },
  },
}));
const BusinessDashboard = () => {
  const classes = useStyles();
  const { dictionary } = useContext(LanguageContext);
  const history = useHistory();

  return (
    <div className={classes.root}>
      <VerticalTabs dictionary={dictionary} history={history} />
    </div>
  );
};

export default BusinessDashboard;
