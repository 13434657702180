import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "animate.css";
import {
  NotificationContextProvider,
  LanguageContextProvider,
  ThemeContextProvider,
  AuthContextProvider,
} from "./contexts";

ReactDOM.render(
  <LanguageContextProvider>
    <ThemeContextProvider>
      <NotificationContextProvider>
        <AuthContextProvider>
          <App />
        </AuthContextProvider>
      </NotificationContextProvider>
    </ThemeContextProvider>
  </LanguageContextProvider>,
  document.getElementById("root")
);
