import React, { useContext, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { createStyles, TextField, withStyles } from "@material-ui/core";
import { Controller } from "react-hook-form";

const CssTextField = withStyles((theme) => ({
  root: theme.outlineTextfield,
}))(TextField);

const useStyles = makeStyles((theme) =>
  createStyles({
    label: {
      color: "black",
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      fontSize: 13,
      marginBottom: 5,
    },
  })
);

const BusinessLocationField = ({ form, setModalVisible, dictionary }) => {
  const classes = useStyles();
  return (
    <div
      onClick={() => {
        setModalVisible(true);
      }}
    >
      <Typography className={classes.label}>
        {dictionary.businessLocation}
      </Typography>
      <Controller
        name="businessLocation"
        control={form.control}
        rules={{ required: true }}
        render={({ field }) => (
          <CssTextField
            {...field}
            fullWidth={true}
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          />
        )}
      />

      {form.formState.errors.businessLocation && (
        <Typography
          className={`form-error-text animate__animated animate__headShake`}
        >
          {dictionary.invalidBusinessLocation}
        </Typography>
      )}
    </div>
  );
};

export default BusinessLocationField;
