import React, { createContext, useReducer, useContext } from "react";
import { getBusiness, searchBusinesses, getMoreBusinesses } from "../services";
import { AuthContext, LanguageContext } from ".";
import { NotificationContext } from "./NotificationContext";
import { useHistory } from "react-router-dom";
import { addCustomerBooking } from "../services/api/business-front/addCustomerBooking";

const initialState = {
  search: "",
  bookingSubmitted: false,
  business: null as any,
  bookingInfo: null as any,
  businesses: [],
  isShopSearch: false,
  // isLoading: false,
  isFailing: false,
  isMoreItems: false,
  onToggleShopSearch: () => {},
  onGetBusiness: (params) => {},
  onResetForm: () => {},
  onAddCustomerBooking: (params) => {},
  onSearchBusinesses: (params) => {},
  onGetMoreBusinesses: () => {},
  onToggleListLoading: () => {},
  onSearchChanged: (params) => {},
};

export const CustomerContext = createContext(initialState);

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "BOOKING_NEW_SUBMISSION":
      return {
        ...state,
        bookingInfo: null,
      };
    case "BOOKING_SUBMITTED":
      return {
        ...state,
        bookingInfo: action.payload,
      };
    case "TOGGLE_LOADING_LIST":
      return {
        ...state,
        isMoreItems: true,
        businesses: [],
      };
    case "SET_SINGLE_BUSINESS":
      return {
        ...state,
        business: action.payload,
      };
    case "SET_MULTI_BUSINESS":
      return {
        ...state,
        businesses: action.payload,
        isMoreItems: action.isMoreItems,
      };
    case "TOGGLE_SHOP_SEARCH":
      return {
        ...state,
        isShopSearch: !state.isShopSearch,
      };
    case "SET_SEARCH_TEXT":
      return {
        ...state,
        search: action.payload,
      };
    case "RESET":
      return initialState;
    default:
      break;
  }
  return state;
};

export const CustomerContextProvider = (props: any) => {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { dictionary } = useContext(LanguageContext);
  const { checkTokenStatus, user } = useContext(AuthContext);
  const { toggleContinue, toggleToast, toggleActivity } =
    useContext(NotificationContext);
  const defaultParams = {
    dispatch,
    state,
    dictionary,
    toggleContinue,
    toggleToast,
    toggleActivity,
    history,
    checkTokenStatus,
    user,
  };
  state.onResetForm = () => {
    dispatch({ type: "BOOKING_NEW_SUBMISSION" });
  };
  state.onAddCustomerBooking = (submit) => {
    addCustomerBooking(submit, defaultParams);
  };
  state.onToggleShopSearch = () => {
    dispatch({ type: "TOGGLE_SHOP_SEARCH" });
  };
  state.onGetBusiness = (id) => getBusiness(id, defaultParams);
  state.onSearchChanged = (event) => {
    dispatch({ type: "SET_SEARCH_TEXT", payload: event.target.value });
  };
  state.onSearchBusinesses = (text) => {
    // dispatch({ type: "SET_SEARCH", payload: text });
    searchBusinesses(text, defaultParams);
  };
  state.onGetMoreBusinesses = () => {
    console.log("GET MORE BUSINESSES");

    getMoreBusinesses(defaultParams);
  };
  state.onToggleListLoading = () => {
    // console.log("show loading : " + state.isMoreItems);
    dispatch({ type: "TOGGLE_LOADING_LIST" });
  };

  return (
    <CustomerContext.Provider value={{ ...state }}>
      {props.children}
    </CustomerContext.Provider>
  );
};
