import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { Box, Grid, Divider } from "@material-ui/core";
import { Images } from "../themes";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    maxWidth: 600,
    width: "90vw",
    height: 200,
    maxHeight: 200,
    borderWidth: 0,
    boxShadow: "0 0 10px rgba(33,33,33,.2);",
    borderRadius: 30,
    padding: 20,
    paddingTop: 15,
    transition: "box-shadow .3s",
    "&:hover": {
      boxShadow: "0 0 20px rgba(33,33,33,.2); ",
    },
    cursor: "pointer",
    [theme.breakpoints.down(600)]: {
      height: 150,
    },
  },
  logo: {
    width: 100,
    height: 100,
    [theme.breakpoints.down(600)]: {
      width: 60,
      height: 60,
    },
  },
  divider: {
    backgroundColor: "black",
    opacity: 0.2,
    height: 120,
    marginLeft: 25,
    width: 3,
    borderRadius: 50,
    [theme.breakpoints.down(600)]: {
      height: 80,
      marginLeft: 25,
    },
  },
  shopDetails: {
    marginLeft: 0,
    [theme.breakpoints.down(600)]: {
      marginLeft: 20,
    },
  },
  shopName: {
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    fontSize: 30,
    [theme.breakpoints.down(600)]: {
      fontSize: 22,
    },
  },
  shopType: {
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    fontSize: 18,
    opacity: 0.7,
    paddingBottom: 15,
    [theme.breakpoints.down(600)]: {
      fontSize: 15,
      paddingBottom: 5,
    },
  },
  shopInfo: {
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    fontSize: 15,
    opacity: 0.7,
    [theme.breakpoints.down(600)]: {
      fontSize: 12,
    },
  },
  shopServices: {
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.regular,
    fontSize: 14,
    opacity: 0.7,
    paddingBottom: 10,
    [theme.breakpoints.down(600)]: {
      fontSize: 10,
    },
  },
}));

export default function ResultsListItem({ business, dictionary, history }) {
  const classes = useStyles();
  let services = [] as any;
  business.businessServices.forEach((value) => {
    services.push(dictionary[value]);
  });

  return (
    <Grid
      direction="column"
      justify="center"
      alignItems="center"
      container
      style={{ marginBottom: 30 }}
    >
      <Grid item xs={12}>
        <Card
          className={classes.root}
          variant="outlined"
          onClick={() => history.push("/" + business.link)}
        >
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            style={{ minHeight: "100%" }}
            spacing={0}
          >
            <Grid item xs={2} style={{ textAlign: "center" }}>
              <img
                src={Images[business.businessType + "_icon"]}
                className={classes.logo}
              />
            </Grid>
            <Grid item xs={1}>
              <Divider
                orientation="vertical"
                flexItem
                className={classes.divider}
              />
            </Grid>
            <Grid item xs={8} className={classes.shopDetails}>
              <Typography className={classes.shopName}>
                {dictionary.lang === "en"
                  ? business.businessNameEn
                  : business.businessNameAr}
              </Typography>
              <Typography className={classes.shopType}>
                {dictionary[business.businessType]}
              </Typography>
              <Typography className={classes.shopInfo}>
                {dictionary.lang === "en"
                  ? dictionary[business.businessRegion] + " | Saudi Arabia"
                  : dictionary[business.businessRegion] +
                    "  |  المملكة العربية السعودية "}
              </Typography>
              <Typography className={classes.shopServices}>
                {services.slice(0, services.length).join(", ")}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}
