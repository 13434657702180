import { Console } from "console";
import { db, app } from "..";

export const signup = (
  data,
  { state, dispatch, toggleToast, toggleContinue, toggleActivity, history }
) => {
  let bid = Math.round(Math.pow(36, 5 + 1) - Math.random() * Math.pow(36, 5))
    .toString(36)
    .slice(1);
  dispatch({ type: "REQUEST" });
  //return console.log(JSON.stringify(data));
  toggleActivity(true, "registering");

  const businessHours = [
    {
      name: "sunday",
      fromName: "sundayFrom",
      toName: "sundayTo",
      openName: "sundayOpen",
      fromValue: "08:00",
      toValue: "23:00",
      openValue: true,
    },
    {
      name: "monday",
      fromName: "mondayFrom",
      toName: "mondayTo",
      openName: "mondayOpen",
      fromValue: "08:00",
      toValue: "23:00",
      openValue: true,
    },
    {
      name: "tuesday",
      fromName: "tuesdayFrom",
      toName: "tuesdayTo",
      openName: "tuesdayOpen",
      fromValue: "08:00",
      toValue: "23:00",
      openValue: true,
    },
    {
      name: "wednesday",
      fromName: "wednesdayFrom",
      toName: "wednesdayTo",
      openName: "wednesdayOpen",
      fromValue: "08:00",
      toValue: "23:00",
      openValue: true,
    },
    {
      name: "thursday",
      fromName: "thursdayFrom",
      toName: "thursdayTo",
      openName: "thursdayOpen",
      fromValue: "08:00",
      toValue: "23:00",
      openValue: true,
    },
    {
      name: "friday",
      fromName: "fridayFrom",
      toName: "fridayTo",
      openName: "fridayOpen",
      fromValue: "08:00",
      toValue: "23:00",
      openValue: true,
    },
    {
      name: "saturday",
      fromName: "saturdayFrom",
      toName: "saturdayTo",
      openName: "saturdayOpen",
      fromValue: "08:00",
      toValue: "23:00",
      openValue: true,
    },
  ];

  let date = Date.now().toString();
  let businessOwnerBody = {
    email: data.email.toLowerCase(),
    name: data.fullName.toLowerCase(),
    personalPhone: data.personalPhone,
    language: "en",
    role: "business_owner",
    subscription: "null",
    disabled: false,
    createdAt: date,
    updatedAt: date,
  };
  let businessBody = {
    bid: bid,
    businessNameEn: data.businessNameEn,
    businessNameEn_insensitive: data.businessNameEn.toLowerCase(),
    businessNameAr: data.businessNameAr,
    businessPhone: data.businessPhone,
    businessEmail: null,
    businessDisabled: false,
    businessOnline: true,
    businessType: data.businessType,
    businessServices: data.businessServices,
    businessRegion: data.businessRegion,
    businessLocation: state.businessLocation,
    businessPrices: data.businessPrices,
    businessHours: businessHours,
    businessSocial: null,
    businessLogo: null,
    bookingsService: false,
    bookingsDisabled: false,
    bookingsMax: 10,
    link: data.link.toLowerCase(),
    businessesTotal: 0,
    createdAt: date,
    updatedAt: date,
  };
  let linksBody = {
    bid: bid,
    link: data.link.toLowerCase(),
  };
  db.collection("links")
    .doc(data.link.toLowerCase())
    .get()
    .then((doc) => {
      if (!doc.exists) {
        app
          .auth()
          .createUserWithEmailAndPassword(data.email, data.password)
          .then((user) => {
            const uid = app.auth().currentUser!.uid;
            Object.assign(businessOwnerBody, { uid: uid });
            Object.assign(businessBody, { uid: uid });
            Object.assign(linksBody, { uid: uid });
            const p1 = db
              .collection("business_owners")
              .doc(uid)
              .set(businessOwnerBody);
            const p2 = db
              .collection("business_owners_history")
              .add(businessOwnerBody);
            const p3 = db.collection("businesses").doc(bid).set(businessBody);
            const p4 = db.collection("businesses_history").add(businessBody);
            const p5 = db
              .collection("links")
              .doc(data.link.toLowerCase())
              .set(linksBody);
            const p6 = db.collection("links_history").add(linksBody);
            const promises = [p1, p2, p3, p4, p5, p6] as any;
            const finalPromise = Promise.all(promises);
            finalPromise
              .then(() => {
                toggleToast("registered", "success");
                history.replace(`/dashboard`);
                toggleActivity(false);
                dispatch({ type: "RESET" });
                //submit.form.reset();
              })
              .catch((error) => {
                console.log("6 part error : " + JSON.stringify(error));
                console.log(error);
                toggleActivity(false);
                dispatch({ type: "REQUEST_FAIL" });
                toggleContinue("requestFail", error.code, true);
              });
          })
          .catch((error) => {
            console.log(error);
            console.log("user create error : " + JSON.stringify(error));

            toggleActivity(false);
            dispatch({ type: "REQUEST_FAIL" });
            toggleContinue("requestFail", error.code, true);
            // submit.form.setValue("password", "");
            // submit.form.setValue("passwordAgain", "");
          });
      } else {
        toggleActivity(false);
        toggleContinue("requestFail", "linkExists", true);
      }
    })
    .catch(function (error) {
      dispatch({ type: "REQUEST_FAIL" });
      toggleActivity(false);
      console.log("links error : " + JSON.stringify(error));
      toggleContinue("requestFail", error.code, true);
    });
};
