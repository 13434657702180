import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../contexts";
import ActivityIndicator from "./ActivityIndicator";

const PrivateRoute = ({
  mustBeAuthenticated,
  alternativeRoute,
  component: RouteComponent,
  ...rest
}: any) => {
  const { user, isPending } = useContext(AuthContext);
  var authenticated = mustBeAuthenticated ? user : !user;
  return (
    <Route
      {...rest}
      render={(routeProps) => {
        if (isPending) {
          return (
            <div style={{}}>
              <ActivityIndicator />
              {/* <h3 style={{ textAlign: "center" }}>Checking User</h3> */}
            </div>
          );
        } else {
          return authenticated ? (
            <RouteComponent {...routeProps} />
          ) : (
            <Redirect to={alternativeRoute} />
          );
        }
      }}
    />
  );
};

export default PrivateRoute;
