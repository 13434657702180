import React from "react";
import { Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import { ShopSearchBox, Typewriter, WelcomeToolbar } from "..";
import { Images } from "../../themes";
const useStyles = makeStyles((theme) => ({
  root: {},
  categoryTitle: {
    fontFamily: "NotoSansJP-Regular",
    fontSize: 50,
    color: "#1b1e3e",
    marginBottom: 10,
    marginLeft: "15%",
    [theme.breakpoints.down(1000)]: {
      marginLeft: 0,
      textAlign: "center",
      fontSize: 36,
    },
    [theme.breakpoints.down(500)]: {
      fontSize: 28,
    },
  },
  image: {
    borderRadius: "50%",
    boxShadow: "0 0 30px rgba(33,33,33,.2);",
    [theme.breakpoints.down(1000)]: {
      width: 400,
      height: 400,
    },
    [theme.breakpoints.down(500)]: {
      width: 300,
      height: 300,
    },
  },
  imageContainer: {
    textAlign: "right",
    marginRight: 60,
    [theme.breakpoints.down(1120)]: {
      textAlign: "center",
      marginRight: 60,
    },
    [theme.breakpoints.down(500)]: {
      textAlign: "center",
      marginRight: 0,
    },
  },
  stepTitle: {
    fontFamily: "NotoSansJP-Regular",
    fontSize: 23,
    color: "#1b1e3e",
    [theme.breakpoints.down(1000)]: {
      fontSize: 20,
    },
  },
  stepDescription: {
    fontFamily: "NotoSansJP-Regular",
    fontSize: 20,
    opacity: 0.5,
    color: "#7b848c",
    [theme.breakpoints.down(1000)]: {
      fontSize: 16,
    },
  },
}));
const WhyLoveAlluraSection = ({ dictionary }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
        style={{ marginTop: 200, paddingRight: "10%", paddingLeft: "10%" }}
      >
        <Grid item xs={12} sm={12} style={{ width: "100%" }}>
          <Typography className={classes.categoryTitle}>
            Why businesses <strong>love</strong> Allura
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        style={{ marginTop: 50 }}
        spacing={0}
      >
        <Grid item xs style={{ backgroundColor: "transparent" }}>
          <div className={classes.imageContainer}></div>
        </Grid>
        <Grid item xs={6}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="flex-start"
            style={{ marginTop: 0 }}
            spacing={5}
          >
            <Grid item xs={12} sm={7}>
              {/* <img src={Images.checkmark} /> */}
              <Typography className={classes.stepTitle}>
                Your own business page
              </Typography>
              <Typography className={classes.stepDescription}>
                Get a public business page for customers to visit and checkout
                the services you provide, contact information, and your own
                appointment system!
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              {/* <img src={Images.checkmark} /> */}
              <Typography className={classes.stepTitle}>
                Customizable
              </Typography>
              <Typography className={classes.stepDescription}>
                edit your business details at anytime and customize the look and
                feel of your public page with your own flavours!
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7} style={{ width: "100%" }}>
              {/* <img src={Images.checkmark} /> */}
              <Typography className={classes.stepTitle}>
                Personal URL Link
              </Typography>
              <Typography className={classes.stepDescription}>
                Create your own custom URL link to share your business page to
                your social media platforms like Instagram, twitter, snapchat
                ... etc
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default WhyLoveAlluraSection;
