import React, { useContext, useEffect } from "react";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import { purple } from "@material-ui/core/colors";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch, { SwitchClassKey, SwitchProps } from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { DashboardContext } from "../../../contexts";
import { SubmitButton } from "../..";

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
}
const useStyles = makeStyles((theme) => ({
  statusText: {
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    fontSize: 13,
  },
}));
const IOSSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      "&$checked": {
        transform: "translateX(16px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#52d869",
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#52d869",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  })
)(({ classes, ...props }: Props) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default function CustomizedSwitches({ business, dictionary }) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(true);
  const [state, setState] = React.useState("businessAvailable");
  const { onEditInfo } = useContext(DashboardContext);

  const checkStatus = (checked) => {
    // console.log(checked);
    if (!business.businessDisabled) {
      checked ? setChecked(true) : setChecked(false);
      checked
        ? setState("businessAvailable")
        : setState("businessNotAvailable");
    } else {
      setChecked(false);
      setState("businessBlocked");
    }
  };
  useEffect(() => {
    checkStatus(business.businessOnline);
  }, [business]);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    checkStatus(event.target.checked);
    if (!business.businessDisabled) {
      const data = { businessOnline: event.target.checked, bid: business.bid };
      const Submit = { data };
      onEditInfo(Submit);

      // const data = {
      //   businessOnline: event.target.checked,
      //   bid: business.bid,
      //   link: business.link,
      // };
      // const Submit = { data, business };
      // onEditInfo(Submit);
    }
  };
  return (
    <div style={{ marginLeft: 20, paddingTop: 10, marginRight: 20 }}>
      <FormControlLabel
        control={
          <IOSSwitch checked={checked} onChange={handleChange} name="checked" />
        }
        label={<span className={classes.statusText}>{dictionary[state]}</span>}
      />
    </div>
  );
}
