import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { Grid, Divider, Link, IconButton } from "@material-ui/core";
import { Button } from "@material-ui/core";
import BasicInfoForm from "./EditBasicInfo";
import {
  BusinessStatusSwitch,
  EditBasicInfo,
  EditBusinessLocation,
  EditBusinessServices,
  EditMediaLinks,
  EditOpeningHours,
  QRGenerator,
} from "../..";
import EditBusinessPrices from "./EditBusinessPrices";
import { Images } from "../../../themes";
import LinkIcon from "@material-ui/icons/Link";
import { NotificationContext } from "../../../contexts";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10,
    paddingBottom: 50,
    [theme.breakpoints.up(850)]: {
      display: "none",
    },
  },
  cardRoot: {
    minWidth: 275,
    maxWidth: 400,
    width: "90vw",
    //height: 650,
    borderWidth: 0,
    boxShadow: "0 0 10px rgba(33,33,33,.2);",
    borderRadius: 30,
    padding: 0,
    transition: "box-shadow .3s",
  },
  editButton: {
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    //backgroundColor: "transparent",
    borderColor: "black",
    color: "black",
    borderWidth: 2,
    borderRadius: 5,
    marginTop: 10,
    //opacity: 0.7,
    height: 30,
    fontSize: 13,
    textTransform: "none",
  },
  informationContainer: {
    //height: 330,
    width: 300,
    // marginLeft: 20,
    paddingBottom: 30,
  },
  horiDivider: {
    marginTop: 15,
    marginBottom: 30,
    backgroundColor: "black",
    opacity: 0.2,
    width: 200,
    marginLeft: 50,
    height: 2,
    borderRadius: 50,
    textAlign: "center",
  },
  title: {
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    fontSize: 13,
    textAlign: "center",
    opacity: 0.5,
  },
  details: {
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    textAlign: "center",
    fontSize: 18,
  },
  basicInfoEditButtonContainer: {
    position: "absolute",
    minWidth: 275,
    maxWidth: 380,
    width: "85vw",
    marginTop: 10,
  },
  basicInfoEditButton: {
    backgroundColor: "#66bb6a",
    "&:hover": {
      backgroundColor: "#66bb6a",
    },
  },
  logo: {
    width: 70,
    height: 70,
  },
  link: {
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    cursor: "pointer",
    fontSize: 16,
    textAlign: "center",
  },
  topLinkButtonsContainer: {
    // left: 30,
    // whiteSpace: "nowrap",
    // display: "inline",
    position: "absolute",
    minWidth: 275,
    maxWidth: 280,
    width: "85vw",
    margin: 5,
    marginLeft: 10,
    marginRight: 10,
  },
  shareButton: {
    width: 40,
    height: 40,
    marginTop: 10,
    backgroundColor: "#70caee",
    "&:hover": {
      backgroundColor: "#70caee",
    },
  },
  qrButton: {
    width: 40,
    height: 40,
    // marginRight: 20,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

export default function BusinessInformationMobile({
  business,
  dictionary,
  history,
}) {
  const classes = useStyles();
  let url = window.location.host;
  const { toggleToast } = useContext(NotificationContext);

  return (
    <Grid
      direction="column"
      container
      className={classes.root}
      justify="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        <Card className={classes.cardRoot} variant="outlined">
          <EditBasicInfo business={business} dictionary={dictionary} />
          {/* <BusinessStatusSwitch business={business} dictionary={dictionary} /> */}
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing={2}
            className={classes.topLinkButtonsContainer}
          >
            <Grid item>
              <QRGenerator
                link={"https://" + url + "/" + business.link}
                dictionary={dictionary}
              />
              <IconButton
                aria-label="edit"
                className={classes.shareButton}
                onClick={() => {
                  navigator.clipboard.writeText(
                    "https://" + url + "/" + business.link
                  );
                  toggleToast("copied", "success");
                }}
              >
                <LinkIcon style={{ color: "white", width: 30, height: 30 }} />
              </IconButton>
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{
              marginTop: 50,
            }}
            spacing={0}
          >
            <Grid item style={{}}>
              <img
                src={Images[business.businessType + "_icon"]}
                className={classes.logo}
              />
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            // style={{ minHeight: "50%" }}
            spacing={0}
          >
            <Grid item className={classes.informationContainer}>
              <Divider flexItem className={classes.horiDivider} />

              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={12}>
                  <Typography className={classes.title}>
                    {dictionary.businessNameAr}
                  </Typography>

                  <Typography className={classes.details}>
                    <span
                      style={{
                        fontFamily: "Almarai",
                        fontWeight: 700,
                      }}
                    >
                      {business.businessNameAr}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.title}>
                    {dictionary.businessNameEn}
                  </Typography>

                  <Typography className={classes.details}>
                    {business.businessNameEn}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.title}>
                    {dictionary.businessPhone}
                  </Typography>

                  <Typography className={classes.details}>
                    {business.businessPhone}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.title}>
                    {dictionary.BusinessEmailAddress}
                  </Typography>
                  <Typography className={classes.details}>
                    {business.businessEmail || dictionary.notAvailable}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.title}>
                    {dictionary.alluraLink}
                  </Typography>
                  <Link
                    onClick={() => history.push(business.link)}
                    className={classes.link}
                  >
                    <Typography className={classes.details}>
                      {url + "/" + business.link}
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
              <Divider flexItem className={classes.horiDivider} />
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={5}
              >
                <Grid item>
                  <Typography className={classes.title}>
                    {dictionary.businessLocation}
                  </Typography>
                  <EditBusinessLocation
                    business={business}
                    dictionary={dictionary}
                  />
                </Grid>
                <Grid item>
                  <Typography className={classes.title}>
                    {dictionary.mediaLinks}
                  </Typography>

                  <EditMediaLinks business={business} dictionary={dictionary} />
                </Grid>
                <Grid item>
                  <Typography className={classes.title}>
                    {dictionary.businessServicesTitleOnly}
                  </Typography>
                  <EditBusinessServices
                    business={business}
                    dictionary={dictionary}
                  />
                </Grid>
                <Grid item>
                  <Typography className={classes.title}>
                    {dictionary.openingTimes}
                  </Typography>
                  <EditOpeningHours
                    business={business}
                    dictionary={dictionary}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}
