import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { LanguageContext } from "../../contexts";

const useStyles = makeStyles((theme: any) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.regular,
  },
  select: {
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.regular,
    color: theme.isDarkMode ? "white" : "black",
  },
  label: {
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    color: theme.isDarkMode ? "white" : "black",
  },
}));

export default function SimpleSelect() {
  const classes = useStyles();

  const { dictionary, onChangeLanguage, language } =
    useContext(LanguageContext);

  const [languageSelection, setLanguageSelection] = useState("");
  const handleChange = (event: any) => {
    setLanguageSelection(event.target.value);
    onChangeLanguage(event.target.value);
  };
  useEffect(() => {
    setLanguageSelection(language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormControl className={classes.formControl}>
      <InputLabel className={classes.label}>
        {dictionary["language"]}
      </InputLabel>
      <Select
        value={languageSelection}
        onChange={handleChange}
        className={classes.select}
      >
        <MenuItem value={"ar"}>{dictionary["ar"]}</MenuItem>
        <MenuItem value={"en"}>{dictionary["en"]}</MenuItem>
      </Select>
    </FormControl>
  );
}
