import React from "react";
import {
  Button,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { BackgroundVideo, ShopSearchBox, Typewriter, WelcomeToolbar } from "..";
import { Images } from "../../themes";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {},
  downArrow: {
    width: 50,
    height: 50,
    marginTop: 75,
  },
  divider: {
    paddingLeft: "45%",
    paddingRight: "45%",
    [theme.breakpoints.down(600)]: {
      paddingLeft: "25%",
      paddingRight: "25%",
    },
  },
  searchContainer: {
    minHeight: "90vh",
    [theme.breakpoints.down(600)]: {
      minHeight: 570,
    },
  },
}));
const IntroductionSection = ({ dictionary }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <BackgroundVideo />
      <div style={{ position: "absolute", width: "100%", top: 0 }}>
        <WelcomeToolbar />
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="center"
          className={classes.searchContainer}
          spacing={2}
        >
          <Grid item sm={12} style={{ width: 500, maxWidth: "70vw" }}>
            <Typewriter dictionary={dictionary} />
          </Grid>
          <Grid item sm={12}>
            <ShopSearchBox readonly={true} />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            style={{
              minWidth: 200,
              maxWidth: 350,
              width: "75vw",
              marginTop: 20,
            }}
          >
            <Button
              onClick={() => history.push(`/register`)}
              type="submit"
              variant="outlined"
              className="btn-primary"
            >
              Create a business
            </Button>
          </Grid>
        </Grid>
      </div>
      <Grid
        container
        justify="center"
        alignItems="center"
        spacing={0}
        style={{ marginTop: 20 }}
      >
        <Grid item sm={12}>
          <Typography
            style={{
              fontFamily: "NotoSansJP-Light",
              fontSize: 30,
              color: "#1b1e3e",
              textAlign: "center",
              marginBottom: 20,
            }}
          >
            Services for everyone
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} className={classes.divider}>
          <Divider
            style={{
              backgroundColor: "black",
              opacity: 0.2,
              height: 1,
              borderRadius: 50,
              textAlign: "center",
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default IntroductionSection;
