import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import Typewriter from "typewriter-effect/dist/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    text: {
      height: 30,
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      color: "white",
      fontSize: 20,
      [theme.breakpoints.down(600)]: {
        fontSize: 15,
      },
    },
  })
);

const ActivityIndicator = ({ dictionary }) => {
  const classes = useStyles();
  let app = document.getElementById("typewriter");
  var typewriter = new Typewriter(app, {
    loop: true,
    delay: 75,
  });
  if (dictionary.lang === "ar") {
    typewriter
      .pauseFor(2500)
      .typeString("ابحث عن حلاق")
      .pauseFor(2000)
      .deleteChars(4)
      .typeString("سبا")
      .pauseFor(2000)
      .deleteChars(3)
      .typeString("مصور احترافي")
      .pauseFor(2000)
      .deleteChars(30)
      .typeString("دلع نفسك بمساج")
      .pauseFor(2000)
      .deleteChars(30)
      .start();
  } else {
    typewriter
      .pauseFor(2500)
      .typeString("Search for a barbershop")
      .pauseFor(2000)
      .deleteChars(10)
      .typeString("Spa")
      .pauseFor(2000)
      .deleteChars(3)
      .typeString("Photographer")
      .pauseFor(2000)
      .deleteChars(30)
      .typeString("Get yourself a nail treatment")
      .pauseFor(2000)
      .deleteChars(14)
      .typeString("haircut")
      .pauseFor(2000)
      .deleteChars(7)
      .typeString("massage")
      .pauseFor(2000)
      .deleteChars(7)
      .typeString("facial")
      .pauseFor(2000)
      .deleteChars(6)
      .typeString("tan")
      .pauseFor(2000)
      .deleteChars(3)
      .start();
  }

  return <div id="typewriter" className={classes.text}></div>;
};

export default ActivityIndicator;
