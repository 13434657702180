import React, { useContext, useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import CardMobile from "./CardMobile";
import CardDesktop from "./CardDesktop";
import {
  AccountContext,
  DashboardContext,
  NotificationContext,
} from "../../../contexts";
import InfiniteScroll from "react-infinite-scroll-component";
import AddBookingsModal from "./ViewBookingsModal";
import QRCode from "qrcode.react";
import AddBookingService from "./AddBookingService";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // overflow: "auto",
    // maxHeight: "100vh",
    paddingBottom: 150,
    // textAlign: "center",
  },
}));

export default function BookingsTab({ dictionary, history }) {
  const classes = useStyles();
  const { onGetBusinesses, onClear, businesses, isMoreItems } =
    useContext(DashboardContext);
  const { onGetBusinessOwnerInfo } = useContext(AccountContext);
  var BusinessesWithoutBookings = businesses.filter(function (el) {
    return el.bookingsService == false;
  });
  useEffect(() => {
    // onClear();
    onGetBusinesses();
    onGetBusinessOwnerInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={classes.root} id="scrollableDiv">
      <InfiniteScroll
        style={{ overflow: "hidden" }}
        dataLength={businesses.length}
        next={onGetBusinesses}
        hasMore={isMoreItems}
        loader={<div />}
        scrollableTarget="scrollableDiv"
      >
        {/* <QRCode value="http://facebook.github.io/react/" /> */}
        {businesses.map((business, i) => {
          return (
            business.bookingsService && (
              <CardMobile
                history={history}
                business={business}
                dictionary={dictionary}
                key={i}
              />
            )
          );
        })}
        {businesses.map((business, i) => {
          return (
            business.bookingsService && (
              <CardDesktop
                history={history}
                business={business}
                dictionary={dictionary}
                key={i}
              />
            )
          );
        })}

        {!isMoreItems && BusinessesWithoutBookings.length > 0 && (
          <AddBookingService businesses={businesses} dictionary={dictionary} />
        )}
      </InfiniteScroll>
      <AddBookingsModal />
    </div>
  );
}
