import React, { useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { fade, InputAdornment, InputBase, withStyles } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { LanguageContext, CustomerContext } from "../../contexts";

const BootstrapInput = withStyles((theme) => ({
  root: {
    borderRadius: 50,
    backgroundColor: theme.palette.common.white,
    "label + &": {
      backgroundColor: theme.palette.common.white,
      marginTop: theme.spacing(3),
    },
    border: "0px solid #ced4da",
    paddingLeft: 10,
    width: "80vw",
    maxWidth: "500px",
    // boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    // borderColor: theme.palette.primary.main,
    boxShadow: "0 0 20px rgba(33,33,33,.2);",
    //fontSize: 20,
  },
  input: {
    // "&:focus": {
    //   boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    //   borderColor: theme.palette.primary.main,
    // },
    borderRadius: 50,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    height: 30,
    fontSize: 19,
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,

    padding: "10px 12px",
  },
}))(InputBase);
const SearchBox = ({ readonly }) => {
  const {
    onToggleShopSearch,
    onSearchBusinesses,
    onToggleListLoading,
    onSearchChanged,
    search,
  } = useContext(CustomerContext);
  const { dictionary } = useContext(LanguageContext);
  //const [search, setSearch] = useState("");
  // const handleChange = (event) => {
  //   setSearch(event.target.value);
  // };
  useEffect(() => {
    if (search) onToggleListLoading();
    const delayDebounceFn = setTimeout(() => {
      if (search) return onSearchBusinesses(search);
      // Send Axios request here
    }, 2000);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  return (
    <div
      onClick={() => {
        if (readonly) onToggleShopSearch();
      }}
    >
      <BootstrapInput
        value={search}
        onChange={onSearchChanged}
        //autoFocus={!readonly}
        readOnly={readonly}
        placeholder={dictionary.findShop}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon style={{ fontSize: 30, marginLeft: 5 }} />
          </InputAdornment>
        }
      />
    </div>
  );
};

export default SearchBox;
