import { createTheme } from "@material-ui/core/styles";
import light from "../assets/images/light.jpg";
import dark from "../assets/images/dark.jpg";
declare module "@material-ui/core/styles/createTheme" {
  interface Theme {
    outlineTextfield: {};
    fonts: any;
    colors: any;
    tabBackground?: any;
    isDarkMode?: any;
  }
  interface ThemeOptions {
    outlineTextfield: {};
    fonts: any;
    colors: any;
    tabBackground?: any;
    isDarkMode?: any;
  }
}

export const GlobalStyles = ({ direction, font, color, isDarkMode }) => {
  return createTheme({
    direction: direction,
    fonts: font,
    colors: color,
    isDarkMode: isDarkMode,
    tabBackground: color.background,
    outlineTextfield: {
      minWidth: 200,
      "& input": {
        "&::placeholder": {
          lineHeight: 3,
        },
        lineHeight: 3,
        //   height: 40,
        marginLeft: 10,
        marginRight: 10,
        fontFamily: font.name,
        fontWeight: font.regular,
      },
      "& label": {
        fontFamily: font.name,
        fontWeight: font.bold,
        backgroundColor: "white",
        paddingRight: 5,
        color: "black",
      },
      "& label.Mui-focused": {
        color: "black",
        fontFamily: font.name,
        fontWeight: font.bold,
        backgroundColor: "white",
        paddingRight: 5,
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "black",
        fontFamily: font.name,
        fontWeight: font.bold,
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "black",
          borderWidth: 2,
          borderRadius: 10,
        },
        "&.Mui-focused fieldset": {
          borderColor: "black",
          borderWidth: 2,
          borderRadius: 10,
        },
        "&:hover fieldset": {
          borderColor: "black",
          borderWidth: 3,
          borderRadius: 10,
        },
      },
    },
    overrides: {
      // MuiInput: {
      //   input: {
      //     "&::placeholder": {
      //       color: "red",
      //     },
      //   },
      // },
      MuiCssBaseline: {
        "@global": {
          ".bg": {
            width: "100%",
            height: "100%",
            position: "fixed",
            right: 0,
            left: 0,
            top: 0,
            zIndex: -2,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            background: "#fff",
          },
          ".btn-primary": {
            textTransform: "uppercase",
            paddingTop: "2%",
            borderRadius: 10,
            fontSize: 18,
            fontFamily: font.name,
            fontWeight: font.regular,
            width: "100%",
            height: 60,
            backgroundColor: color.primary,
            color: color.secondary,
            //  transition: "transform .3s",
            //   transform: "scale(1)",
            "&:hover": {
              backgroundColor: color.primary,
              color: color.secondary,
              // transform: "scale(1.1)",
            },
          },
          ".btn-danger": {
            paddingTop: "2%",
            textTransform: "none",
            borderRadius: 10,
            fontSize: 18,
            fontFamily: font.name,
            fontWeight: font.regular,
            width: "100%",
            height: 60,
            backgroundColor: color.danger,
            color: color.secondary,
            // transition: "transform .3s",
            // transform: "scale(1)",
            "&:hover": {
              backgroundColor: color.danger,
              color: color.secondary,
              //transform: "scale(1.1)",
            },
          },
          ".btn-info": {
            paddingTop: "2%",
            textTransform: "none",
            borderRadius: 10,
            fontSize: 18,
            fontFamily: font.name,
            fontWeight: font.regular,
            width: "100%",
            height: 60,
            backgroundColor: color.info,
            color: color.secondary,
            // transition: "transform .3s",
            // transform: "scale(1)",
            "&:hover": {
              backgroundColor: color.info,
              color: color.secondary,
              //transform: "scale(1.1)",
            },
          },
          ".btn-warn": {
            paddingTop: "2%",
            textTransform: "none",
            borderRadius: 10,
            fontSize: 18,
            fontFamily: font.name,
            fontWeight: font.regular,
            width: "100%",
            height: 60,
            backgroundColor: color.warn,
            color: color.secondary,
            // transition: "transform .3s",
            // transform: "scale(1)",
            "&:hover": {
              backgroundColor: color.warn,
              color: color.secondary,
              //transform: "scale(1.1)",
            },
          },
          ".btn-success": {
            paddingTop: "2%",
            textTransform: "none",
            borderRadius: 10,
            fontSize: 18,
            fontFamily: font.name,
            fontWeight: font.regular,
            width: "100%",
            height: 60,
            backgroundColor: color.success,
            color: color.secondary,
            // transition: "transform .3s",
            // transform: "scale(1)",
            "&:hover": {
              backgroundColor: color.success,
              color: color.secondary,
              //transform: "scale(1.1)",
            },
          },
          ".btn-clear": {
            textTransform: "none",
            borderRadius: 10,
            fontSize: 17,
            fontFamily: font.name,
            fontWeight: font.regular,
            width: "100%",
            minWidth: 100,
            height: 45,
            backgroundColor: "transparent",
            color: color.primary,
            boxShadow: "none",
            "&:hover": {
              backgroundColor: "transparent",
              color: color.primary,
              boxShadow: "none",
            },
            // "&:active": {
            //   backgroundColor: "transparent",
            //   color: color.primary,
            // },
          },
          ".form-error-text": {
            color: "#ef5350",
            fontFamily: font.name,
            fontWeight: font.bold,

            textAlign: "center",
            marginTop: 5,
          },
          // ".input-textfield": {
          //   fontFamily: font.name,
          //   fontWeight: font.regular,
          // },
          // ".input-textfield-label": {
          //   fontFamily: font.name,
          //   fontWeight: font.regular,
          // },
        },
      },
    },
  });
};
