import { Console } from "console";
import { db, app } from "..";

export const addBusiness = (
  submit,
  getBusinesses,
  { state, dispatch, toggleToast, toggleContinue, toggleActivity, history }
) => {
  let bid = Math.round(Math.pow(36, 5 + 1) - Math.random() * Math.pow(36, 5))
    .toString(36)
    .slice(1);
  dispatch({ type: "REQUEST" });
  //return console.log(JSON.stringify(submit.data));
  toggleActivity(true, "addingBusiness");

  const businessHours = [
    {
      name: "sunday",
      fromName: "sundayFrom",
      toName: "sundayTo",
      openName: "sundayOpen",
      fromValue: "08:00",
      toValue: "23:00",
      openValue: true,
    },
    {
      name: "monday",
      fromName: "mondayFrom",
      toName: "mondayTo",
      openName: "mondayOpen",
      fromValue: "08:00",
      toValue: "23:00",
      openValue: true,
    },
    {
      name: "tuesday",
      fromName: "tuesdayFrom",
      toName: "tuesdayTo",
      openName: "tuesdayOpen",
      fromValue: "08:00",
      toValue: "23:00",
      openValue: true,
    },
    {
      name: "wednesday",
      fromName: "wednesdayFrom",
      toName: "wednesdayTo",
      openName: "wednesdayOpen",
      fromValue: "08:00",
      toValue: "23:00",
      openValue: true,
    },
    {
      name: "thursday",
      fromName: "thursdayFrom",
      toName: "thursdayTo",
      openName: "thursdayOpen",
      fromValue: "08:00",
      toValue: "23:00",
      openValue: true,
    },
    {
      name: "friday",
      fromName: "fridayFrom",
      toName: "fridayTo",
      openName: "fridayOpen",
      fromValue: "08:00",
      toValue: "23:00",
      openValue: true,
    },
    {
      name: "saturday",
      fromName: "saturdayFrom",
      toName: "saturdayTo",
      openName: "saturdayOpen",
      fromValue: "08:00",
      toValue: "23:00",
      openValue: true,
    },
  ];

  let date = Date.now().toString();
  let businessBody = {
    bid: bid,
    businessNameEn: submit.data.businessNameEn,
    businessNameEn_insensitive: submit.data.businessNameEn.toLowerCase(),
    businessNameAr: submit.data.businessNameAr,
    businessPhone: submit.data.businessPhone,
    businessEmail: null,
    businessDisabled: false,
    businessOnline: true,
    businessType: submit.data.businessType,
    businessServices: submit.data.businessServices,
    businessRegion: submit.data.businessRegion,
    businessLocation: submit.data.businessLocation,
    businessPrices: submit.data.businessPrices,
    businessHours: businessHours,
    businessSocial: null,
    businessLogo: null,
    bookingsService: false,
    bookingsDisabled: false,
    bookingsMax: 10,
    link: submit.data.link.toLowerCase(),
    businessesTotal: state.businesses.length,
    createdAt: date,
    updatedAt: date,
  };
  let linksBody = {
    bid: bid,
    link: submit.data.link.toLowerCase(),
  };
  db.collection("links")
    .doc(submit.data.link.toLowerCase())
    .get()
    .then((doc) => {
      if (!doc.exists) {
        const uid = app.auth().currentUser!.uid;
        Object.assign(businessBody, { uid: uid });
        Object.assign(linksBody, { uid: uid });

        const p1 = db
          .collection("businesses")
          .doc(bid)
          .set(businessBody)
          .then(() =>
            db
              .collection("businesses_history")
              .add(businessBody)
              .then(() =>
                db
                  .collection("links")
                  .doc(submit.data.link.toLowerCase())
                  .set(linksBody)
                  .then(() => db.collection("links_history").add(linksBody))
              )
          );

        // const p3 = db.collection("businesses").doc(bid).set(businessBody);
        // const p4 = db.collection("businesses_history").add(businessBody);
        // const p5 = db
        //   .collection("links")
        //   .doc(submit.data.link.toLowerCase())
        //   .set(linksBody);
        // const p6 = db.collection("links_history").add(linksBody);
        const promises = [p1] as any;
        const finalPromise = Promise.all(promises);
        finalPromise
          .then(() => {
            toggleToast("businessAdded", "success");
            //history.replace(`/dashboard`);
            getBusinesses();
            submit.form.reset();
            //toggleActivity(false);
            // dispatch({ type: "REQUEST_SUCCESS" });
            //submit.form.reset();
          })
          .catch((error) => {
            console.log("6 part error : " + JSON.stringify(error));
            console.log(error);
            toggleActivity(false);
            dispatch({ type: "REQUEST_FAIL" });
            toggleContinue("requestFail", error.code, true);
          });
      } else {
        toggleActivity(false);
        toggleContinue("requestFail", "linkExists", true);
      }
    })
    .catch(function (error) {
      dispatch({ type: "REQUEST_FAIL" });
      toggleActivity(false);
      console.log("links error : " + JSON.stringify(error));
      toggleContinue("requestFail", error.code, true);
    });
};
