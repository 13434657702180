import React from "react";
import Typography from "@material-ui/core/Typography";
import { TextField, withStyles } from "@material-ui/core";
import { Controller } from "react-hook-form";

const CssTextField = withStyles((theme) => ({
  root: theme.outlineTextfield,
}))(TextField);

const PasswordTextfield = ({ form, dictionary }) => {
  const handleChange = (e) => {
    e.preventDefault();
  };
  return (
    <div>
      <Controller
        name="password"
        control={form.control}
        rules={{ required: true, pattern: /^.{6,}/ }}
        render={({ field }) => (
          <CssTextField
            {...field}
            onCut={handleChange}
            onCopy={handleChange}
            onPaste={handleChange}
            type="password"
            fullWidth={true}
            label={dictionary.password}
            variant="outlined"
          />
        )}
      />
      {form.formState.errors.password && (
        <Typography
          className={`form-error-text animate__animated animate__headShake`}
        >
          {dictionary.invalidPassword}
        </Typography>
      )}
    </div>
  );
};

export default PasswordTextfield;
