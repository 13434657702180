import React, { useContext } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { LanguageContext, CustomerContext } from "../../../../contexts";
import { useHistory } from "react-router-dom";
import { Linkwriter } from "../../../../components";
const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(9),
  },
  button: {
    width: "80%",
    marginTop: 50,
    maxWidth: 300,
    //border: "4px solid currentColor",
    borderRadius: 0,
    height: "auto",
    //padding: theme.spacing(2, 5),
  },
  TextContainer: {
    width: 400,
    marginTop: 50,
    borderRadius: 50,
    boxShadow: "0 0 20px rgba(33,33,33,.2);",
    padding: 20,
    [theme.breakpoints.down(600)]: {
      width: 280,
    },
  },
  link: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  buoy: {
    width: 60,
  },
  title: {
    textAlign: "center",
    fontSize: 40,
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    [theme.breakpoints.down(450)]: {
      fontSize: 25,
    },
  },
  description: {
    textAlign: "center",
    fontSize: 25,
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.regular,
    opacity: 0.7,
    marginBottom: 20,
    [theme.breakpoints.down(450)]: {
      fontSize: 20,
    },
  },
  benefits: {
    fontSize: 22,
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.regular,
    opacity: 0.7,
    textAlign: "left",
    marginLeft: "35%",
    color: "#ff9800",
    marginTop: 10,
    [theme.breakpoints.down(450)]: {
      fontSize: 18,
      marginLeft: 10,
    },
  },
  linkPrefix: {
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    color: "black",
    fontSize: 28,
    [theme.breakpoints.down(600)]: {
      fontSize: 17,
    },
  },
});

function ProductSmokingHero(props) {
  const { classes } = props;
  const { onToggleShopSearch } = useContext(CustomerContext);
  const { dictionary } = useContext(LanguageContext);
  const history = useHistory();
  return (
    <Container className={classes.root} component="section">
      <Typography className={classes.title}>{dictionary.createNow}</Typography>

      <div className={classes.TextContainer} dir={"ltr"}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="flex-start"
          spacing={1}
        >
          <Grid className={classes.linkPrefix} item>
            {" "}
            Allura.app/
          </Grid>
          <Grid item>
            <Linkwriter dictionary={dictionary} />
          </Grid>
        </Grid>
        {/* <div className={classes.label}>
          <Linkwriter dictionary={dictionary} />
          <Typography>🎉 FREE TO JOIN!</Typography>
        </div> */}
        {/* <Typography className={classes.description}>
          Join our platform now and enjoy faster growth and social media
          outreach
        </Typography>
        <Typography className={classes.benefits}>
          😎 All channels in one URL link
        </Typography>
        <Typography className={classes.benefits}>
          😎 Automated Booking System
        </Typography>

        <Typography className={classes.benefits}>🎉 FREE TO JOIN!</Typography> */}
      </div>
      <div className={classes.button}>
        <Button
          onClick={() => history.push(`/register`)}
          type="submit"
          variant="outlined"
          className="btn-primary"
        >
          {dictionary.start}
        </Button>
      </div>
      {/* <div className={classes.button}>
        <Button
          onClick={() => onToggleShopSearch()}
          type="submit"
          variant="outlined"
          className="btn-info"
        >
          Search a business
        </Button>
      </div> */}
      {/* <div className={classes.button}>
        <Typography
          variant="h4"
          component="span"
          style={{ textAlign: "center" }}
        >
          Got any questions? Need help?
        </Typography>
      </div>
      <Typography variant="subtitle1" className={classes.link}>
        We are here to help. Get in touch!
      </Typography>
      <img
        src="/static/themes/onepirate/producBuoy.svg"
        className={classes.buoy}
        alt="buoy"
      /> */}
    </Container>
  );
}

ProductSmokingHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles as any)(ProductSmokingHero);
