import React, { createContext, useReducer } from "react";

const initialState = {
  isContinueAlert: false,
  isConfirmAlert: false,
  confirmAction: () => {},
  isToast: false,
  isActivity: false,
  toastType: "error",
  activityTitle: "",
  messageTitle: "",
  messageDetails: "",
  toggleActivity: (...params) => {},
  toggleContinue: (...params) => {},
  toggleConfirm: (...params) => {},
  toggleToast: (...params) => {},
};

export const NotificationContext = createContext(initialState);

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "TOGGLE_CONTINUE_ALERT":
      return {
        ...state,
        isContinueAlert: action.visible,
        messageTitle: action.title,
        messageDetails: action.details ? action.details : "tryLater",
      };
    case "TOGGLE_CONFIRM_ALERT":
      return {
        ...state,
        isConfirmAlert: !state.isConfirmAlert,
        messageTitle: action.title,
        messageDetails: action.details,
        confirmAction: action.confirmAction,
      };
    case "TOGGLE_TOAST":
      return {
        ...state,
        isToast: !state.isToast,
        messageDetails: action.details,
        toastType: action.toastType,
      };
    case "TOGGLE_ACTIVITY":
      return {
        ...state,
        activityTitle: action.title ? action.title : "",
        isActivity: action.visible,
      };
    default:
      break;
  }
  return state;
};

export const NotificationContextProvider = (props: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  state.toggleActivity = (visible: boolean, title?: string) => {
    dispatch({ type: "TOGGLE_ACTIVITY", title, visible });
  };
  state.toggleContinue = (title: string, details: string, visible: boolean) =>
    dispatch({
      type: "TOGGLE_CONTINUE_ALERT",
      title: title,
      details: details,
      visible: visible,
    });
  state.toggleConfirm = (title: string, details: string, confirmAction) =>
    dispatch({
      type: "TOGGLE_CONFIRM_ALERT",
      title: title,
      details: details,
      confirmAction: confirmAction,
    });
  state.toggleToast = (details: string, toastType: string) => {
    dispatch({ type: "TOGGLE_TOAST", details: details, toastType: toastType });
  };

  return (
    <NotificationContext.Provider
      value={{
        ...state,
      }}
    >
      {props.children}
    </NotificationContext.Provider>
  );
};
