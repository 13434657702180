import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  createStyles,
  Grid,
  IconButton,
  List,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { DashboardContext } from "../../../contexts";
import { useForm } from "react-hook-form";
import { HourTextfields, PriceTextfield } from "../..";
import CloseIcon from "@material-ui/icons/Close";
import OpenTodaySwitch from "./OpenTodaySwitch";

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      textAlign: "center",
      fontSize: 25,
      margin: 10,
    },
    details: {
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.regular,
      textAlign: "center",
    },
    button: {
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      textAlign: "center",
      margin: 10,
    },
    paper: { borderRadius: 25, paddingBottom: 20 },
    editButton: {
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      borderColor: "black",
      color: "black",
      borderWidth: 2,
      borderRadius: 5,
      marginTop: 10,
      height: 30,
      fontSize: 13,
      textTransform: "none",
    },
    dayText: {
      textAlign: "center",
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      fontSize: 20,
    },
  })
);

export default function ScrollDialog({ business, dictionary }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { onEditInfo } = useContext(DashboardContext);
  const handleClickOpen = () => () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const form = useForm({
    defaultValues: {
      bid: business.bid,
    },
    reValidateMode: "onBlur",
    shouldUnregister: false,
  });
  const onSubmit = (data) => {
    const businessHours = [
      {
        name: "sunday",
        fromName: "sundayFrom",
        toName: "sundayTo",
        openName: "sundayOpen",
        fromValue: data.sundayFrom,
        openValue: data.sundayOpen,
        toValue: data.sundayTo,
      },
      {
        name: "monday",
        fromName: "mondayFrom",
        toName: "mondayTo",
        openName: "mondayOpen",
        fromValue: data.mondayFrom,
        openValue: data.mondayOpen,
        toValue: data.mondayTo,
      },
      {
        name: "tuesday",
        fromName: "tuesdayFrom",
        toName: "tuesdayTo",
        openName: "tuesdayOpen",
        fromValue: data.tuesdayFrom,
        openValue: data.tuesdayOpen,
        toValue: data.tuesdayTo,
      },
      {
        name: "wednesday",
        fromName: "wednesdayFrom",
        toName: "wednesdayTo",
        openName: "wednesdayOpen",
        fromValue: data.wednesdayFrom,
        openValue: data.wednesdayOpen,
        toValue: data.wednesdayTo,
      },
      {
        name: "thursday",
        fromName: "thursdayFrom",
        toName: "thursdayTo",
        openName: "thursdayOpen",
        fromValue: data.thursdayFrom,
        openValue: data.thursdayOpen,
        toValue: data.thursdayTo,
      },
      {
        name: "friday",
        fromName: "fridayFrom",
        toName: "fridayTo",
        openName: "fridayOpen",
        fromValue: data.fridayFrom,
        openValue: data.fridayOpen,
        toValue: data.fridayTo,
      },
      {
        name: "saturday",
        fromName: "saturdayFrom",
        toName: "saturdayTo",
        openName: "saturdayOpen",
        fromValue: data.saturdayFrom,
        openValue: data.saturdayOpen,
        toValue: data.saturdayTo,
      },
    ];
    data = { bid: data.bid };
    data = { ...data, businessHours };
    const submit = { data, form };
    onEditInfo(submit);
    handleClose();
  };

  return (
    <div>
      <input type="hidden" {...form.register("bid")} />
      <Button
        onClick={handleClickOpen()}
        className={classes.editButton}
        variant="outlined"
      >
        {dictionary.editOpeningTimes}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <IconButton
          aria-label="edit"
          style={{ position: "absolute", top: 0, right: 0 }}
          onClick={handleClose}
        >
          <CloseIcon style={{ fontSize: 40 }} />
        </IconButton>
        <DialogTitle id="max-width-dialog-title">
          <Typography className={classes.title}>
            {dictionary.openingTimes}
          </Typography>
        </DialogTitle>
        <DialogContent dividers={true}>
          <List>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={1}
            >
              {business.businessHours.map((day, i) => {
                return (
                  <div style={{ width: "90%" }} key={i}>
                    <div className={classes.dayText}>
                      {dictionary[day.name]}
                    </div>

                    <OpenTodaySwitch
                      day={day}
                      dictionary={dictionary}
                      form={form}
                    />

                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      spacing={2}
                      style={{ marginTop: 5, marginBottom: 20 }}
                    >
                      <Grid item xs={12} sm={6} style={{ width: "95%" }}>
                        <HourTextfields
                          form={form}
                          dictionary={dictionary}
                          placeholder={dictionary.openAt}
                          name={day.fromName}
                          value={day.fromValue}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} style={{ width: "95%" }}>
                        <HourTextfields
                          form={form}
                          dictionary={dictionary}
                          placeholder={dictionary.closeAt}
                          name={day.toName}
                          value={day.toValue}
                        />
                      </Grid>
                    </Grid>
                  </div>
                );
              })}
            </Grid>
          </List>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid item style={{ width: "60%", minWidth: 250 }}>
              <Button
                onClick={form.handleSubmit(onSubmit)}
                variant="outlined"
                className="btn-primary"
              >
                {dictionary.confirm}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}
