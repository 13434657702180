import React, { useContext, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import QRCode from "qrcode.react";

import {
  Button,
  Card,
  Grid,
  makeStyles,
  Typography,
  createStyles,
  Theme,
  CircularProgress,
  Container,
  withStyles,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { LanguageContext } from "../../contexts";

import { Images } from "../../themes";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import clsx from "clsx";
import Check from "@material-ui/icons/Check";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import StepConnector from "@material-ui/core/StepConnector";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: 10,
    },
    appBar: {
      background: "transparent",
      boxShadow: "none",
    },
    searchbar: {
      flexGrow: 1,
      textAlign: "center",
      color: "black",
      paddingTop: 10,
    },
    sectionDesktop: {
      position: "absolute",
      display: "none",
      [theme.breakpoints.up(800)]: {
        display: "flex",
      },
    },
    mainFooter: {
      position: "fixed",
      bottom: 10,
      width: "100%",
      marginTop: 100,
    },
    text: {
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      textAlign: "center",
    },
    subtext: {
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.regular,
      fontSize: 23,
      textAlign: "center",
    },
    cardTitle: {
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      textAlign: "center",
      opacity: 0.7,
    },
    bottomCloseButton: {
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      color: "#1b1e3e",
      borderWidth: 0,
      fontSize: 25,
    },
    imageContainer: {
      boxShadow: "0 0 50px rgba(33,33,33,.2);",
      width: 100,
      height: 100,
      borderRadius: "50%",
      marginTop: 20,
      marginBottom: 20,

      cursor: "pointer",
    },
    icon: {
      width: 80,
      height: 80,
      textAlign: "center",
      color: theme.isDarkMode ? "white" : "black",
    },
    resultMessage: {
      fontSize: 19,
      opacity: 0.7,
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      color: theme.isDarkMode ? "white" : "black",
    },
    stepTitle: {
      color: "black",
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      fontSize: 12,
      // textAlign: "center",
    },
    bookButton: {
      borderRadius: 30,
      fontSize: 14,
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      width: "100%",
      height: 50,
      backgroundColor: theme.colors.primary,
      color: theme.colors.secondary,
      textTransform: "none",
      transition: "transform .3s",
      transform: "scale(1)",
      "&:hover": {
        backgroundColor: theme.colors.primary,
        color: theme.colors.secondary,
        transform: "scale(1.1)",
      },
    },
    logo: {
      width: 70,
      height: 70,
    },
    subCard: {
      maxWidth: 340,
      width: "90vw",
      // height: 350,
      borderWidth: 0,
      boxShadow: "0 0 50px rgba(33,33,33,.2);",
      borderRadius: 30,
      paddingTop: 15,
      paddingBottom: 20,
      marginBottom: 20,
      marginTop: 0,

      transition: "box-shadow .3s",
    },
    locationButton: {
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      borderColor: "#3f51b5",
      color: "#3f51b5",
      borderWidth: 2,
      borderRadius: 5,
      height: 30,
      fontSize: 13,
      textTransform: "none",
    },
  })
);

export default function CustomerBooking() {
  const classes = useStyles();
  const { dictionary } = useContext(LanguageContext);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      className={`animate__animated  animate__fadeIn ${classes.root}`}
    >
      <Grid item>
        <Card
          className={`animate__animated faster animate__fadeIn ${classes.imageContainer}`}
          variant="outlined"
        >
          <img
            // onClick={() => history.push(`/`)}
            src={Images.logo}
            style={{
              width: 100,
              height: 100,
            }}
          />
        </Card>
      </Grid>
      {/* <Typography className={classes.subtext}>
        {dictionary.bookingSubmitted}
        <Typography className={classes.text} style={{ fontSize: 30 }}>
          {dictionary.lang == "ar"
            ? business.businessNameAr
            : business.businessNameEn}
        </Typography>
      </Typography> */}
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={3}
        style={{
          paddingRight: 40,
          paddingLeft: 40,
        }}
      >
        <Grid item>
          <Card
            className={`animate__animated faster animate__fadeIn ${classes.subCard}`}
            variant="outlined"
          >
            <Typography className={classes.cardTitle}>
              {" "}
              {dictionary.bookingInfo}
            </Typography>
            <br />
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={3}
            >
              <Grid item xs={6}>
                <Typography className={classes.text}>
                  {dictionary.businessName}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  className={classes.text}
                  style={{ color: "#3f51b5" }}
                >
                  Amal Gold Spa
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.text}>
                  {dictionary.businessLocation}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Button
                  // onClick={handleClickOpen()}
                  className={classes.locationButton}
                  variant="outlined"
                  style={
                    dictionary.lang == "ar"
                      ? { marginRight: 15 }
                      : { marginRight: 15 }
                  }
                >
                  {dictionary.ourLocation}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.text}>
                  {dictionary.customerName}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  className={classes.text}
                  style={{ color: "#3f51b5" }}
                >
                  Ahmed Al-Eissa
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.text}>
                  {dictionary.bookingNumber}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  className={classes.text}
                  style={{ color: "#3f51b5" }}
                >
                  D 2 E Q
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.text}>
                  {dictionary.bookingDay}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  className={classes.text}
                  style={{ color: "#3f51b5" }}
                >
                  December 12
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.text}>
                  {dictionary.bookingHour}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  className={classes.text}
                  style={{ color: "#3f51b5" }}
                >
                  12:30
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.text}>
                  {dictionary.bookingStatus}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.text} style={{ color: "green" }}>
                  {dictionary.bookingUp}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
      <Grid item>
        <Card
          className={`animate__animated faster animate__fadeIn ${classes.subCard}`}
          variant="outlined"
        >
          <Typography className={classes.cardTitle}>
            {dictionary.useBookingQR}
          </Typography>
          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <QRCode value={`https://allura.app/booking/`} />
          </div>
        </Card>
      </Grid>
      <Grid item style={{ minWidth: 250 }}>
        <Button
          // onClick={handleClose}
          variant="outlined"
          className="btn-danger"
        >
          {dictionary.cancelBooking}
        </Button>
      </Grid>
    </Grid>
  );
}
