import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { Grid, Divider, Button, Paper, List } from "@material-ui/core";
import { Images } from "../../../themes";
import {
  PersonalNumberTextfield,
  EmailTextfield,
  PasswordTextfield,
} from "../..";
import { useForm } from "react-hook-form";
import {
  AccountContext,
  LanguageContext,
  NotificationContext,
} from "../../../contexts";
import LanguageIcon from "@material-ui/icons/Language";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    marginTop: 20,
    marginRight: 20,
    marginLeft: "2%",
  },
  support: {
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    fontSize: 15,
    color: "#1b1e3e",
    textAlign: "center",
    marginTop: 5,
  },
  cardRoot: {
    minWidth: 275,
    maxWidth: 500,
    width: "90vw",
    borderWidth: 0,
    boxShadow: "0 0 10px rgba(33,33,33,.2);",
    borderRadius: 30,
    padding: 20,
    paddingTop: 40,
    transition: "box-shadow .3s",
  },
  categoryName: {
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    fontSize: 25,
  },
  title: {
    marginTop: 30,
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    fontSize: 13,
    textAlign: "center",
    opacity: 0.5,
    [theme.breakpoints.down(850)]: {
      //  fontSize: 10,
    },
  },
  name: {
    fontFamily: "Almarai",
    fontWeight: theme.fonts.bold,
    fontSize: 20,
    marginBottom: 30,
  },

  information: {
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    fontSize: 20,
  },
  list: {
    paddingBottom: 200,
    maxHeight: "100%",
    overflow: "auto",
    [theme.breakpoints.down(1000)]: {
      width: "100vw",
      overflowX: "hidden",
    },
  },
  gridItem: {
    [theme.breakpoints.up(600)]: { minWidth: 550 },
  },
}));

export default function BusinessInformationDesktop() {
  const {
    onDeleteUser,
    businessOwner,
    onUpdateContact,
    onResetPass,
    onLogoutUser,
  } = useContext(AccountContext);
  const { toggleConfirm } = useContext(NotificationContext);
  const { dictionary, onSetNewLanguage, language } =
    useContext(LanguageContext);
  const classes = useStyles();
  const updateForm = useForm({
    defaultValues: {
      personalPhone: businessOwner!.personalPhone,
      email: businessOwner!.email,
    },
    reValidateMode: "onBlur",
    shouldUnregister: false,
  });
  const deleteForm = useForm();
  const onUpdate = (data) => {
    console.log(data);
    const form = updateForm;
    const submit = { data, form };
    onUpdateContact(submit);
  };
  const onDelete = (data) => {
    console.log(data);
    const form = deleteForm;
    const submit = { data, form };
    toggleConfirm("deleteAccount", "deleteAccountDescription", () =>
      onDeleteUser(submit)
    );
  };
  const handleLanguage = () => {
    language === "ar" ? onSetNewLanguage("en") : onSetNewLanguage("ar");
  };

  return (
    <List className={classes.list}>
      <Grid
        container
        className={classes.root}
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={12} sm={3} className={classes.gridItem}>
          <Card className={classes.cardRoot} variant="outlined">
            <Grid
              direction="column"
              container
              justify="center"
              alignItems="center"
              spacing={5}
            >
              <Typography className={classes.categoryName}>
                {dictionary.contactInfo}
              </Typography>
              <Typography className={classes.title}>
                {dictionary.businessOwnerName}
              </Typography>
              <Typography className={classes.name}>
                {businessOwner.name}
              </Typography>
              <Grid item xs={12} sm={12} style={{ width: "95%" }}>
                <PersonalNumberTextfield
                  form={updateForm}
                  dictionary={dictionary}
                />
              </Grid>
              <Grid item xs={12} sm={12} style={{ width: "95%" }}>
                <EmailTextfield form={updateForm} dictionary={dictionary} />
              </Grid>
              <Grid item xs={12} sm={12} style={{ width: "95%" }}>
                <PasswordTextfield form={updateForm} dictionary={dictionary} />
              </Grid>
              <Grid item style={{ width: "60%", minWidth: 250 }}>
                <Button
                  onClick={updateForm.handleSubmit(onUpdate)}
                  variant="outlined"
                  className="btn-primary"
                >
                  {dictionary.update}
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12} sm={3} className={classes.gridItem}>
          <Grid
            direction="column"
            container
            justify="center"
            alignItems="flex-start"
            spacing={3}
          >
            <Grid item>
              <Card className={classes.cardRoot} variant="outlined">
                <Grid
                  direction="column"
                  container
                  justify="center"
                  alignItems="center"
                  spacing={5}
                >
                  <Typography className={classes.categoryName}>
                    {dictionary.accountActions}
                  </Typography>
                  <Grid item style={{ width: "60%", minWidth: 250 }}>
                    <Button
                      style={{
                        fontFamily:
                          dictionary.lang === "ar" ? "Roboto" : "Almarai",
                      }}
                      endIcon={<LanguageIcon />}
                      onClick={() => handleLanguage()}
                      variant="outlined"
                      className="btn-info"
                    >
                      {language === "ar" ? dictionary.en : dictionary.ar}
                    </Button>
                  </Grid>
                  <Grid item style={{ width: "60%", minWidth: 250 }}>
                    <Button
                      onClick={() => onResetPass()}
                      variant="outlined"
                      className="btn-info"
                    >
                      {dictionary.resetPass}
                    </Button>
                  </Grid>
                  <Grid item style={{ width: "60%", minWidth: 250 }}>
                    <Button
                      onClick={() => {
                        toggleConfirm("logout", "logout?", onLogoutUser);
                      }}
                      variant="outlined"
                      className="btn-warn"
                    >
                      {dictionary.logout}
                    </Button>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item>
              <Card className={classes.cardRoot} variant="outlined">
                <Grid
                  direction="column"
                  container
                  justify="center"
                  alignItems="center"
                  spacing={5}
                >
                  <Typography className={classes.categoryName}>
                    {dictionary.accountDeletion}
                  </Typography>
                  <Grid item xs={12} sm={12} style={{ width: "95%" }}>
                    <PasswordTextfield
                      form={deleteForm}
                      dictionary={dictionary}
                    />
                  </Grid>
                  <Grid item style={{ width: "60%", minWidth: 250 }}>
                    <Button
                      onClick={deleteForm.handleSubmit(onDelete)}
                      variant="outlined"
                      className="btn-danger"
                    >
                      {dictionary.deleteAccount}
                    </Button>
                  </Grid>
                </Grid>
              </Card>
              <Grid item sm={12} xs={12} style={{ marginTop: 30 }}>
                <Typography className={classes.support}>
                  {dictionary.supportemail}
                </Typography>
                <Typography className={classes.support}>
                  support@allura.app
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </List>
  );
}
