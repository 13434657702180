import React, { useContext, useEffect, useRef } from "react";
import {
  CustomerContext,
  LanguageContext,
  NotificationContext,
} from "../../contexts";
import {
  BackgroundVideo,
  SearchAndResults,
  IntroductionSection,
  HowItWorksSection,
  TotallyFreeSection,
  WhyLoveAlluraSection,
  FooterSection,
  GetStartedSection,
  BusinessFrontLocation,
  CustomerInfoModal,
} from "../../components";
import {
  Button,
  Card,
  createStyles,
  Grid,
  Link,
  List,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { Images } from "../../themes";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import CallIcon from "@material-ui/icons/Call";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { useParams, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      paddingTop: 20,
      marginBottom: 100,
    },
    logoContainer: {
      boxShadow: "0 0 50px rgba(33,33,33,.2);",
      width: 150,
      height: 150,
      borderRadius: "50%",
      marginBottom: 40,
      cursor: "pointer",
    },
    bookingCard: {
      maxWidth: 700,
      width: "90vw",
      height: 400,
      borderWidth: 0,
      boxShadow: "0 0 50px rgba(33,33,33,.2);",
      borderRadius: 30,
      transition: "box-shadow .3s",
    },
    SocialCard: {
      maxWidth: 700,
      width: "90vw",
      borderWidth: 0,
      boxShadow: "0 0 50px rgba(33,33,33,.2);",
      borderRadius: 30,
      padding: 15,
      paddingBottom: 30,
      transition: "box-shadow .3s",
    },
    subCard: {
      maxWidth: 340,
      width: "90vw",
      // height: 350,
      borderWidth: 0,
      boxShadow: "0 0 50px rgba(33,33,33,.2);",
      borderRadius: 30,
      paddingTop: 15,
      paddingBottom: 20,
      transition: "box-shadow .3s",
    },
    bookButton: {
      borderRadius: 30,
      fontSize: 14,
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      width: "100%",
      height: 50,
      backgroundColor: theme.colors.primary,
      color: theme.colors.secondary,
      textTransform: "none",
      transition: "transform .3s",
      transform: "scale(1)",
      "&:hover": {
        backgroundColor: theme.colors.primary,
        color: theme.colors.secondary,
        transform: "scale(1.1)",
      },
    },
    locationButton: {
      borderRadius: 30,
      fontSize: 14,
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      width: "100%",
      height: 50,
      backgroundColor: theme.colors.info,
      color: theme.colors.secondary,
      textTransform: "none",
      transition: "transform .3s",
      transform: "scale(1)",

      "&:hover": {
        backgroundColor: theme.colors.info,
        color: theme.colors.secondary,
        transform: "scale(1.1)",
      },
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    title: {
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      fontSize: 17,
      color: theme.colors.primary,
      textAlign: "center",
    },
    dayText: {
      fontSize: 14,
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      opacity: 0.7,
      textAlign: "center",
      marginTop: 20,
    },
    hourText: {
      fontSize: 14,
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      opacity: 0.7,
      textAlign: "center",
      marginTop: 20,
    },
    businessName: {
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      fontSize: 19,
      color: "white",
      textAlign: "center",
    },

    serviceText: {
      fontSize: 14,
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      opacity: 0.7,
      marginTop: 10,
      textAlign: "center",
    },
    star: { width: 15, height: 15, marginTop: 10 },
    socialItem: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
    },
    socialText: {
      fontSize: 14,
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      marginLeft: 5,
    },
    languageButton: {
      borderRadius: 30,
      fontSize: 14,
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      width: "100%",
      height: 50,
      backgroundColor: theme.colors.info,
      color: theme.colors.secondary,
      textTransform: "none",
      transition: "transform .3s",
      transform: "scale(1)",

      "&:hover": {
        backgroundColor: theme.colors.info,
        color: theme.colors.secondary,
        transform: "scale(1.1)",
      },
    },
  })
);

const BusinessFront = () => {
  const classes = useStyles();
  const { dictionary, onChangeLanguage, language } =
    useContext(LanguageContext);
  const { toggleContinue } = useContext(NotificationContext);

  const { onGetBusiness, business } = useContext(CustomerContext);
  const { id } = useParams() as any;
  const history = useHistory();
  const handleLanguage = () => {
    language === "ar" ? onChangeLanguage("en") : onChangeLanguage("ar");
  };
  useEffect(() => {
    if (!/^([a-zA-Z]){3,30}$/.test(id)) {
      return history.replace("/404");
    }
    onGetBusiness(id.toLowerCase());
  }, []);

  return business ? (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      spacing={6}
      className={classes.root}
    >
      <Grid item xs={12}>
        <Card
          className={`animate__animated faster animate__fadeIn ${classes.bookingCard}`}
          variant="outlined"
        >
          <div
            style={{
              backgroundImage: `url(${Images[business.businessType + "_bg"]})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              width: "100%",
              height: "110%",
              //filter: "blur(5px)",
            }}
          >
            <Grid
              item
              style={{
                minWidth: 100,
                maxWidth: 300,
                width: "20%",
                padding: 20,
              }}
            >
              <Button
                onClick={() => handleLanguage()}
                type="submit"
                variant="outlined"
                className={classes.languageButton}
                style={{
                  fontFamily: dictionary.lang === "ar" ? "Roboto" : "Almarai",
                }}
              >
                {language === "ar" ? dictionary.en : dictionary.ar}
              </Button>
            </Grid>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              style={{ minHeight: "70%" }}
              spacing={3}
            >
              <Grid item>
                <img
                  src={Images[business.businessType + "_icon"]}
                  style={{
                    width: 100,
                    height: 100,
                  }}
                />
              </Grid>
              <Grid item>
                <Typography className={classes.businessName}>
                  {dictionary.lang == "ar"
                    ? business.businessNameAr
                    : business.businessNameEn}
                </Typography>
              </Grid>
              {/* <Grid item style={{ minWidth: 100, maxWidth: 300, width: "60%" }}>
                <CustomerInfoModal />
              </Grid> */}
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                style={{
                  minHeight: "100%",
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
                spacing={2}
              >
                <Grid
                  item
                  style={{
                    minWidth: 100,
                    maxWidth: business.businessSocial?.brochure ? 150 : 300,
                    width: "60%",
                  }}
                >
                  <BusinessFrontLocation
                    dictionary={dictionary}
                    business={business}
                  />
                </Grid>
                {business.businessSocial?.brochure && (
                  <Grid
                    item
                    style={{ minWidth: 100, maxWidth: 150, width: "60%" }}
                  >
                    <Button
                      onClick={() =>
                        (window.location.href =
                          business.businessSocial!.brochure)
                      }
                      type="submit"
                      variant="outlined"
                      className={classes.locationButton}
                    >
                      {dictionary.ourBrochure}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </div>
        </Card>
      </Grid>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={3}
        style={{
          paddingRight: 40,
          paddingLeft: 40,
        }}
      >
        <Grid item>
          <Card
            className={`animate__animated faster animate__fadeIn ${classes.subCard}`}
            variant="outlined"
          >
            <Typography className={classes.title}>
              {" "}
              {dictionary.openingTimes}
            </Typography>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={3}
            >
              <Grid item xs={6}>
                {business.businessHours.map((value, i) => {
                  return (
                    <Typography className={classes.dayText} key={i}>
                      {dictionary[business.businessHours[i].name]}
                    </Typography>
                  );
                })}
              </Grid>
              <Grid item xs={6}>
                {business.businessHours.map((value, i) => {
                  if (business.businessHours[i].openValue) {
                    return (
                      <Typography className={classes.dayText} key={i}>
                        {business.businessHours[i].fromValue +
                          " - " +
                          business.businessHours[i].toValue}
                      </Typography>
                    );
                  } else {
                    return (
                      <Typography className={classes.dayText} key={i}>
                        Closed
                      </Typography>
                    );
                  }
                })}
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item>
          <Card
            className={`animate__animated faster animate__fadeIn ${classes.subCard}`}
            variant="outlined"
            dir="ltr"
          >
            <Typography className={classes.title}>
              {dictionary.availableServices}
            </Typography>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              spacing={0}
              style={{
                // paddingRight: 40,
                //   paddingLeft: "25%",
                paddingTop: 10,
              }}
            >
              <Grid item xs={12}>
                {business.businessServices.map((value, i) => {
                  if (i > 9) return;
                  return (
                    <Typography className={classes.serviceText} key={i}>
                      {dictionary[business?.businessServices[i]]}
                    </Typography>
                  );
                })}
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Card
          className={`animate__animated faster animate__fadeIn ${classes.SocialCard}`}
          variant="outlined"
        >
          <Typography className={classes.title}>
            {" "}
            {dictionary.contactUs}
          </Typography>

          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ marginTop: 10 }}
            spacing={3}
          >
            <Grid item className={classes.socialItem}>
              <CallIcon />
              <Link
                href={`tel:${business.businessPhone}`}
                className={classes.socialText}
              >
                {business.businessPhone}
              </Link>
            </Grid>
            {business.businessEmail && (
              <Grid item className={classes.socialItem}>
                <AlternateEmailIcon />
                <Link
                  href={`mailto:${business.businessEmail}`}
                  className={classes.socialText}
                >
                  {business.businessEmail}
                </Link>
              </Grid>
            )}
            {business.businessSocial?.whatsapp && (
              <Grid item className={classes.socialItem}>
                <WhatsAppIcon />
                <Link
                  href={`https://api.whatsapp.com/send?phone=+966${business.businessSocial.whatsapp}`}
                  className={classes.socialText}
                >
                  {dictionary.visitWhatsapp}
                </Link>
              </Grid>
            )}
            {business.businessSocial?.instagram && (
              <Grid item className={classes.socialItem}>
                <InstagramIcon />
                <Link
                  href={business.businessSocial.instagram}
                  className={classes.socialText}
                >
                  {dictionary.visitInstagram}
                </Link>
              </Grid>
            )}
            {business.businessSocial?.snapchat && (
              <Grid item className={classes.socialItem}>
                <img src={Images.snapchat} style={{ width: 20, height: 20 }} />
                <Link
                  href={business.businessSocial.snapchat}
                  className={classes.socialText}
                >
                  {dictionary.visitSnapchat}
                </Link>
              </Grid>
            )}
            {business.businessSocial?.twitter && (
              <Grid item className={classes.socialItem}>
                <TwitterIcon />
                <Link
                  href={business.businessSocial.twitter}
                  className={classes.socialText}
                >
                  {dictionary.visitTwitter}
                </Link>
              </Grid>
            )}
            {business.businessSocial?.facebook && (
              <Grid item className={classes.socialItem}>
                <FacebookIcon />
                <Link
                  href={business.businessSocial.facebook}
                  className={classes.socialText}
                >
                  {dictionary.visitFacebook}
                </Link>
              </Grid>
            )}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  ) : (
    <div />
  );
};

export default BusinessFront;
