import React, { useContext, useEffect, useState } from "react";
import {
  createStyles,
  makeStyles,
  useTheme,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import {
  Checkbox,
  InputBase,
  ListItemText,
  TextField,
  Typography,
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import {
  AccountContext,
  DashboardContext,
  LanguageContext,
} from "../../contexts";
import { BusinessDashboard } from "../../pages";
const CssTextField = withStyles((theme) => ({
  root: theme.outlineTextfield,
}))(TextField);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      // margin: theme.spacing(1),
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      //marginTop: theme.spacing(3),
    },
    label: {
      color: "black",
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      fontSize: 13,
      marginBottom: 5,
    },
    listItem: {
      color: "black",
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.regular,
      fontSize: 15,
    },
  })
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelect({ dictionary, form, businesses }) {
  const classes = useStyles();
  const { language } = useContext(LanguageContext);
  var businesses = businesses.filter(function (el) {
    return el.bookingsService == false;
  });
  return (
    <FormControl fullWidth={true} className={classes.formControl}>
      <Typography className={classes.label}>
        {dictionary.businessName}
      </Typography>

      <Controller
        control={form.control}
        name="bid"
        rules={{ required: true }}
        defaultValue={[]}
        render={({ field }) => {
          return (
            <CssTextField
              {...field}
              select
              variant="outlined"
              defaultValue=""
              SelectProps={{
                classes: { root: classes.listItem },
                multiple: false,
                renderValue: (selected: any) => {
                  var elementPos = businesses.filter(function (element) {
                    return element.bid === selected;
                  });
                  return language === "en"
                    ? elementPos[0].businessNameEn
                    : elementPos[0].businessNameAr;
                },
              }}
            >
              {businesses.map((option) => {
                return (
                  <MenuItem key={option.bid} value={option.bid}>
                    <Checkbox checked={field.value.includes(option.bid)} />
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography className={classes.listItem}>
                          {language === "en"
                            ? option.businessNameEn
                            : option.businessNameAr}
                        </Typography>
                      }
                      className={classes.listItem}
                    />
                  </MenuItem>
                );
              })}
            </CssTextField>
          );
        }}
      />

      {form.formState.errors.bid && (
        <Typography
          className={`form-error-text animate__animated animate__headShake`}
        >
          {dictionary.fieldRequired}
        </Typography>
      )}
    </FormControl>
  );
}
