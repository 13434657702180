import React, { createContext, useReducer, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { app } from "../services";
import { NotificationContext } from "./NotificationContext";

const initialState = {
  user: null,
  isPending: true,
  checkTokenStatus: (params: any) => {},
  clearToken: () => {},
};

export const AuthContext = createContext(initialState);

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "SET_AUTH":
      return {
        ...state,
        user: action.user,
        isPending: false,
      };
    default:
      break;
  }
  return state;
};

export const AuthContextProvider = (props: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { toggleContinue } = useContext(NotificationContext);

  const clearToken = () => {
    app
      .auth()
      .signOut()
      .then(function () {
        // Sign-out successful.
        console.log("logged out succesfully");
      })
      .catch(function (error) {
        toggleContinue("requestFail", error.code);
      });
  };

  useEffect(() => {
    app.auth().onAuthStateChanged((user) => {
      dispatch({
        type: "SET_AUTH",
        user: user,
      });
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        clearToken,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
