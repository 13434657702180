import React, { useContext } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {
  InputBase,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import { AccountContext } from "../../contexts";
const CssTextField = withStyles((theme) => ({
  root: theme.outlineTextfield,
}))(TextField);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    label: {
      color: "black",
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      fontSize: 13,
      marginBottom: 5,
    },
    listItem: {
      color: "black",
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.regular,
      fontSize: 15,
    },
  })
);

export default function SimpleSelect({ form, dictionary }) {
  const classes = useStyles();
  const [age, setAge] = React.useState("");
  // const { onSetMultForm, multiStepFormData } = useContext(AccountContext);
  // const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
  //   onSetMultForm({
  //     businessServices: [],
  //     businessType: event.target.value,
  //   });
  //   // console.log(multiStepFormData);
  // };

  return (
    <FormControl fullWidth={true} className={classes.formControl}>
      <Typography className={classes.label}>
        {dictionary.businessType}
      </Typography>
      <Controller
        control={form.control}
        name="businessType"
        rules={{ required: true }}
        render={({ field }) => {
          return (
            <CssTextField
              {...field}
              select
              variant="outlined"
              className={classes.listItem}
              SelectProps={{
                classes: { root: classes.listItem },
                multiple: false,
              }}
            >
              <MenuItem className={classes.listItem} value={"salon"}>
                {dictionary.salon}
              </MenuItem>
              <MenuItem className={classes.listItem} value={"photography"}>
                {dictionary.photography}
              </MenuItem>
              <MenuItem className={classes.listItem} value={"spa"}>
                {dictionary.spa}
              </MenuItem>
            </CssTextField>
          );
        }}
      />
      {form.formState.errors.businessType && (
        <Typography
          className={`form-error-text animate__animated animate__headShake`}
        >
          {dictionary.invalidBusinessType}
        </Typography>
      )}
    </FormControl>
  );
}
