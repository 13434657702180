import React, { useContext, useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  DashboardContext,
  LanguageContext,
  NotificationContext,
} from "../../../contexts";
import { Button, Fab, Grid, IconButton, Typography } from "@material-ui/core";
import {
  BusinessNameArTextfield,
  BusinessNameEnTextfield,
  BusinessServicesEdit,
  BusinessServicesSelect,
  BusinessTypeSelect,
} from "../..";
import { useForm } from "react-hook-form";
import EditIcon from "@material-ui/icons/Edit";
import BusinessPhoneTextfield from "../../textfields/BusinessPhoneTextfield";
import BusinessEmailTextfield from "../../textfields/BusinessEmailTextfield";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import BookingSelect from "../../selects/BookingSelect";
import BookingMaxSelect from "../../selects/BookingMaxSelect";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      justifyContent: "center",
      display: "flex",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    title: {
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      textAlign: "center",
      fontSize: 25,
      margin: 20,
    },
    details: {
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.regular,
      textAlign: "center",
    },
    button: {
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      textAlign: "center",
      margin: 10,
    },
    paper: { paddingBottom: 20 },
    editButton: {
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      borderColor: "black",
      color: "black",
      borderWidth: 2,
      borderRadius: 5,
      marginTop: 10,
      height: 30,
      fontSize: 13,
      textTransform: "none",
    },
    fab: {
      marginTop:40,
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      backgroundColor: "#3f51b5",
      color: "white",
      boxShadow: "none",
      "&:hover": {
        backgroundColor: "#3f51b5",
        color: "white",
        boxShadow: "none",
      },
      "&:active": {
        backgroundColor: "#3f51b5",
        color: "white",
        boxShadow: "none",
      },
    },
  })
);

export default function MaxWidthDialog({ businesses, dictionary }) {
  const classes = useStyles();
  const { onEditInfo, onAddBookingService } = useContext(DashboardContext);

  const [visible, setVisible] = useState(false);
  const form = useForm({
    defaultValues: {
      //   bid: business.bid,
      //  businesses: businesses,
    },
    reValidateMode: "onBlur",
    shouldUnregister: false,
  });
  const onSubmit = (data) => {
    onAddBookingService(data);
  };
  const { toggleContinue, messageDetails, messageTitle, isContinueAlert } =
    useContext(NotificationContext);

  return (
    <div className={classes.root}>
      {/* <input type="hidden" {...form.register("bid")} /> */}
      <Fab
        className={classes.fab}
        variant="extended"
        onClick={() => setVisible(true)}
      >
        <AddIcon className={classes.extendedIcon} />
        {dictionary.addBookings}
      </Fab>
      <Dialog
        classes={{
          paper: classes.paper,
        }}
        fullWidth={true}
        maxWidth={"sm"}
        open={visible}
        onClose={() => {}}
        aria-labelledby="max-width-dialog-title"
      >
        <IconButton
          aria-label="edit"
          style={{ position: "absolute", top: 0, right: 0 }}
          onClick={() => setVisible(false)}
        >
          <CloseIcon style={{ fontSize: 40 }} />
        </IconButton>
        <DialogTitle id="max-width-dialog-title">
          <Typography className={classes.title}>
            {dictionary.addBookings}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item style={{ width: "95%" }}>
              <BookingSelect
                form={form}
                businesses={businesses}
                dictionary={dictionary}
              />
            </Grid>
            <Grid item style={{ width: "95%" }}>
              <BookingMaxSelect form={form} dictionary={dictionary} />
            </Grid>
            <Grid item style={{ width: "60%", minWidth: 250 }}>
              <Button
                onClick={form.handleSubmit(onSubmit)}
                variant="outlined"
                className="btn-primary"
              >
                {dictionary.confirm}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
