import { Button, Card, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import {
  ActivityIndicator,
  NewBusinessDetails,
  NewBusinessRegister,
} from "../../components";
import { AccountContext, LanguageContext } from "../../contexts";
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    maxWidth: 600,
    width: "90vw",
    marginTop: 50,
    marginBottom: 50,
    // maxHeight: 200,
    borderWidth: 0,
    boxShadow: "0 0 50px rgba(33,33,33,.2);",
    borderRadius: 30,
    padding: 20,
    paddingTop: 40,
    paddingBottom: 40,
    transition: "box-shadow .3s",
    [theme.breakpoints.down(450)]: {},
  },
}));
const BusinessRegister = () => {
  const classes = useStyles();
  const {
    businessRegisterStep,
    isLoading,
    onRegisterUser,
    toggleBusinessRegisterStep,
  } = useContext(AccountContext);
  const { dictionary } = useContext(LanguageContext);
  const form = useForm({
    reValidateMode: "onBlur",
    shouldUnregister: false, // unmount input state will be remained
  });
  // const onSubmit = (data) => {
  //   const submit = { data, form };
  //   //console.log("DATA SUBMITTED : " + JSON.stringify(data));
  //   // if (businessRegisterStep === 0) {
  //   //   return toggleBusinessRegisterStep(1);
  //   // }
  //   // console.log("errors SUBMITTED : " + JSON.stringify(form.errors));

  //   // return onRegisterUser(submit);
  // };
  // const onError = (errors, e) =>{
  //   if (businessRegisterStep === 0) {
  //     return toggleBusinessRegisterStep(1);
  //   }
  // }

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item>
        <Card
          className={`animate__animated faster animate__fadeIn ${classes.root}`}
          variant="outlined"
        >
          <div style={{ display: businessRegisterStep == 2 ? "none" : "" }}>
            <NewBusinessRegister dictionary={dictionary} />
          </div>

          <div style={{ display: businessRegisterStep == 1 ? "none" : "" }}>
            <NewBusinessDetails dictionary={dictionary} />
          </div>
        </Card>
      </Grid>
    </Grid>
  );
};

export default BusinessRegister;
