import React, { useContext } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import Container from "@material-ui/core/Container";
import { Images } from "../../../../themes";
import { Typography } from "@material-ui/core";
import { dictionaryList } from "../../../../services/languages";
import { LanguageContext } from "../../../../contexts";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
  images: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexWrap: "wrap",
  },
  imageWrapper: {
    position: "relative",
    display: "block",
    padding: 0,
    borderRadius: 0,
    height: "40vh",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      height: 200,
    },
    "&:hover": {
      zIndex: 1,
    },
    "&:hover $imageBackdrop": {
      opacity: 0.15,
    },
    "&:hover $imageMarked": {
      opacity: 0,
    },
    "&:hover $imageTitle": {
      border: "4px solid currentColor",
    },
  },
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
  imageBackdrop: {
    // position: "absolute",
    // left: 0,
    // right: 0,
    // top: 0,
    // bottom: 0,
    // background: theme.palette.common.black,
    // opacity: 0.5,
    // transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    position: "relative",
    fontSize: 25,
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px 14px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    background: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
  title: {
    textAlign: "center",
    fontSize: 45,
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    [theme.breakpoints.down(450)]: {
      fontSize: 30,
    },
  },
});

function ProductCategories(props) {
  const { classes } = props;
  const { dictionary } = useContext(LanguageContext);

  const images = [
    {
      url: Images.bannerV1,
      title: "salon",
      width: "33%",
    },
    {
      url: Images.spa_bg,
      title: "spa",
      width: "33%",
    },
    {
      url: Images.bannerV2,
      title: "photography",
      width: "33%",
    },
  ];

  return (
    <Container className={classes.root} component="section">
      <Typography className={classes.title}>
        {dictionary.ourServices}
      </Typography>
      <div className={classes.images}>
        {images.map((image) => (
          <div
            key={image.title}
            className={classes.imageWrapper}
            style={{
              width: image.width,
            }}
          >
            <div
              className={classes.imageSrc}
              style={{
                backgroundImage: `url(${image.url})`,
              }}
            />
            {/* <div className={classes.imageBackdrop} /> */}
            <div className={classes.imageButton}>
              <Typography
                component="h3"
                variant="h6"
                color="inherit"
                className={classes.imageTitle}
              >
                {dictionary[image.title]}

                {/* <div className={classes.imageMarked} /> */}
              </Typography>
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
}

ProductCategories.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles as any)(ProductCategories);
