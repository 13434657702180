import React, { useContext } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import { CustomerContext, LanguageContext } from "../contexts";
import { ResultsListItem, ShopSearchBox } from ".";
import { CircularProgress, Container, Grid } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { Images } from "../themes";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    appBar: {
      background: "transparent",
      boxShadow: "none",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    searchbar: {
      flexGrow: 1,
      textAlign: "center",
      color: "black",
      paddingTop: 10,
    },
    sectionDesktop: {
      position: "absolute",
      display: "none",
      [theme.breakpoints.up(800)]: {
        display: "flex",
      },
    },
    sectionMobile: {
      position: "fixed",
      bottom: 10,
      width: "100%",
      marginTop: 100,
      display: "flex",
      [theme.breakpoints.up(800)]: {
        display: "none",
      },
    },
    mainFooter: {
      position: "fixed",
      bottom: 10,
      width: "100%",
      marginTop: 100,
    },
    text: {
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      textAlign: "center",
    },
    bottomCloseButton: {
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      color: "#1b1e3e",
      borderWidth: 0,
      fontSize: 25,
    },
    icon: {
      width: 80,
      height: 80,
      textAlign: "center",
      color: theme.isDarkMode ? "white" : "black",
    },
    resultMessage: {
      fontSize: 19,
      opacity: 0.7,
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      color: theme.isDarkMode ? "white" : "black",
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog() {
  const classes = useStyles();
  const {
    isShopSearch,
    onToggleShopSearch,
    businesses,
    onGetMoreBusinesses,
    isMoreItems,
    search,
  } = useContext(CustomerContext);
  const { dictionary } = useContext(LanguageContext);
  const history = useHistory();

  const handleClose = () => {
    onToggleShopSearch();
  };

  const listRender = (search, businesses, isMoreItems, dictionary, history) => {
    if (isMoreItems && businesses.length == 0) {
      return (
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="center"
          //style={{ minHeight: "100vh" }}
          spacing={2}
        >
          <Grid item sm={12}>
            <CircularProgress style={{ color: "black" }} />
          </Grid>
        </Grid>
      );
    } else if (businesses.length === 0 && !isMoreItems) {
      return (
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="center"
          spacing={1}
        >
          <Grid item sm={12}>
            <img
              src={!search ? Images.shop : Images.notFound}
              className={classes.icon}
            />
          </Grid>
          <Grid item sm={12}>
            <Typography className={classes.resultMessage}>
              {!search ? dictionary.typeBusinessName : dictionary.noResults}
            </Typography>
          </Grid>
        </Grid>
      );
    }

    return businesses.map((business, i) => (
      <ResultsListItem
        key={i}
        business={business}
        dictionary={dictionary}
        history={history}
      />
    ));
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={isShopSearch}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <div className={classes.root}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <div className={classes.sectionDesktop}>
                <IconButton
                  edge="start"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon style={{ color: "#1b1e3e", fontSize: 40 }} />
                </IconButton>
              </div>
              <div className={classes.searchbar}>
                {isShopSearch && <ShopSearchBox readonly={false} />}
              </div>
            </Toolbar>
          </AppBar>
          <div
            style={{
              height: "80vh",
              marginTop: 90,
              overflow: "auto",
              maxHeight: "100vh",
            }}
            id="scrollableDiv"
          >
            <InfiniteScroll
              style={{
                overflow: "hidden",
                paddingBottom: 50,
                paddingTop: 25,
              }}
              dataLength={businesses.length}
              next={() => onGetMoreBusinesses()}
              hasMore={isMoreItems}
              loader={<div />}
              scrollableTarget="scrollableDiv"
            >
              {listRender(search, businesses, isMoreItems, dictionary, history)}
            </InfiniteScroll>
          </div>

          <div className={classes.sectionMobile}>
            <AppBar
              position="static"
              style={{
                backgroundColor: "white",
                boxShadow: "0px -1px 1px rgba(22, 22, 22, 0.15)",

                textAlign: "center",
                height: 50,
              }}
            >
              <Container>
                <Button
                  onClick={handleClose}
                  className={classes.bottomCloseButton}
                  variant="outlined"
                >
                  Go Back
                </Button>
              </Container>
            </AppBar>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
