import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { InputAdornment, TextField, withStyles } from "@material-ui/core";
import { Controller } from "react-hook-form";

const CssTextField = withStyles((theme) => ({
  root: theme.outlineTextfield,
}))(TextField);

const PriceTextfield = ({ form, dictionary, price }) => {
  return (
    <Grid item xs={12} sm={6} style={{ width: "95%" }}>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={1}
      >
        <Grid item xs style={{ width: "95%", marginTop: 20 }}>
          <Typography
            style={{
              fontFamily: "Roboto-Bold",
              textAlign: "center",
              fontSize: 18,
            }}
          >
            {dictionary[price.name]}
          </Typography>
        </Grid>
        <Grid item xs style={{ width: "95%" }}>
          <Controller
            defaultValue={price.priceFrom}
            name={price.name}
            control={form.control}
            rules={{ required: true, pattern: /^(0?[1-9]|1[012]){1,10}$/ }}
            render={({ field }) => (
              <CssTextField
                {...field}
                autoComplete="off"
                fullWidth={true}
                variant="outlined"
                type="number"
                InputProps={{
                  endAdornment: (
                    <span style={{ fontFamily: "Roboto-Bold" }}>SAR</span>
                  ),
                }}
              />
            )}
          />
          {form.formState.errors[price.name] && (
            <Typography
              className={`form-error-text animate__animated animate__headShake`}
            >
              {dictionary.invalidPrice}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PriceTextfield;
