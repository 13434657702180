export const fonts = {
  ar: {
    name: "Almarai",
    regular: "400",
    bold: "700",
  },
  en: {
    name: "Roboto",
    regular: "400",
    bold: "700",
  },
};
