import React, { useContext } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import { LanguageContext, NotificationContext } from "../../contexts";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      textAlign: "center",
      fontSize: 25,
      margin: 20,
    },
    details: {
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.regular,
      textAlign: "center",
    },
    button: {
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      textAlign: "center",
      margin: 10,
    },
    root: {},
    paper: { borderRadius: 25 },
  })
);

export default function MaxWidthDialog() {
  const classes = useStyles();
  const { dictionary } = useContext(LanguageContext);
  const {
    toggleConfirm,
    messageDetails,
    messageTitle,
    isConfirmAlert,
    confirmAction,
  } = useContext(NotificationContext);

  const handleClose = () => {
    toggleConfirm(messageTitle, messageDetails, confirmAction);
  };

  return (
    <React.Fragment>
      <Dialog
        classes={{
          root: classes.root,
          paper: classes.paper,
        }}
        fullWidth={true}
        maxWidth={"sm"}
        open={isConfirmAlert}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
          <Typography className={classes.title}>
            {dictionary[messageTitle] || dictionary["warn"]}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography className={classes.details}>
            {dictionary[messageDetails] || dictionary["tryLater"]}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            <Typography className={classes.button}>
              {dictionary.cancel}
            </Typography>
          </Button>
          <Button
            onClick={() => {
              confirmAction();
              handleClose();
            }}
            color="primary"
          >
            <Typography className={classes.button}>
              {dictionary.confirm}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
