import React, { useContext, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import QRCode from "qrcode.react";

import {
  Button,
  Card,
  Grid,
  makeStyles,
  Typography,
  createStyles,
  Theme,
  CircularProgress,
  Container,
  withStyles,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import {
  AccountContext,
  CustomerContext,
  DashboardContext,
  LanguageContext,
} from "../../../contexts";

import { Images } from "../../../themes";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import CustomerNameTextfield from "../../textfields/CustomerNameTextfield";
import CustomerPhoneTextfield from "../../textfields/CustomerPhoneTextfield";
import clsx from "clsx";
import Check from "@material-ui/icons/Check";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import StepConnector from "@material-ui/core/StepConnector";
import { styles } from "../../../pages/welcome/modules/components/Toolbar";
import BookingDateSelect from "../../selects/BookingDateSelect";
import BookingTimeSelect from "../../selects/BookingTimeSelect";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: 10,
    },
    appBar: {
      background: "transparent",
      boxShadow: "none",
    },
    searchbar: {
      flexGrow: 1,
      textAlign: "center",
      color: "black",
      paddingTop: 10,
    },
    sectionDesktop: {
      position: "absolute",
      display: "none",
      [theme.breakpoints.up(800)]: {
        display: "flex",
      },
    },
    mainFooter: {
      position: "fixed",
      bottom: 10,
      width: "100%",
      marginTop: 100,
    },
    text: {
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      textAlign: "center",
    },
    subtext: {
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.regular,
      fontSize: 23,
      textAlign: "center",
    },
    cardTitle: {
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      textAlign: "center",
      opacity: 0.7,
    },
    bottomCloseButton: {
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      color: "#1b1e3e",
      borderWidth: 0,
      fontSize: 25,
    },
    icon: {
      width: 80,
      height: 80,
      textAlign: "center",
      color: theme.isDarkMode ? "white" : "black",
    },
    resultMessage: {
      fontSize: 19,
      opacity: 0.7,
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      color: theme.isDarkMode ? "white" : "black",
    },
    stepTitle: {
      color: "black",
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      fontSize: 12,
      // textAlign: "center",
    },
    bookButton: {
      borderRadius: 30,
      fontSize: 14,
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      width: "100%",
      height: 50,
      backgroundColor: theme.colors.primary,
      color: theme.colors.secondary,
      textTransform: "none",
      transition: "transform .3s",
      transform: "scale(1)",
      "&:hover": {
        backgroundColor: theme.colors.primary,
        color: theme.colors.secondary,
        transform: "scale(1.1)",
      },
    },
    logo: {
      width: 70,
      height: 70,
    },
    subCard: {
      maxWidth: 340,
      width: "90vw",
      // height: 350,
      borderWidth: 0,
      boxShadow: "0 0 50px rgba(33,33,33,.2);",
      borderRadius: 30,
      paddingTop: 15,
      paddingBottom: 20,
      marginBottom: 20,
      marginTop: 20,

      transition: "box-shadow .3s",
    },
  })
);
const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  },
});

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);
function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <AccountCircleIcon />,
    2: <EventAvailableIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomerInfoModal() {
  const classes = useStyles();

  const { bookingSubmitted, bookingInfo, business, onResetForm } =
    useContext(CustomerContext);
  const { dictionary } = useContext(LanguageContext);
  const { businessLocation } = useContext(AccountContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isStepOne, setIsStepOne] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const steps = [dictionary.customerInfo, dictionary.selectDate];
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const { onToggleAddBusinessModal, isAddBusinessModal, onAddBusiness } =
    useContext(DashboardContext);
  const { onAddCustomerBooking } = useContext(CustomerContext);
  const history = useHistory();
  const form = useForm({
    defaultValues: {
      businessRegion: [],
      businessServices: [],
      businessType: [],
    },
    reValidateMode: "onBlur",
    // shouldUnregister: false,
  });
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleOpen = () => {
    form.reset();
    setIsStepOne(true);
    onResetForm();
    setIsOpen(true);
  };

  const onSubmit = (data) => {
    setIsStepOne(false);
    handleNext();
    if (!isStepOne) {
      let submit = { data, form };
      onAddCustomerBooking(submit);
    }
  };

  return (
    <div>
      <Button
        onClick={() => handleOpen()}
        type="submit"
        variant="outlined"
        className={classes.bookButton}
      >
        {dictionary.bookNow}
      </Button>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <div className={classes.root}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <div className={classes.sectionDesktop}>
                <IconButton
                  edge="start"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon style={{ color: "#1b1e3e", fontSize: 40 }} />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
        </div>
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="center"
          className={`animate__animated  animate__fadeIn ${classes.root}`}
        >
          {bookingInfo ? (
            <div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={Images.success} />
              </div>
              <Typography className={classes.subtext}>
                {dictionary.bookingSubmitted}
                <Typography className={classes.text} style={{ fontSize: 30 }}>
                  {dictionary.lang == "ar"
                    ? business.businessNameAr
                    : business.businessNameEn}
                </Typography>
              </Typography>
              <Grid item>
                <Card
                  className={`animate__animated faster animate__fadeIn ${classes.subCard}`}
                  variant="outlined"
                >
                  <Typography className={classes.cardTitle}>
                    {dictionary.bookingNumber}
                  </Typography>
                  <br />
                  <Typography className={classes.subtext}>
                    {bookingInfo.bookingId.split("").join(" ").toUpperCase()}
                  </Typography>
                </Card>
              </Grid>
              <Grid item>
                <Card
                  className={`animate__animated faster animate__fadeIn ${classes.subCard}`}
                  variant="outlined"
                >
                  <Typography className={classes.cardTitle}>
                    {dictionary.bookingDate}
                  </Typography>
                  <br />
                  <Typography className={classes.subtext}>
                    {bookingInfo.bookingDate}
                  </Typography>
                  <Typography className={classes.subtext}>
                    {bookingInfo.bookingTime}
                  </Typography>
                </Card>
              </Grid>
              <Grid item>
                <Card
                  className={`animate__animated faster animate__fadeIn ${classes.subCard}`}
                  variant="outlined"
                >
                  <Typography className={classes.cardTitle}>
                    {dictionary.useBookingQR}
                  </Typography>
                  <br />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <QRCode
                      value={`https://allura.app/booking/${bookingInfo.bookingId}`}
                    />
                  </div>
                </Card>
              </Grid>
              <Grid item style={{ minWidth: 250 }}>
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  className="btn-primary"
                >
                  {dictionary.back}
                </Button>
              </Grid>
            </div>
          ) : (
            <div>
              <Stepper
                alternativeLabel
                activeStep={activeStep}
                connector={<ColorlibConnector />}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      <div className={classes.stepTitle}> {label}</div>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
              <Grid item>
                {isStepOne && (
                  <form onSubmit={form.handleSubmit(onSubmit)}>
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="center"
                      //   style={{ minHeight: "100%" }}
                      spacing={3}
                    >
                      <Grid item style={{ width: "90%", minWidth: 350 }}>
                        <CustomerNameTextfield
                          form={form}
                          dictionary={dictionary}
                        />
                      </Grid>
                      <Grid item style={{ width: "90%", minWidth: 350 }}>
                        <CustomerPhoneTextfield
                          form={form}
                          dictionary={dictionary}
                        />
                      </Grid>
                      <Grid item style={{ width: "60%", minWidth: 350 }}>
                        <Button
                          type="submit"
                          variant="outlined"
                          className="btn-primary"
                        >
                          {dictionary.selectDate}
                        </Button>
                      </Grid>
                      <Grid item style={{ width: "60%", minWidth: 250 }}>
                        <Button
                          onClick={handleClose}
                          className="btn-clear"
                          variant="contained"
                        >
                          {dictionary.back}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )}
                {!isStepOne && (
                  <form
                    // style={{ position: "absolute", top: 50 }}
                    onSubmit={form.handleSubmit(onSubmit)}
                  >
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="center"
                      //   style={{ minHeight: "100%" }}
                      spacing={3}
                    >
                      <Grid item style={{ width: "90%", minWidth: 350 }}>
                        <BookingDateSelect
                          form={form}
                          dictionary={dictionary}
                        />
                      </Grid>
                      <Grid item style={{ width: "90%", minWidth: 350 }}>
                        <BookingTimeSelect
                          form={form}
                          dictionary={dictionary}
                        />
                      </Grid>
                      <Grid item style={{ width: "60%", minWidth: 350 }}>
                        <Button
                          type="submit"
                          variant="outlined"
                          className="btn-primary"
                        >
                          {dictionary.confirm}
                        </Button>
                      </Grid>
                      <Grid item style={{ width: "60%", minWidth: 250 }}>
                        <Button
                          onClick={() => {
                            setIsStepOne(true);
                            handleBack();
                          }}
                          className="btn-clear"
                          variant="contained"
                        >
                          {dictionary.back}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Grid>
            </div>
          )}
        </Grid>
      </Dialog>
    </div>
  );
}
