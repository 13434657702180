import React, { useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField, withStyles } from "@material-ui/core";
import { Controller } from "react-hook-form";
import InstagramIcon from "@material-ui/icons/Instagram";
import { DashboardContext } from "../../contexts";

const CssTextField = withStyles((theme) => ({
  root: theme.outlineTextfield,
}))(TextField);

const useStyles = makeStyles((theme) => ({
  shareButton: {
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    //backgroundColor: "transparent",
    borderColor: "black",
    color: "black",
    borderWidth: 2,
    borderRadius: 5,

    opacity: 0.7,
    height: 30,
    fontSize: 13,
    textTransform: "none",
  },
}));

const InstagramTextfield = ({ form, dictionary }) => {
  //const [value, setValue] = useState("");
  const classes = useStyles();
  const { onCopyToClipboard } = useContext(DashboardContext);
  const linkCopy = (data) => {
    onCopyToClipboard(data.instagram);
  };
  return (
    <div>
      <Controller
        name="instagram"
        control={form.control}
        rules={{
          required: false,
          pattern:
            /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/,
        }}
        render={({ field }) => (
          <CssTextField
            {...field}
            autoComplete="off"
            fullWidth={true}
            label={dictionary["instagram"]}
            variant="outlined"
            placeholder={dictionary["instagramPlaceholder"]}
            InputProps={{
              startAdornment: <InstagramIcon />,
              // endAdornment: (
              //   <Button
              //     onClick={form.handleSubmit(linkCopy)}
              //     className={classes.shareButton}
              //     variant="outlined"
              //   >
              //     {dictionary.copy}
              //   </Button>
              // ),
            }}
          />
        )}
      />
      {form.formState.errors.instagram && (
        <Typography
          className={`form-error-text animate__animated animate__headShake`}
        >
          {dictionary.invalidLink}
        </Typography>
      )}
    </div>
  );
};

export default InstagramTextfield;
