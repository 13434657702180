import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, withStyles } from "@material-ui/core";
import { Controller } from "react-hook-form";

const CssTextField = withStyles((theme) => ({
  root: theme.outlineTextfield,
}))(TextField);

const NameTextfield = ({ form, dictionary }) => {
  return (
    <div>
      <Controller
        name="customerName"
        control={form.control}
        rules={{
          required: true,
          // pattern: /^([\u0621-\u064Aa-zA-Z_-\s]){3,30}$/,
        }}
        render={({ field }) => (
          <CssTextField
            {...field}
            autoComplete="off"
            fullWidth={true}
            label={dictionary.customerName}
            variant="outlined"
          />
        )}
      />
      {form.formState.errors.customerName && (
        <Typography
          className={`form-error-text animate__animated animate__headShake`}
        >
          {dictionary.invalidName}
        </Typography>
      )}
    </div>
  );
};

export default NameTextfield;
