import React, { useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { DashboardContext, LanguageContext } from "../../../contexts";
export const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "center",
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    // position: "relative",
    // bottom: "10%",
    // right: "auto",
    // left: "auto",
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    backgroundColor: "#3f51b5",
    color: "white",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#3f51b5",
      color: "white",
      boxShadow: "none",
    },
    "&:active": {
      backgroundColor: "#3f51b5",
      color: "white",
      boxShadow: "none",
    },
  },
}));

const AddBusinessButton = () => {
  const classes = useStyles();
  const { dictionary } = useContext(LanguageContext);
  const { onToggleAddBusinessModal } = useContext(DashboardContext);

  return (
    <div className={classes.root}>
      <Fab
        className={classes.fab}
        variant="extended"
        onClick={() => onToggleAddBusinessModal()}
      >
        <AddIcon className={classes.extendedIcon} />
        {dictionary.addBusiness}
      </Fab>
    </div>
  );
};

export default AddBusinessButton;
