import { app, db } from "../config";

export const addBookingService = async (
  submit, getBusinesses,
  { toggleContinue, toggleToast }
) => {
  // let newLanguage = language === "ar" ? "en" : "ar";
  let date = Date.now().toString();
  let body = {
    bookingsService: true,
    bookingsMax: submit.bookingMax,
    updatedAt: date,
  };

  db.collection("businesses")
    .doc(submit.bid)
    .update(body)
    .then(() => {
      toggleToast("updated", "success");
      getBusinesses();
    })
    .catch(function (error) {
      console.log(error.code);
      toggleContinue("requestFail", error.code, true);
    });
};
