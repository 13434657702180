import React, { useContext } from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MailIcon from "@material-ui/icons/Mail";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MoreIcon from "@material-ui/icons/MoreVert";
import { Button, Slide } from "@material-ui/core";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import LanguageIcon from "@material-ui/icons/Language";
import { AccountContext, LanguageContext } from "../contexts";
import { useHistory } from "react-router-dom";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { Images } from "../themes";
import BusinessIcon from "@material-ui/icons/Business";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "transparent",
    boxShadow: "none",
    position: "absolute",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  logo: {
    marginLeft: "10%",
    marginTop: 20,
    height: 100,
    width: 100,
    display: "none",
    [theme.breakpoints.up(100)]: {
      display: "block",
    },
  },
  languageButton: {
    fontWeight: theme.fonts.bold,
    borderColor: "white",
    color: "white",
    borderWidth: 0,
    borderRadius: 5,
    marginLeft: 10,
    marginRight: 10,
  },
  signup: {
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    borderColor: "white",
    color: "white",
    borderWidth: 0,
    borderRadius: 5,
    marginLeft: 10,
    marginRight: 10,
  },
  login: {
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    borderColor: "white",
    color: "white",
    borderWidth: 2,
    borderRadius: 5,
    marginLeft: 10,
    marginRight: 10,
  },
  mobileMenuItem: {
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    borderColor: "white",
    color: "black",
    borderWidth: 0,
    borderRadius: 5,
    marginLeft: 10,
    marginRight: 10,
  },
  bookingButton: {
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    borderColor: "white",
    color: "white",
    borderWidth: 0,
    borderRadius: 5,
    marginLeft: 10,
    marginRight: 10,
  },
  sectionDesktop: {
    marginRight: "10%",
    display: "none",
    [theme.breakpoints.up(940)]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up(940)]: {
      display: "none",
    },
  },
}));

export default function WelcomeToolbar() {
  const classes = useStyles();
  const history = useHistory();
  const { dictionary, onSetNewLanguage, language } =
    useContext(LanguageContext);
  const trigger = useScrollTrigger();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleRegister = (event) => {
    history.push(`/register`);
  };

  const handleLogin = (event) => {
    history.push(`/dashboard`);
  };

  const handleLanguage = (event) => {
    language === "ar" ? onSetNewLanguage("en") : onSetNewLanguage("ar");
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>LOGIN</MenuItem>
      <MenuItem onClick={handleMenuClose}>REGISTER</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem onClick={handleLanguage}>
        <IconButton color="inherit">
          <Badge color="secondary">
            <LanguageIcon />
          </Badge>
        </IconButton>
        <p>English</p>
      </MenuItem> */}
      {/* <MenuItem>
        <IconButton color="inherit">
          <Badge color="secondary">
            <BookmarkIcon />
          </Badge>
        </IconButton>
        <p>Manage Booking</p>
      </MenuItem> */}
      <MenuItem onClick={handleLogin}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p className={classes.mobileMenuItem}>{dictionary.login}</p>
      </MenuItem>
      <MenuItem onClick={handleRegister}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <BusinessCenterIcon />
        </IconButton>
        <p className={classes.mobileMenuItem}>{dictionary.signup}</p>
      </MenuItem>
      {/* <MenuItem onClick={handleRegister}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <BookmarkIcon />
        </IconButton>
        <p className={classes.mobileMenuItem}>{dictionary.bookings}</p>
      </MenuItem> */}
      <MenuItem onClick={handleLanguage}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <LanguageIcon />
        </IconButton>
        <p
          className={classes.mobileMenuItem}
          style={{
            fontFamily: dictionary.lang === "ar" ? "Roboto" : "Almarai",
          }}
        >
          {language === "ar" ? dictionary.en : dictionary.ar}
        </p>
      </MenuItem>
    </Menu>
  );

  return (
    <div style={{ position: "relative", top: 0 }}>
      <Slide appear={false} direction="down" in={!trigger}>
        <AppBar position="static" className={classes.root}>
          <Toolbar>
            {/* <img className={classes.logo} src={Images.logo} /> */}
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <Button
                onClick={handleLogin}
                startIcon={<AccountCircleIcon />}
                className={classes.login}
                variant="outlined"
              >
                {dictionary.login}
              </Button>

              <Button
                startIcon={<BusinessCenterIcon />}
                onClick={handleRegister}
                className={classes.signup}
                variant="outlined"
              >
                {dictionary.signup}
              </Button>

              {/* <Button
                startIcon={<BookmarkIcon />}
                className={classes.bookingButton}
                variant="outlined"
              >
                {dictionary.bookings}
              </Button> */}
              <Button
                onClick={handleLanguage}
                startIcon={<LanguageIcon />}
                className={classes.languageButton}
                style={{
                  fontFamily: dictionary.lang === "ar" ? "Roboto" : "Almarai",
                }}
                variant="outlined"
              >
                {language === "ar" ? dictionary.en : dictionary.ar}
              </Button>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MenuIcon style={{ fontSize: 40 }} />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
      </Slide>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}
