import React, { useContext } from "react";
import { LanguageContext, AccountContext } from "../../contexts";
import Button from "@material-ui/core/Button";

const Logout = () => {
  const { dictionary } = useContext(LanguageContext);
  const { onLogoutUser } = useContext(AccountContext);

  return (
    <Button
      variant="contained"
      className="btn-primary"
      onClick={() => {
        onLogoutUser();
      }}
    >
      {dictionary.logout}
    </Button>
  );
};

export default Logout;
