export const lightTheme = {
  primary: "#313451",
  secondary: "white",
  info: "#3949ab",
  danger: "#f44336",
  success: "#689f38",
  warn: "#F9A825",
  background: "#00adb5",
};

export const darkTheme = {
  primary: "#abedd8",
  secondary: "#48466d",
  info: "#3949ab",
  danger: "#f44336",
  success: "#689f38",
  warn: "#F9A825",
  background: "#393e46",
};
