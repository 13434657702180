import { app, db } from "..";

const removeDuplicates = (data, key) => {
  return [...(new Map(data.map((x) => [key[x], x])).values() as any)];
};

export const getMoreBusinesses = async ({
  dispatch,
  state,
  toggleContinue,
}) => {
  console.log("getting more businesses ...");

  let index = "null";
  if (state.businesses.length > 0) {
    index =
      state.businesses[state.businesses.length - 1].businessNameEn_insensitive;
  }
  console.log("INDEX : " + index);
  const cleanText = state.search.toLowerCase();
  const regex = /^[\u0621-\u064A\u0660-\u0669 ]+$/.test(cleanText);
  const startFrom = regex ? "businessNameAr" : "businessNameEn_insensitive";
  const endTo = regex ? "\u064A" : "\uf8ff";
  const ordering = regex ? "businessNameAr" : "businessNameEn_insensitive";

  db.collection("businesses")
    .where(startFrom, ">=", cleanText)
    .where(startFrom, "<=", cleanText + endTo)
    .orderBy(ordering)
    .startAfter(index)
    .limit(10)
    .get({ source: "server" }) // this "source" is for forcing data from server instead of cache
    .then(async (querySnapshot) => {
      let items = [] as any;
      if (querySnapshot.docs[0]) {
        items = state.businesses.concat(
          querySnapshot.docs.map((doc) => {
            return Object.assign({}, doc.data());
          })
        );
        // items = items.filter((obj, key, array) =>
        //   array.map((obj2) => obj.bid !== obj2.bid)
        // );
      } else {
        items = state.businesses;
      }
      //console.log("more items size : " + items.length);
      dispatch({
        type: "SET_MULTI_BUSINESS",
        payload: items,
        isMoreItems: querySnapshot.docs[0] ? true : false,
      });
    })
    .catch(function (error) {
      console.log(error);
      toggleContinue("requestFail", error.code, true);
    });
};
