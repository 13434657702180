import React, { useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField, withStyles } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import { DashboardContext } from "../../contexts";
import { Controller } from "react-hook-form";

const CssTextField = withStyles((theme) => ({
  root: theme.outlineTextfield,
}))(TextField);

const useStyles = makeStyles((theme) => ({
  prefix: {
    paddingRight: 20,
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
  },
}));

const AlluraLinkTextfield = ({ form, dictionary }) => {
  //const [value, setValue] = useState("");
  const classes = useStyles();
  const { onCopyToClipboard } = useContext(DashboardContext);
  // const linkCopy = (data) => {
  //   onCopyToClipboard("allura.app/" + data.link);
  // };
  return (
    <div dir={"ltr"}>
      <Controller
        name="link"
        control={form.control}
        rules={{ required: true, pattern: /^([a-zA-Z]){3,15}$/ }}
        render={({ field }) => (
          <CssTextField
            {...field}
            fullWidth={true}
            label={dictionary["alluraLink"]}
            variant="outlined"
            placeholder={dictionary["alluraLinkPlaceholder"]}
            InputProps={{
              startAdornment: (
                <span className={classes.prefix}>allura.app/</span>
              ),
            }}
          />
        )}
      />
      {form.formState.errors.link && (
        <Typography
          className={`form-error-text animate__animated animate__headShake`}
        >
          {dictionary.businessLinkInvalid}
        </Typography>
      )}
    </div>
  );
};

export default AlluraLinkTextfield;
