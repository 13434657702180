import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { Grid, Divider, Link, IconButton } from "@material-ui/core";
import { Images } from "../../../themes";
//import BasicInfoForm from "./EditBasicInfo";
import ShareIcon from "@material-ui/icons/Share";
import LinkIcon from "@material-ui/icons/Link";
import { Button } from "@material-ui/core";
import {
  EditBusinessLocation,
  EditBusinessServices,
  EditBusinessPrices,
  BusinessStatusSwitch,
  EditBasicInfo,
  EditOpeningHours,
  EditMediaLinks,
  BusinessTypeSelect,
  QRGenerator,
} from "../..";
import { LanguageContext, NotificationContext } from "../../../contexts";
import { ImageSearch } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10,
    paddingBottom: 50,
    [theme.breakpoints.up(850)]: {
      display: "none",
    },
  },
  cardRoot: {
    minWidth: 275,
    maxWidth: 400,
    width: "90vw",
    //height: 600,
    borderWidth: 0,
    boxShadow: "0 0 10px rgba(33,33,33,.2);",
    borderRadius: 30,
    //padding: 20,
    //  paddingLeft: 20,
    paddingBottom: 20,

    transition: "box-shadow .3s",
  },
  logo: {
    width: 100,
    height: 100,
  },
  divider: {
    backgroundColor: "black",
    opacity: 0.2,
    height: 2,
    marginTop: 20,
    width: 250,
    borderRadius: 50,
    [theme.breakpoints.down(850)]: {
      display: "none",
    },
  },
  informationContainer: {
    height: 80,
    // width: 480,
    // marginLeft: 20,
    // [theme.breakpoints.down(850)]: {
    //   height: 230,
    //   width: 380,
    //   marginLeft: 10,
    // },
  },
  title: {
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    fontSize: 15,
    textAlign: "center",
    opacity: 0.5,
    marginTop: 20,
  },
  details: {
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    fontSize: 24,
  },
}));

export default function BookingsCardMobile({ business, dictionary, history }) {
  const classes = useStyles();
  let url = window.location.host;
  const { toggleToast } = useContext(NotificationContext);
  const { language } = useContext(LanguageContext);

  return (
    <Grid
      direction="column"
      container
      className={classes.root}
      justify="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        <Card className={classes.cardRoot} variant="outlined">
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{ minHeight: "75%", paddingTop: 30 }}
            spacing={0}
          >
            <Grid item>
              <img src={Images.appointment} className={classes.logo} />
            </Grid>
            <Grid item>
              <Divider flexItem className={classes.divider} />
            </Grid>
            <Grid item className={classes.informationContainer}>
              <Typography className={classes.title}>
                {dictionary.businessName}
              </Typography>
              <Typography className={classes.details}>
                {language === "en"
                  ? business.businessNameEn
                  : business.businessNameAr}
              </Typography>
            </Grid>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              spacing={3}
            >
              <Grid item>
                <Button
                  // onClick={handleDelete}
                  variant="outlined"
                  className="btn-success"
                  style={{
                    position: "relative",
                    width: 200,
                    height: 40,
                    borderRadius: 50,
                    marginTop: 20,
                    fontSize: 15,
                  }}
                >
                  {dictionary.viewBookings}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}
