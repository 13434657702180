import React, { useContext, useEffect } from "react";
import ProductCategories from "./modules/views/ProductCategories";
import ProductSmokingHero from "./modules/views/ProductSmokingHero";
import WhatIsAllura from "./modules/views/WhatIsAllura";
import AppFooter from "./modules/views/AppFooter";
import ProductHero from "./modules/views/ProductHero";
import ProductValues from "./modules/views/ProductValues";
import ProductHowItWorks from "./modules/views/ProductHowItWorks";
import ProductCTA from "./modules/views/ProductCTA";
import AppAppBar from "./modules/views/AppAppBar";
import { LanguageContext } from "../../contexts";
import {
  FooterSection,
  SearchBusinessModal,
  WelcomeToolbar,
} from "../../components";
import withRoot from "./modules/withRoot";

function Welcome() {

  // const { dictionary } = useContext(LanguageContext);
  return (
    <React.Fragment>
      <WelcomeToolbar />
      <ProductHero />
      <WhatIsAllura />
      <ProductValues />
      <ProductCategories />
      {/* <ProductHowItWorks /> */}
      <ProductCTA />
      <ProductSmokingHero />
      <FooterSection />
      {/* <AppFooter /> */}
      <SearchBusinessModal />
    </React.Fragment>
  );
}

export default Welcome;
