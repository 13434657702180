import React, { useContext } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ProductHeroLayout from "./ProductHeroLayout";
import { Images } from "../../../../themes";
import { Button, Grid, Typography } from "@material-ui/core";
import {
  ShopSearchBox,
  Typewriter,
  WelcomeToolbar,
} from "../../../../components";
import { LanguageContext } from "../../../../contexts";
import { useHistory } from "react-router-dom";

const backgroundImage = Images.bannerV5;

const image = Math.floor(Math.random() * 5) + 1;
const styles = (theme) => ({
  background: {
    backgroundImage: `url(${Images["bannerV" + 5]})`,
    backgroundColor: "#7fc7d9", // Average color of the background image.
    backgroundPosition: "center",
    zIndex: 0,
    filter: "brightness(70%)",
  },
  button: {
    minWidth: 200,
  },
  h5: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(10),
    },
  },
  logo: {
    zIndex: 3,
    width: 200,
    height: 200,
    marginBottom: 40,
    [theme.breakpoints.down(450)]: {
      width: 150,
      height: 150,
    },
  },
});

function ProductHero(props) {
  const { classes } = props;
  const { dictionary } = useContext(LanguageContext);
  const history = useHistory();

  return (
    <ProductHeroLayout backgroundClassName={classes.background}>
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: "none" }}
        src={backgroundImage}
        alt="increase priority"
      />
      {/* <Typography color="inherit" align="center" variant="h2" marked="center">
        Upgrade your Sundays
      </Typography> */}

      <img className={classes.logo} src={Images.logo} alt="logo" />
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        spacing={2}
        style={{ zIndex: 1 }}
      >
        {/* <Grid item sm={12} style={{ width: 500, maxWidth: "70vw", height: 30 }}>
          <Typewriter dictionary={dictionary} />
        </Grid> */}
        <Grid item sm={12}>
          <ShopSearchBox readonly={true} />
        </Grid>
        <Grid
          item
          sm={12}
          xs={12}
          style={{
            minWidth: 200,
            maxWidth: 350,
            width: "75vw",
            marginTop: 20,
          }}
        >
          <Button
            onClick={() => history.push(`/register`)}
            type="submit"
            variant="outlined"
            className="btn-primary"
          >
            {dictionary.createBusiness}
          </Button>
        </Grid>
      </Grid>
      {/* <Typography
        color="inherit"
        align="center"
        variant="h5"
        className={classes.h5}
      >
        Enjoy secret offers up to -70% off the best luxury hotels every Sunday.
      </Typography> */}
      {/* <Button
        color="secondary"
        variant="contained"
        size="large"
        className={classes.button}
        component="a"
        href="/premium-themes/onepirate/sign-up/"
      >
        Register
      </Button> */}
    </ProductHeroLayout>
  );
}

ProductHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHero);
