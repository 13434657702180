import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { ShopSearchBox, Typewriter, WelcomeToolbar } from "..";
import { Images } from "../../themes";
const useStyles = makeStyles((theme) => ({
  root: {},
  numbers: {
    fontFamily: "Roboto-Bold",
    fontSize: 50,
    color: "#ece8d8",
    [theme.breakpoints.down(1000)]: {
      fontSize: 40,
    },
  },
  stepTitle: {
    fontFamily: "NotoSansJP-Regular",
    fontSize: 23,
    color: "#1b1e3e",
    [theme.breakpoints.down(1000)]: {
      fontSize: 20,
    },
  },
  stepDescription: {
    fontFamily: "NotoSansJP-Regular",
    fontSize: 20,
    opacity: 0.5,
    color: "#7b848c",
    [theme.breakpoints.down(1000)]: {
      fontSize: 16,
    },
  },
  categoryTitle: {
    fontFamily: "NotoSansJP-Regular",
    fontSize: 50,
    color: "#1b1e3e",
    marginBottom: 10,
    marginLeft: "15%",
    [theme.breakpoints.down(1000)]: {
      marginLeft: 0,
      textAlign: "center",
      fontSize: 36,
    },
  },
}));
const HowItWorksSection = ({ dictionary }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
        style={{ marginTop: 120 }}
      >
        <Grid item sm={12} style={{ width: "100%" }}>
          <Typography className={classes.categoryTitle}>
            How it works
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        style={{ marginTop: 0, paddingRight: "15%", paddingLeft: "15%" }}
        spacing={10}
      >
        <Grid item xs={12} sm={4} style={{ backgroundColor: "transparent" }}>
          <Typography className={classes.numbers}>1</Typography>
          <Typography className={classes.stepTitle}>
            Search for a service
          </Typography>
          <Typography className={classes.stepDescription}>
            Enter your search criteria to find the best services for you.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography className={classes.numbers}>2</Typography>
          <Typography className={classes.stepTitle}>
            Explore your options
          </Typography>
          <Typography className={classes.stepDescription}>
            Does the business have things like nail treatments ? complete body
            massages ? hair coloring ? photography for events ? and much more!
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography className={classes.numbers}>3</Typography>
          <Typography className={classes.stepTitle}>
            Book your Appointment! 🙌
          </Typography>
          <Typography className={classes.stepDescription}>
            Enjoy the services provided to you.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default HowItWorksSection;
