import React, { useContext, useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  DashboardContext,
  LanguageContext,
  NotificationContext,
} from "../../../contexts";
import { Button, Grid, IconButton, Typography } from "@material-ui/core";
import {
  BusinessNameArTextfield,
  BusinessNameEnTextfield,
  BusinessServicesEdit,
  BusinessServicesSelect,
  BusinessTypeSelect,
} from "../..";
import { useForm } from "react-hook-form";
import EditIcon from "@material-ui/icons/Edit";
import BusinessPhoneTextfield from "../../textfields/BusinessPhoneTextfield";
import BusinessEmailTextfield from "../../textfields/BusinessEmailTextfield";
import CloseIcon from "@material-ui/icons/Close";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      textAlign: "center",
      fontSize: 25,
      margin: 20,
    },
    details: {
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.regular,
      textAlign: "center",
    },
    button: {
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      textAlign: "center",
      margin: 10,
    },
    root: {},
    paper: { paddingBottom: 20 },
    editButton: {
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      borderColor: "black",
      color: "black",
      borderWidth: 2,
      borderRadius: 5,
      marginTop: 10,
      height: 30,
      fontSize: 13,
      textTransform: "none",
    },
  })
);

export default function MaxWidthDialog({ business, dictionary }) {
  const classes = useStyles();
  const { onEditInfo } = useContext(DashboardContext);

  const [visible, setVisible] = useState(false);
  const form = useForm({
    defaultValues: {
      bid: business.bid,
      businessServices: business.businessServices,
    },
    reValidateMode: "onBlur",
    shouldUnregister: false,
  });
  const onSubmit = (data) => {
    let businessPrices = [] as any;
    data.businessServices.forEach((element) =>
      businessPrices.push({ name: element, priceFrom: 0 })
    );
    if (business.businessPrices.length > 2) {
      for (let i = 0; i < businessPrices.length; i++) {
        for (let z = 0; z < business.businessPrices.length; z++) {
          if (businessPrices[i].name === business.businessPrices[z].name) {
            businessPrices[i].priceFrom = business.businessPrices[z].priceFrom;
          }
        }
      }
    }
    // console.log("PRICES : " + JSON.stringify(prices));
    // console.log("SERVICES : " + JSON.stringify(data.businessServices));
    data = { ...data, businessPrices };
    const submit = { data, form };
    onEditInfo(submit);
    setVisible(false);
  };
  const { toggleContinue, messageDetails, messageTitle, isContinueAlert } =
    useContext(NotificationContext);

  return (
    <React.Fragment>
      <input type="hidden" {...form.register("bid")} />
      <Button
        onClick={() => setVisible(true)}
        className={classes.editButton}
        variant="outlined"
      >
        {dictionary.editServices}
      </Button>
      <Dialog
        classes={{
          root: classes.root,
          paper: classes.paper,
        }}
        fullWidth={true}
        maxWidth={"sm"}
        open={visible}
        onClose={() => {}}
        aria-labelledby="max-width-dialog-title"
      >
        <IconButton
          aria-label="edit"
          style={{ position: "absolute", top: 0, right: 0 }}
          onClick={() => setVisible(false)}
        >
          <CloseIcon style={{ fontSize: 40 }} />
        </IconButton>
        <DialogTitle id="max-width-dialog-title">
          <Typography className={classes.title}>
            {dictionary["services"]}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item style={{ width: "95%" }}>
              <BusinessServicesSelect
                form={form}
                // business={business}
                dictionary={dictionary}
              />
            </Grid>
            <Grid item style={{ width: "60%", minWidth: 250 }}>
              <Button
                onClick={form.handleSubmit(onSubmit)}
                variant="outlined"
                className="btn-primary"
              >
                {dictionary.confirm}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
