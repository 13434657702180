import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Images } from "../../themes";

import { HorizontalTabsDesktop, HorizontalTabsMobile } from "..";
import { Button, Grid, Typography } from "@material-ui/core";
import { AccountContext, NotificationContext } from "../../contexts";
import { useContext } from "react";
import EditAccount from "./account/EditAccount";
import classes from "*.module.css";
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ width: "100%", height: "100%", backgroundColor: "#F5F6F8" }}
    >
      {children}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: "100%",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  tabItem: {
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    opacity: 1,
    "&:hover": {
      backgroundColor: "#ececec",
    },
  },
  tabHome: {
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    paddingBottom: 15,
    marginBottom: "5vh",
    marginTop: 20,
    opacity: 1,
    "&:hover": {
      backgroundColor: "#ececec",
    },
  },
  indicator: {
    left: "0px",
    backgroundColor: "black",
    maxHeight: 40,
    width: 7,
    borderRadius: 50,
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 10,
  },
  ButtonInTabs: {
    marginLeft: theme.direction === "ltr" ? 20 : 0,
  },
}));

const ButtonInTabs = ({ className, onClick, title, image }) => {
  const classes = useStyles();

  return (
    <Button className={className} onClick={onClick}>
      <Grid
        direction="column"
        container
        className={classes.ButtonInTabs}
        justify="center"
        alignItems="center"
      >
        <Grid item>
          <img
            src={Images[image]}
            style={{ marginTop: 10, width: 40, height: 40 }}
          />
        </Grid>
        <Grid item>{title}</Grid>
      </Grid>
    </Button>
  );
};

export default function VerticalTabs({ dictionary, history }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const {
    onLogoutUser,
    onDeleteUser,
    businessOwner,
    onUpdateContact,
    onResetPass,
  } = useContext(AccountContext);
  const { toggleConfirm } = useContext(NotificationContext);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        classes={{ indicator: classes.indicator }}
        orientation="vertical"
        // variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs"
        className={classes.tabs}
      >
        <Tab
          icon={<img src={Images.logo} style={{ width: 70, height: 70 }} />}
          {...a11yProps(0)}
          className={classes.tabHome}
        />
        <Tab
          icon={
            <img
              src={Images.user}
              style={{ marginTop: 10, width: 40, height: 40 }}
            />
          }
          label={dictionary.account}
          {...a11yProps(1)}
          className={classes.tabItem}
        />
        <ButtonInTabs
          onClick={() => {
            toggleConfirm("logout", "logout?", onLogoutUser);
          }}
          className={classes.tabItem}
          image={"exit"}
          title={dictionary.logout}
        />
      </Tabs>

      <TabPanel value={value} index={0}>
        <HorizontalTabsDesktop dictionary={dictionary} history={history} />
        <HorizontalTabsMobile
          dictionary={dictionary}
          history={history}
          verticalTab={setValue}
          onLogoutUser={onLogoutUser}
          toggleConfirm={toggleConfirm}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        {businessOwner && <EditAccount />}
      </TabPanel>
      <TabPanel value={value} index={2}></TabPanel>
    </div>
  );
}
