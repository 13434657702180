import React, { useContext, useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import mapStyles from "./mapStyles";
import { Images } from "../../themes";
import { Autocomplete } from "@material-ui/lab";
import {
  Button,
  Container,
  createStyles,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
//import classes from "*.module.css";
//import { BusinessLocationField } from "..";
import {
  AccountContext,
  DashboardContext,
  NotificationContext,
} from "../../contexts";
const autocompleteService = { current: null };
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    appBar: {
      background: "transparent",
      boxShadow: "none",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    searchbar: {
      flexGrow: 1,
      textAlign: "center",
      color: "black",
      paddingTop: 10,
    },
    sectionDesktop: {
      position: "absolute",
      display: "none",
      [theme.breakpoints.up(800)]: {
        display: "flex",
      },
    },
    sectionMobile: {
      position: "fixed",
      bottom: 10,
      width: "100%",
      marginTop: 100,
      display: "flex",
      [theme.breakpoints.up(800)]: {
        display: "none",
      },
    },
    mainFooter: {
      position: "fixed",
      bottom: 10,
      width: "100%",
      marginTop: 100,
    },
    icon: {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(2),
    },
    locate: {
      position: "absolute",
      top: 5,
      right: 5,
      background: "none",
      border: "none",
      zIndex: 10,
    },
    searchResultTitle: {
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
    },
    noResults: {
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
    },
    locationButton: {
      borderRadius: 30,
      fontSize: 14,
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      width: "100%",
      height: 50,
      backgroundColor: theme.colors.info,
      color: theme.colors.secondary,
      textTransform: "none",
      transition: "transform .3s",
      transform: "scale(1)",

      "&:hover": {
        backgroundColor: theme.colors.info,
        color: theme.colors.secondary,
        transform: "scale(1.1)",
      },
    },
    // editButton: {
    //   fontFamily: theme.fonts.bold,
    //   borderColor: "black",
    //   color: "black",
    //   borderWidth: 2,
    //   borderRadius: 5,
    //   marginTop: 10,
    //   height: 30,
    //   fontSize: 13,
    //   textTransform: "none",
    // },
  })
);

const mapContainerStyle = {
  height: "100vh",
  width: "100vw",
};
const options = {
  styles: mapStyles as any,
  disableDefaultUI: true,
  zoomControl: true,
  // mapTypeId: "satellite",
};
let center = {
  lat: 24.76678430315914,
  lng: 46.71487750690889,
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BusinessLocationMap = ({ business, dictionary }) => {
  const classes = useStyles();
  const { onEditInfo } = useContext(DashboardContext);
  const { toggleToast } = useContext(NotificationContext);

  const [libraries] = useState(["places"]) as any;
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
    libraries,
  });

  const [marker, setMarker] = React.useState({
    // lat: 24.76678430315914,
    // lng: 46.71487750690889,
    lat: "",
    lng: "",
    time: new Date(),
  }) as any;
  // const [markers, setMarkers] = React.useState([]) as any; // to do multiple markers at same time
  const [selected, setSelected] = React.useState(null) as any;
  const [modalVisible, setModalVisible] = React.useState(false);

  const onMapClick = React.useCallback((e) => {
    // to do multiple markers at same time
    // setMarkers((current) => [
    //   ...current,
    //   {
    //     lat: e.latLng.lat(),
    //     lng: e.latLng.lng(),
    //     time: new Date(),
    //   },
    // ]);

    setMarker({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
      time: new Date(),
    });
  }, []);

  const mapRef = React.useRef<any>();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const panTo = React.useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(12);
  }, []);

  useEffect(() => {
    if (business.businessLocation.lat !== 0 && modalVisible) {
      const lat = business.businessLocation.lat;
      const lng = business.businessLocation.lng;
      setTimeout(() => {
        setMarker({
          lat: lat,
          lng: lng,
        });
        mapRef.current.panTo({ lat, lng });
        mapRef.current.setZoom(14);
      }, 500);
    }
  }, [modalVisible]);

  if (loadError) return <div>Error</div>;
  if (!isLoaded)
    return (
      <Button
        onClick={() => setModalVisible(true)}
        type="submit"
        variant="outlined"
        className={classes.locationButton}
      >
        See Location
      </Button>
    );

  // https://www.youtube.com/watch?v=WZcxJGmLbSo&t=0s  <-- follow this to learn maps again

  // ASK FOR USER CURRENT LOCATION
  // navigator.geolocation.getCurrentPosition(
  //   (position) => {
  //     panTo({
  //       lat: position.coords.latitude,
  //       lng: position.coords.longitude,
  //     });
  //   },
  //   () => null
  // );

  return (
    <div>
      <Button
        onClick={() => setModalVisible(true)}
        type="submit"
        variant="outlined"
        className={classes.locationButton}
      >
        {dictionary.ourLocation}
      </Button>
      <Dialog
        fullScreen
        open={modalVisible}
        // onClose={handleClose}
        TransitionComponent={Transition}
      >
        {/* <Search
          panTo={panTo}
          setModalVisible={setModalVisible}
          dictionary={dictionary}
        /> */}
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={10}
          center={center}
          options={options}
          // onClick={onMapClick}
          onLoad={onMapLoad}
        >
          {marker.lat && (
            <Marker
              animation={0}
              key={`${marker.lat}-${marker.lng}`}
              position={{ lat: marker.lat, lng: marker.lng }}
              // onClick={() => {
              //   window.location.href = "http://www.w3schools.com";
              // }}
              // to use custom marker icon
              icon={{
                url: Images.pin,
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(15, 15),
                scaledSize: new window.google.maps.Size(40, 40),
              }}
            />
          )}

          {/* if you want to map out multiple markers
          {markers.map((marker) => (
            <Marker
              key={marker.time.toISOString()}
              position={{ lat: marker.lat, lng: marker.lng }}
            />
          ))} */}
        </GoogleMap>

        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          style={{ position: "absolute", bottom: 1 }}
        >
          <Grid item xs={12} style={{ maxWidth: 600, width: "90%" }}>
            <AppBar
              position="static"
              style={{
                textAlign: "center",
                height: 120,
                backgroundColor: "white",
                boxShadow: "0 0 30px rgba(33,33,33,.2)",
                borderTopRightRadius: 15,
                borderTopLeftRadius: 15,
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              <Container maxWidth="md">
                <Button
                  onClick={() => {
                    window.location.href = `https://www.google.com/maps/dir/?api=1&destination=${marker.lat},${marker.lng}`;
                    setModalVisible(false);
                  }}
                  className="btn-primary"
                  variant="outlined"
                >
                  {dictionary.getDirections}
                </Button>
                <Button
                  className="btn-clear"
                  variant="contained"
                  onClick={() => setModalVisible(false)}
                >
                  {dictionary.close}
                </Button>
              </Container>
            </AppBar>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
};

const CustomTextField = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    maxWidth: 500,
    boxShadow: "0 0 20px rgba(33,33,33,.2)",
    borderRadius: 30,
    "& input": {
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
    },
    "& label": {
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
    },
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black",
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderWidth: 0,
        borderRadius: 10,
      },
      "&.Mui-focused fieldset": {
        borderColor: "black",
        borderWidth: 0,
        borderRadius: 10,
      },
      "&:hover fieldset": {
        borderColor: "black",
        borderWidth: 0,
        borderRadius: 10,
      },
    },
  },
}))(TextField);
interface PlaceType {
  description: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings: [
      {
        offset: number;
        length: number;
      }
    ];
  };
}
function Search({ panTo, setModalVisible, dictionary }) {
  const classes = useStyles();
  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState<PlaceType[]>([]);

  const fetch = React.useMemo(
    () =>
      throttle(
        (
          request: { input: string },
          callback: (results?: PlaceType[]) => void
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            request,
            callback
          );
        },
        200
      ),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: PlaceType[]) => {
      if (active) {
        let newOptions = [] as PlaceType[];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const handleSelect = async (address) => {
    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      panTo({ lat, lng });
    } catch (error) {
      console.log("😱 Error: ", error);
    }
  };

  return (
    <div className="root">
      <AppBar className={classes.appBar}>
        <Toolbar>
          <div className={classes.sectionDesktop}>
            <IconButton
              edge="start"
              onClick={() => setModalVisible(false)}
              aria-label="close"
            >
              <CloseIcon style={{ color: "#1b1e3e", fontSize: 40 }} />
            </IconButton>
          </div>
          <div className={classes.searchbar}>
            <Autocomplete
              id="google-map-demo"
              classes={{
                noOptions: classes.noResults,
              }}
              noOptionsText={dictionary.noResults}
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.description
              }
              filterOptions={(x) => x}
              options={options}
              autoComplete
              includeInputInList
              filterSelectedOptions
              value={value}
              onChange={(event: any, newValue: PlaceType | null) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
                handleSelect(newValue?.description);
              }}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  placeholder={dictionary.searchPlaces}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    // style: {
                    //   fontFamily: "Roboto-bold",
                    //   fontSize: 15,
                    // },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ marginLeft: 10 }} />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              renderOption={(option) => {
                const matches =
                  option.structured_formatting.main_text_matched_substrings;
                const parts = parse(
                  option.structured_formatting.main_text,
                  matches.map((match: any) => [
                    match.offset,
                    match.offset + match.length,
                  ])
                );

                return (
                  <Grid container alignItems="center">
                    <Grid item>
                      <LocationOnIcon className={classes.icon} />
                    </Grid>
                    <Grid item xs>
                      {parts.map((part, index) => (
                        // fontWeight: part.highlight ? 700 : 400 ,
                        <span key={index} className={classes.searchResultTitle}>
                          {part.text}
                        </span>
                      ))}
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        className={classes.searchResultTitle}
                      >
                        {option.structured_formatting.secondary_text}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              }}
            />
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default BusinessLocationMap;
