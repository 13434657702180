import { app, db } from "../config";

export const deleteBusiness = async (
  submit,
  { dispatch, history, toggleContinue, toggleActivity, toggleToast }
) => {
  dispatch({ type: "REQUEST" });
  toggleActivity(true, "deleting");
  const p2 = db
    .collection("businesses")
    .doc(submit.bid)
    .delete()
    .then(function (querySnapshot) {
      db.collection("links")
        .where("bid", "==", submit.bid)
        .limit(5)
        .get()
        .then(function (querySnapshot) {
          // Once we get the results, begin a batch
          var batch = db.batch();

          querySnapshot.forEach(function (doc) {
            // For each doc, add a delete operation to the batch
            batch.delete(doc.ref);
          });
          batch.commit().then(() => {
            toggleActivity(false);
            toggleToast("deleted", "success");
            history.go(0);
          });
        });
    })
    .catch(function (error) {
      toggleActivity(false);
      dispatch({ type: "REQUEST_FAIL" });
      console.log(error.code);
      toggleContinue("requestFail", error.code, true);
    });
};
