import { app, db } from "../config";

export const getBusinessOwnerInfo = async ({
  dispatch,
  toggleContinue,
  onChangeLanguage,
}) => {
  db.collection("business_owners")
    .doc(app.auth().currentUser!.uid)
    .get()
    .then((doc) => {
      if (doc.exists) {
        dispatch({
          type: "REQUEST_SUCCESS",
          payload: doc.data(),
        });
        onChangeLanguage(doc.data()!.language);
        //  console.log(JSON.stringify(doc.data()));
      }
    })
    .catch(function (error) {
      dispatch({ type: "REQUEST_FAIL" });
      toggleContinue("requestFail", error.code, true);
    });
};
