import React, { useContext } from "react";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import {
  Button,
  Card,
  Grid,
  makeStyles,
  Typography,
  createStyles,
  Theme,
  CircularProgress,
  Container,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import {
  AccountContext,
  CustomerContext,
  DashboardContext,
  LanguageContext,
} from "../../../contexts";
import {
  AlluraLinkTextfield,
  BusinessLocationMap,
  BusinessNameArTextfield,
  BusinessNameEnTextfield,
  BusinessPhoneTextfield,
  BusinessServicesSelect,
  BusinessTypeSelect,
  BusinessRegionSelect,
} from "../../";
import { ResultsListItem, ShopSearchBox } from "../..";
import InfiniteScroll from "react-infinite-scroll-component";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { Images } from "../../../themes";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      //marginBottom: 150,
    },
    card: {
      minWidth: 275,
      maxWidth: 600,
      width: "90vw",
      marginTop: 50,
      marginBottom: 50,
      // maxHeight: 200,
      borderWidth: 0,
      boxShadow: "0 0 50px rgba(33,33,33,.2);",
      borderRadius: 30,
      padding: 20,
      paddingTop: 40,
      paddingBottom: 40,
      transition: "box-shadow .3s",
      [theme.breakpoints.down(450)]: {},
    },
    appBar: {
      background: "transparent",
      boxShadow: "none",
    },
    searchbar: {
      flexGrow: 1,
      textAlign: "center",
      color: "black",
      paddingTop: 10,
    },
    sectionDesktop: {
      position: "absolute",
      display: "none",
      [theme.breakpoints.up(800)]: {
        display: "flex",
      },
    },
    mainFooter: {
      position: "fixed",
      bottom: 10,
      width: "100%",
      marginTop: 100,
    },
    text: {
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      textAlign: "center",
    },
    bottomCloseButton: {
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      color: "#1b1e3e",
      borderWidth: 0,
      fontSize: 25,
    },
    icon: {
      width: 80,
      height: 80,
      textAlign: "center",
      color: theme.isDarkMode ? "white" : "black",
    },
    resultMessage: {
      fontSize: 19,
      opacity: 0.7,
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      color: theme.isDarkMode ? "white" : "black",
    },
    title: {
      color: "black",
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      fontSize: 30,
      marginBottom: 5,
      textAlign: "center",
    },
    description: {
      color: "black",
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.regular,
      fontSize: 14,
      marginBottom: 5,
      paddingLeft: 20,
      paddingRight: 20,
      textAlign: "center",
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddBusinessModal() {
  const classes = useStyles();
  const {
    isShopSearch,
    onToggleShopSearch,
    businesses,
    onGetMoreBusinesses,
    isMoreItems,
    search,
  } = useContext(CustomerContext);
  const { dictionary } = useContext(LanguageContext);
  const { businessLocation } = useContext(AccountContext);

  const { onToggleAddBusinessModal, isAddBusinessModal, onAddBusiness } =
    useContext(DashboardContext);

  const history = useHistory();

  const handleClose = () => {
    onToggleAddBusinessModal();
  };
  const form = useForm({
    defaultValues: {
      businessRegion: [],
      businessServices: [],
      businessType: [],
    },
    reValidateMode: "onBlur",
    // shouldUnregister: false,
  });
  const onSubmit = (data) => {
    let prices = [] as any;
    data.businessServices.forEach((element) =>
      prices.push({ name: element, priceFrom: 0 })
    );
    prices = { businessPrices: prices };
    data.businessLocation = businessLocation;
    data = { ...data, ...prices };
    //console.log(JSON.stringify(data));
    const submit = { data, form };
    return onAddBusiness(submit);
  };

  // const listRender = (search, businesses, isMoreItems, dictionary, history) => {
  //   if (isMoreItems && businesses.length == 0) {
  //     return (
  //       <Grid
  //         container
  //         direction="column"
  //         alignItems="center"
  //         justify="center"
  //         //style={{ minHeight: "100vh" }}
  //         spacing={2}
  //       >
  //         <Grid item sm={12}>
  //           <CircularProgress style={{ color: "black" }} />
  //         </Grid>
  //       </Grid>
  //     );
  //   } else if (businesses.length === 0 && !isMoreItems) {
  //     return (
  //       <Grid
  //         container
  //         direction="column"
  //         alignItems="center"
  //         justify="center"
  //         spacing={1}
  //       >
  //         <Grid item sm={12}>
  //           <img
  //             src={!search ? Images.shop : Images.notFound}
  //             className={classes.icon}
  //           />
  //         </Grid>
  //         <Grid item sm={12}>
  //           <Typography className={classes.resultMessage}>
  //             {!search ? dictionary.typeBusinessName : dictionary.noResults}
  //           </Typography>
  //         </Grid>
  //       </Grid>
  //     );
  //   }

  //   return businesses.map((business, i) => (
  //     <ResultsListItem
  //       key={i}
  //       business={business}
  //       dictionary={dictionary}
  //       history={history}
  //     />
  //   ));
  // };

  return (
    <div>
      <Dialog
        fullScreen
        open={isAddBusinessModal}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <div className={classes.root}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <div className={classes.sectionDesktop}>
                <IconButton
                  edge="start"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon style={{ color: "#1b1e3e", fontSize: 40 }} />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
        </div>
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="center"
          // style={{ minHeight: "100vh" }}
        >
          <Grid item>
            <Card
              className={`animate__animated faster animate__fadeIn ${classes.card}`}
              variant="outlined"
            >
              <form onSubmit={form.handleSubmit(onSubmit)}>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                  style={{ minHeight: "100%" }}
                  spacing={3}
                >
                  <Grid item style={{ width: "90%" }}>
                    <Typography className={classes.title}>
                      {dictionary.businessDetails}
                    </Typography>
                    <Typography className={classes.description}>
                      {dictionary.businessDetailsDescription}
                    </Typography>
                  </Grid>
                  <Grid item style={{ width: "90%", minWidth: 250 }}>
                    <AlluraLinkTextfield form={form} dictionary={dictionary} />
                  </Grid>
                  <Grid item style={{ width: "90%", minWidth: 250 }}>
                    <BusinessNameEnTextfield
                      form={form}
                      dictionary={dictionary}
                    />
                  </Grid>
                  <Grid item style={{ width: "90%", minWidth: 250 }}>
                    <BusinessNameArTextfield
                      form={form}
                      dictionary={dictionary}
                    />
                  </Grid>
                  <Grid item style={{ width: "90%", minWidth: 250 }}>
                    <BusinessPhoneTextfield
                      form={form}
                      dictionary={dictionary}
                    />
                  </Grid>
                  <Grid item style={{ width: "90%", minWidth: 250 }}>
                    <BusinessTypeSelect form={form} dictionary={dictionary} />
                  </Grid>
                  <Grid item style={{ width: "90%", minWidth: 250 }}>
                    <BusinessServicesSelect
                      form={form}
                      dictionary={dictionary}
                    />
                  </Grid>
                  <Grid item style={{ width: "90%", minWidth: 250 }}>
                    <BusinessRegionSelect form={form} dictionary={dictionary} />
                  </Grid>
                  <Grid item style={{ width: "90%", minWidth: 250 }}>
                    <BusinessLocationMap form={form} dictionary={dictionary} />
                  </Grid>
                  <Grid item style={{ width: "60%", minWidth: 250 }}>
                    <Button
                      type="submit"
                      variant="outlined"
                      className="btn-primary"
                    >
                      {dictionary.addBusiness}
                    </Button>
                  </Grid>
                  <Grid item style={{ width: "60%", minWidth: 250 }}>
                    <Button
                      onClick={handleClose}
                      className="btn-clear"
                      variant="contained"
                    >
                      {dictionary.back}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Card>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
