import { Tune } from "@material-ui/icons";
import { Console } from "console";
import { db, app } from "..";

export const addCustomerBooking = (
  submit,
  { state, dispatch, toggleToast, toggleContinue, toggleActivity, history }
) => {
  dispatch({ type: "REQUEST" });
  //return console.log(JSON.stringify(state.business));
  toggleActivity(true, "addingBooking");
  let bookingId = Math.round(
    Math.pow(36, 5 + 1) - Math.random() * Math.pow(36, 5)
  )
    .toString(36)
    .slice(1);
  let date = Date.now().toString();
  let body = {
    createdAt: date,
    bookingTime: submit.data.bookingTime,
    bookingDate: submit.data.bookingDate,
    bookingStatus: true,
    bookingId: bookingId,
    bid: state.business.bid,
    businessNameAr: state.business.businessNameAr,
    businessNameEn: state.business.businessNameEn,
    customerName: submit.data.customerName,
    customerPhone: submit.data.customerPhone,
  };

  db.collection("bookings")
    .add(body)
    .then(() => {
      submit.form.reset();
      toggleActivity(false);
      dispatch({ type: "BOOKING_SUBMITTED", payload: body });
    })
    .catch((error) => {
      console.log("6 part error : " + JSON.stringify(error));
      toggleActivity(false);
      dispatch({ type: "REQUEST_FAIL" });
      toggleContinue("requestFail", error.code, true);
    });
};
