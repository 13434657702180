import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import Typewriter from "typewriter-effect/dist/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    text: {
      // flip: false,
      // textAlign: "right",
      //   height: 30,
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      color: "#FDD835",
      fontSize: 28,
      [theme.breakpoints.down(600)]: {
        fontSize: 17,
      },
    },
  })
);

const Linkwriter = ({ dictionary }) => {
  const classes = useStyles();
  let app = document.getElementById("linkwriter");
  var typewriter = new Typewriter(app, {
    loop: true,
    delay: 75,
  });
  typewriter
    .typeString("ahmedphotos")
    .pauseFor(2000)
    .deleteChars(11)
    .typeString("amalsalon")
    .pauseFor(2000)
    .deleteChars(9)
    .typeString("goldenspa")
    .pauseFor(2000)
    .start();

  return <div id="linkwriter" className={classes.text} dir="ltr"></div>;
};

export default Linkwriter;
