// @ts-nocheck
import React, { useContext, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  AccountContextProvider,
  CustomerContextProvider,
  LanguageContext,
  ThemeContext,
  DashboardContextProvider,
} from "./contexts";
import { GlobalStyles } from "./themes";
import {
  Welcome,
  NotFound,
  BusinessRegister,
  BusinessLogin,
  BusinessDashboard,
  BusinessRecover,
  BusinessFront,
  CustomerBooking,
} from "./pages";
import { StylesProvider, jssPreset, ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  ActivityIndicator,
  ConfirmAlert,
  ContinueAlert,
  PrivateRoute,
  SearchBusinessModal,
  Toast,
} from "./components";
import { create } from "jss";
import rtl from "jss-rtl";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

function App() {
  const { font, direction } = useContext(LanguageContext);
  const { color, isDarkMode } = useContext(ThemeContext);
  return (
    <Router>
      <StylesProvider jss={jss}>
        <ThemeProvider
          theme={GlobalStyles({ direction, font, color, isDarkMode })}
        >
          <CssBaseline />
          <div className="bg" />

          <AccountContextProvider>
            <CustomerContextProvider>
              <DashboardContextProvider>
                <Switch>
                  <Route path="/" exact component={Welcome} />
                  <Route path="/welcome" exact component={Welcome} />
                  <Route path="/register" exact component={BusinessRegister} />
                  <Route path="/recover" exact component={BusinessRecover} />
                  <Route
                    path="/booking/:id"
                    exact
                    component={CustomerBooking}
                  />
                  <PrivateRoute
                    mustBeAuthenticated={false}
                    alternativeRoute={"/dashboard"}
                    path="/login"
                    exact
                    component={BusinessLogin}
                  />
                  <PrivateRoute
                    mustBeAuthenticated={true}
                    alternativeRoute={"/login"}
                    path="/dashboard"
                    exact
                    component={BusinessDashboard}
                  />
                  <Route path="/404" exact component={NotFound} />
                  <Route path="/:id" exact component={BusinessFront} />
                  <Route path="/:id/lang" exact component={BusinessFront} />
                  <Route path="*" component={NotFound} />
                </Switch>
              </DashboardContextProvider>
            </CustomerContextProvider>
          </AccountContextProvider>
          <ConfirmAlert />
          <ContinueAlert />
          <Toast />
          <ActivityIndicator />
        </ThemeProvider>
      </StylesProvider>
    </Router>
  );
}

export default App;
