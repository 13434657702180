import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, withStyles } from "@material-ui/core";
import { Controller } from "react-hook-form";

const CssTextField = withStyles((theme) => ({
  root: theme.outlineTextfield,
}))(TextField);

const BusinessNameTextfield = ({ form, dictionary }) => {
  return (
    <div>
      <Controller
        name="businessNameAr"
        control={form.control}
        rules={{
          required: true,
          pattern: /^[\u0621-\u064A0-9 ]{3,25}$/,
        }}
        render={({ field }) => (
          <CssTextField
            {...field}
            inputProps={{
              style: {
                fontFamily: "Almarai",
                fontWeight: 400,
              },
            }}
            fullWidth={true}
            autoComplete="off"
            label={dictionary["businessNameAr"]}
            variant="outlined"
          />
        )}
      />
      {form.formState.errors.businessNameAr && (
        <Typography
          className={`form-error-text animate__animated animate__headShake`}
        >
          {dictionary.invalidBusinessNameAr}
        </Typography>
      )}
    </div>
  );
};

export default BusinessNameTextfield;
