import React, { useContext } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { LanguageContext, ThemeContext } from "../../contexts";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  label: {
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    color: theme.isDarkMode ? "white" : "black",
  },
}));

export default function ThemeSwitch() {
  const classes = useStyles();
  const { toggleTheme, isDarkMode } = useContext(ThemeContext);
  const { dictionary } = useContext(LanguageContext);

  const handleChange = (event: any) => {
    toggleTheme(event.target.checked);
  };

  return (
    <FormControlLabel
      control={
        <Switch checked={isDarkMode} onChange={handleChange} color="primary" />
      }
      label={
        <Typography className={classes.label}>
          {isDarkMode ? dictionary.darkMode : dictionary.lightMode}
        </Typography>
      }
    />
  );
}
