import React, { useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField, withStyles } from "@material-ui/core";
import { Controller } from "react-hook-form";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { DashboardContext } from "../../contexts";

const CssTextField = withStyles((theme) => ({
  root: theme.outlineTextfield,
}))(TextField);

const useStyles = makeStyles((theme) => ({
  shareButton: {
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    //backgroundColor: "transparent",
    borderColor: "black",
    color: "black",
    borderWidth: 2,
    borderRadius: 5,

    opacity: 0.7,
    height: 30,
    fontSize: 13,
    textTransform: "none",
  },
}));

const WhatsappTextfield = ({ form, dictionary }) => {
  //const [value, setValue] = useState("");
  const classes = useStyles();
  const { onCopyToClipboard } = useContext(DashboardContext);
  const linkCopy = (data) => {
    onCopyToClipboard(data.whatsapp);
  };
  return (
    <div>
      <Controller
        name="whatsapp"
        control={form.control}
        rules={{
          required: false,
          pattern: /^([0-9_-\s]){10,15}$/,
        }}
        render={({ field }) => (
          <CssTextField
            {...field}
            autoComplete="off"
            fullWidth={true}
            label={dictionary["whatsapp"]}
            variant="outlined"
            placeholder={dictionary["whatsappPlaceholder"]}
            InputProps={{
              startAdornment: <WhatsAppIcon />,
              // endAdornment: (
              //   <Button
              //     onClick={form.handleSubmit(linkCopy)}
              //     className={classes.shareButton}
              //     variant="outlined"
              //   >
              //     {dictionary.copy}
              //   </Button>
              // ),
            }}
          />
        )}
      />
      {form.formState.errors.whatsapp && (
        <Typography
          className={`form-error-text animate__animated animate__headShake`}
        >
          {dictionary.invalidPhone}
        </Typography>
      )}
    </div>
  );
};

export default WhatsappTextfield;
