import React, { useContext } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Dialog, Grid, Slide, Typography } from "@material-ui/core";
import { LanguageContext, NotificationContext } from "../contexts";
import { TransitionProps } from "@material-ui/core/transitions";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "white",
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
  },
}));
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ActivityIndicator() {
  const classes = useStyles();
  const { dictionary } = useContext(LanguageContext);
  const { activityTitle, isActivity } = useContext(NotificationContext);

  // const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    // setOpen(false);
  };

  return (
    <Dialog
      fullScreen
      open={isActivity}
      onClose={handleClose}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item>
          <CircularProgress style={{ color: "white" }} />
        </Grid>
        <Grid item>
          <Typography className={`${classes.title}`}>
            {dictionary[activityTitle]}
          </Typography>
        </Grid>
      </Grid>
    </Dialog>
  );
}
