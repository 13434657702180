import React, { createContext, useReducer } from "react";
import { lightTheme, darkTheme } from "../themes";

const initialState = {
    isDarkMode: false,
    color: lightTheme,
    toggleTheme: (data: boolean) => {}
};

export const ThemeContext = createContext(initialState);

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "TOGGLE_THEME":
      return {
        ...state,
        isDarkMode: action.payload,
        color: action.payload ? darkTheme : lightTheme,
      };
    default:
      break;
  }
  return state;
};

export const ThemeContextProvider = (props: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const toggleTheme = (data: boolean) => {
    dispatch({ type: "TOGGLE_THEME", payload: data });
  };
 
  return (
    <ThemeContext.Provider value={{...state, toggleTheme}}>
      {props.children}
    </ThemeContext.Provider>
  );
};
