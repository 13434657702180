import React, { useContext } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { Typography } from "@material-ui/core";
import { Images } from "../../../../themes";
import { dictionaryList } from "../../../../services/languages";
import { LanguageContext } from "../../../../contexts";

const styles = (theme) => ({
  root: {
    display: "flex",
    overflow: "hidden",
    backgroundColor: "white",
  },
  container: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(10),
    display: "flex",
    position: "relative",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 55,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
  },
  curvyLines: {
    pointerEvents: "none",
    position: "absolute",
    top: -180,
  },
  description: {
    opacity: 0.7,
    fontSize: 18,
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.regular,
  },
});

function ProductValues(props) {
  const { classes } = props;
  const { dictionary } = useContext(LanguageContext);

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        {/* <img
          src="/static/themes/onepirate/productCurvyLines.png"
          className={classes.curvyLines}
          alt="curvy lines"
        /> */}
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img className={classes.image} src={Images.social} alt="luxury" />
              <Typography variant="h6" className={classes.title}>
                {dictionary.communicationValueTitle}
              </Typography>
              <Typography className={classes.description}>
                {dictionary.communicationValueDescription}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src={Images.location}
                alt="clock"
              />
              <Typography variant="h6" className={classes.title}>
                {dictionary.locationValueTitle}
              </Typography>
              <Typography className={classes.description}>
                {dictionary.locationValueDescription}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <img
                className={classes.image}
                src={Images.appointment}
                alt="graph"
              />
              <Typography variant="h6" className={classes.title}>
                {dictionary.calendarValueTitle}
              </Typography>
              <Typography className={classes.description}>
                {dictionary.calendarValueDescription}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

ProductValues.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles as any)(ProductValues);
