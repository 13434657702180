import React, { useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { fade, InputAdornment, InputBase, withStyles } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { LanguageContext, CustomerContext } from "../../contexts";
import InsertLinkIcon from "@material-ui/icons/InsertLink";
const BootstrapInput = withStyles((theme) => ({
  root: {
    fontSize: 25,
    borderRadius: 20,
    backgroundColor: theme.palette.common.white,
    "label + &": {
      backgroundColor: theme.palette.common.white,
      marginTop: theme.spacing(3),
    },
    border: "2px solid #ced4da",
    paddingLeft: 10,
    width: "80vw",
    maxWidth: "500px",
    // boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    // borderColor: theme.palette.primary.main,
    boxShadow: "0 0 20px rgba(33,33,33,.2);",
  },
  input: {
    // "&:focus": {
    //   boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    //   borderColor: theme.palette.primary.main,
    // },
    borderRadius: 30,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    height: 50,
    fontSize: 25,
    fontFamily: theme.fonts.bold,

    padding: "10px 12px",
  },
}))(InputBase);
const SearchBox = ({ readonly }) => {
  const { onToggleShopSearch } = useContext(CustomerContext);
  const { dictionary } = useContext(LanguageContext);

  return (
    <div
      onClick={() => {
        if (readonly) onToggleShopSearch();
      }}
    >
      <Typography
        style={{
          fontFamily: "Roboto-Bold",
          fontSize: 30,
          color: "white",
          textAlign: "center",
          marginBottom: 20,
        }}
      >
        Create your business
      </Typography>
      <BootstrapInput
        autoFocus={!readonly}
        readOnly={readonly}
        placeholder={"yourname"}
        startAdornment={
          <span
            style={{
              fontFamily: "Roboto-Bold",
            }}
          >
            allura.app/
          </span>
        }
      />
    </div>
  );
};

export default SearchBox;
