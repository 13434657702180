import {
  Button,
  Card,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
  EmailTextfield,
  PasswordTextfield,
  TransitionButton,
} from "../../components";
import { AccountContext, LanguageContext } from "../../contexts";
import { Images } from "../../themes";
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    maxWidth: 600,
    width: "90vw",
    // maxHeight: 200,
    borderWidth: 0,
    boxShadow: "0 0 50px rgba(33,33,33,.2);",
    borderRadius: 30,
    padding: 20,
    paddingTop: 40,
    paddingBottom: 40,
    transition: "box-shadow .3s",
    [theme.breakpoints.down(450)]: {},
  },
  clearButton: {
    textAlign: "center",
    paddingTop: 20,
    paddingRight: "10%",
    paddingLeft: "10%",
  },
  title: {
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    fontSize: 30,
    //opacity: 0.8,
    textAlign: "center",
  },
  imageContainer: {
    boxShadow: "0 0 50px rgba(33,33,33,.2);",
    width: 150,
    height: 150,
    borderRadius: "50%",
    marginBottom: 40,
    cursor: "pointer",
  },
}));
const BusinessLogin = () => {
  const classes = useStyles();
  const { dictionary } = useContext(LanguageContext);
  const { onLoginUser } = useContext(AccountContext);
  const history = useHistory();

  const form = useForm({
    shouldUnregister: false, // unmount input state will be remained
  });
  const onSubmit = (data) => {
    const submit = { data, form };
    onLoginUser(submit);
  };
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: "100vh", paddingTop: 10, paddingBottom: 100 }}
    >
      <Grid item>
        <Card
          className={`animate__animated faster animate__fadeIn ${classes.imageContainer}`}
          variant="outlined"
        >
          <img
            onClick={() => history.push(`/`)}
            src={Images.logo}
            style={{
              width: 150,
              height: 150,
            }}
          />
        </Card>
      </Grid>
      <Grid item>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Card
            className={`animate__animated faster animate__fadeIn ${classes.root}`}
            variant="outlined"
          >
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              style={{ minHeight: "100%" }}
              spacing={3}
            >
              <Grid item style={{ width: "90%" }}>
                <Typography className={classes.title}>
                  {dictionary.businessLogin}
                </Typography>
              </Grid>
              <Grid item style={{ width: "90%" }}>
                <EmailTextfield form={form} dictionary={dictionary} />
              </Grid>
              <Grid item style={{ width: "90%" }}>
                <PasswordTextfield form={form} dictionary={dictionary} />
              </Grid>
              <Grid item style={{ width: "60%", minWidth: 250 }}>
                <Button
                  type="submit"
                  variant="outlined"
                  className="btn-primary"
                >
                  {dictionary.login}
                </Button>
              </Grid>
              <Grid
                container
                justify="center"
                className={classes.clearButton}
                spacing={3}
              >
                <Grid xs item className={classes.clearButton}>
                  <TransitionButton
                    title={dictionary.register}
                    transition={"register"}
                  />
                </Grid>
                <Grid xs item className={classes.clearButton}>
                  <TransitionButton
                    title={dictionary.forgotPassword}
                    transition={"recover"}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </form>
      </Grid>
    </Grid>
  );
};

export default BusinessLogin;
