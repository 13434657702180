import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  createStyles,
  Grid,
  IconButton,
  List,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { DashboardContext, NotificationContext } from "../../../contexts";
import { useForm } from "react-hook-form";
import QRCode from "qrcode.react";

import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import {
  BusinessNameArTextfield,
  BusinessNameEnTextfield,
  BusinessTypeSelect,
  BusinessPhoneTextfield,
  BusinessEmailTextfield,
  AlluraLinkTextfield,
  BusinessRegionSelect,
} from "../..";
import DeleteIcon from "@material-ui/icons/Delete";
import { Images } from "../../../themes";
import jsPDF from "jspdf";

const useStyles = makeStyles((theme) =>
  createStyles({
    qrTitle: {
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
    },
    qrIcon: {
      width: 50,
      height: 50,
      [theme.breakpoints.down(850)]: {
        width: 40,
        height: 40,
      },
    },
    qrButton: {
      width: 50,
      height: 50,
      marginRight: 80,
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "transparent",
      },
      [theme.breakpoints.down(850)]: {
        width: 40,
        height: 40,
      },
    },
  })
);

export default function QRGenerator({ link, dictionary }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { toggleActivity } = useContext(NotificationContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const GeneratePDF = (lang) => {
    setAnchorEl(null);
    var doc = new jsPDF();
    var msg = document.getElementById("qr") as HTMLCanvasElement;
    var width = doc.internal.pageSize.getWidth();
    var height = doc.internal.pageSize.getHeight();
    const marginX = (width - 80) / 2;
    const marginY = (height - 95) / 2;
    setTimeout(function () {
      doc.addImage(Images["qr_" + lang], "JPEG", 0, 0, width, height);
      doc.addImage(msg!.toDataURL(), "png", marginX, marginY, 80, 80);
      doc.save("qr_poster_" + lang);
    }, 500);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <IconButton
        aria-label="edit"
        className={classes.qrButton}
        onClick={handleClick}
      >
        <img src={Images.qr3} className={classes.qrIcon} />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem className={classes.qrTitle} onClick={() => GeneratePDF("en")}>
          {dictionary.englishQR}
        </MenuItem>
        <MenuItem className={classes.qrTitle} onClick={() => GeneratePDF("ar")}>
          {dictionary.arabicQR}
        </MenuItem>
      </Menu>
      <QRCode id="qr" value={link} style={{ display: "none" }} />
    </div>
  );
}
