import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  createStyles,
  Grid,
  IconButton,
  List,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { DashboardContext } from "../../../contexts";
import { useForm } from "react-hook-form";
import { PriceTextfield } from "../..";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      fontFamily: theme.fonts.bold,
      textAlign: "center",
      fontSize: 25,
      margin: 10,
    },
    details: {
      fontFamily: theme.fonts.base,
      textAlign: "center",
    },
    button: {
      fontFamily: theme.fonts.bold,
      textAlign: "center",
      margin: 10,
    },
    paper: { borderRadius: 25, paddingBottom: 20 },
    editButton: {
      fontFamily: theme.fonts.bold,
      borderColor: "black",
      color: "black",
      borderWidth: 2,
      borderRadius: 5,
      marginTop: 10,
      height: 30,
      fontSize: 13,
      textTransform: "none",
    },
  })
);

export default function ScrollDialog({ business, dictionary }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { onEditInfo } = useContext(DashboardContext);
  const handleClickOpen = () => () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const form = useForm({
    defaultValues: {
      bid: business.bid,
      // businessServices: business.businessPrices,
    },
    reValidateMode: "onBlur",
    shouldUnregister: false,
  });
  const onSubmit = (data) => {
    const prices = Object.entries(data);
    const businessPrices = [] as any;
    prices.forEach(([key, value]) => {
      if (key === "bid") return;
      businessPrices.push({
        name: key,
        priceFrom: value,
      });
    });
    data = { bid: data.bid };
    data = { ...data, businessPrices };
    console.log(JSON.stringify(data));
    const submit = { data, form };
    onEditInfo(submit);
    handleClose();
  };

  return (
    <div>
      <input type="hidden" {...form.register("bid")} />
      <Button
        onClick={handleClickOpen()}
        className={classes.editButton}
        variant="outlined"
      >
        Edit Prices
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <IconButton
          aria-label="edit"
          style={{ position: "absolute", top: 0, right: 0 }}
          onClick={handleClose}
        >
          <CloseIcon style={{ fontSize: 40 }} />
        </IconButton>
        <DialogTitle id="max-width-dialog-title">
          <Typography className={classes.title}>
            {dictionary["businessInformation"]}
          </Typography>
        </DialogTitle>

        <DialogContent dividers={true}>
          <List>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={1}
            >
              {business.businessPrices.map((price, i) => {
                return (
                  <PriceTextfield
                    key={i}
                    form={form}
                    dictionary={dictionary}
                    price={price}
                  />
                );
              })}
            </Grid>
          </List>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid item style={{ width: "60%", minWidth: 250 }}>
              <Button
                onClick={form.handleSubmit(onSubmit)}
                variant="outlined"
                className="btn-primary"
              >
                {dictionary.confirm}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}
