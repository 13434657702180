import React, { useContext } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Typography } from "@material-ui/core";
import { WelcomeToolbar } from "../../../../components";
import { LanguageContext } from "../../../../contexts";

const styles = (theme) => ({
  root: {
    color: theme.palette.common.white,
    position: "relative",
    display: "flex",
    alignItems: "center",
    // [theme.breakpoints.up("sm")]: {
    //   height: "80vh",
    //   minHeight: 500,
    //   maxHeight: 1300,
    // },
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(14),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  backdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.5,
    zIndex: -1,
  },
  background: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    zIndex: 0,
  },
  arrowDown: {
    position: "absolute",
    bottom: theme.spacing(4),
    zIndex: 3,
  },
  arrowText: {
    position: "absolute",
    bottom: theme.spacing(7),
    zIndex: 3,
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.regular,
  },
});

function ProductHeroLayout(props) {
  const { backgroundClassName, children, classes } = props;
  const { dictionary } = useContext(LanguageContext);

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        {children}
        {/* <div className={classes.backdrop} /> */}
        <div className={clsx(classes.background, backgroundClassName)} />
        <Typography
          variant="body2"
          color="inherit"
          className={classes.arrowText}
        >
          {dictionary.discover}
        </Typography>
        <img
          className={classes.arrowDown}
          src="/static/themes/onepirate/productHeroArrowDown.png"
          height="16"
          width="12"
          alt="arrow down"
        />
      </Container>
    </section>
  );
}

ProductHeroLayout.propTypes = {
  backgroundClassName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles as any)(ProductHeroLayout);
