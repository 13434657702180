import React, { useContext, useEffect, useState } from "react";
import {
  createStyles,
  makeStyles,
  useTheme,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import {
  Checkbox,
  InputBase,
  ListItemText,
  TextField,
  Typography,
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import { AccountContext, DashboardContext } from "../../contexts";
import { BusinessDashboard } from "../../pages";
const CssTextField = withStyles((theme) => ({
  root: theme.outlineTextfield,
}))(TextField);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      // margin: theme.spacing(1),
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      //marginTop: theme.spacing(3),
    },
    label: {
      color: "black",
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      fontSize: 13,
      marginBottom: 5,
    },
    listItem: {
      color: "black",
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.regular,
      fontSize: 15,
    },
  })
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// const spa = [
//   { id: 1, en: "steam/sauna", ar: "sauna" },
//   { id: 2, en: "bath treatment", ar: "bath" },
//   { id: 3, en: "scrub", ar: "scrub" },
//   { id: 4, en: "massage", ar: "massage" },
//   { id: 5, en: "facial", ar: "facial" },
// ] as any;

// const photography = [
//   { id: 1, en: "Wedding Photographer", ar: "Photographer" },
//   { id: 2, en: "Photo Booth Rental", ar: "Photo" },
//   { id: 3, en: "Portrait Photography", ar: "Portrait" },
//   { id: 4, en: "Real Estate Photographer", ar: "Estate" },
//   { id: 5, en: "Event Photographer", ar: "Event" },
// ] as any;

// const saloon = [
//   { id: 1, en: "Haircuts", ar: "Haircuts" },
//   { id: 2, en: "Hair Coloring", ar: "Coloring" },
//   { id: 3, en: "waxing", ar: "waxing" },
//   { id: 4, en: "nail treatments", ar: "nail" },
//   { id: 5, en: "skin care", ar: "skin" },
//   { id: 6, en: "tanning", ar: "tanning" },
//   { id: 7, en: "massage", ar: "massage" },
// ] as any;

const services = [
  { name: "hairStyling", priceFrom: 0 },
  { name: "hairRemoval", priceFrom: 0 },
  { name: "facials", priceFrom: 0 },
  { name: "body", priceFrom: 0 },
  { name: "makeup", priceFrom: 0 },
  { name: "nails", priceFrom: 0 },
  { name: "portraits", priceFrom: 0 },
  { name: "eventPhotos", priceFrom: 0 },
  { name: "commercialPhotos", priceFrom: 0 },
] as any;

// const services = [
//   "haircuts",
//   "haircuts1",
//   "haircuts2",
//   "haircuts3",
//   "haircuts4",
// ] as any;

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelect({ dictionary, business, form }) {
  const classes = useStyles();

  const theme = useTheme();
  const [businessServices, setBusinessServices] = React.useState(
    business.businessServices
  );
  // setBusinessServices(business.businessServices);
  const handleChange = (event) => {
    console.log(event.target.value);
    setBusinessServices(event.target.value);
  };
  return (
    <FormControl fullWidth={true} className={classes.formControl}>
      <Typography className={classes.label}>
        {dictionary.businessServices}
      </Typography>
      <Controller
        control={form.control}
        name="businessServices"
        defaultValue={businessServices}
        render={({ field: { onChange, value } }) => {
          return (
            <CssTextField
              inputRef={form.register(
                "businessServices",
                { name: "businessServices" },
                {
                  required: true,
                  validate: (value) => value.length >= 3,
                }
              )}
              select
              variant="outlined"
              SelectProps={{
                classes: { root: classes.listItem },
                multiple: true,
                value: value,
                renderValue: (selected: any) => {
                  let translated = [] as any;
                  selected.forEach((element) => {
                    translated.push(dictionary[element.name]);
                  });
                  return translated.join(", ");
                },
                onChange: handleChange,
              }}
            >
              {services.map((service, i) => (
                <MenuItem key={i} value={service}>
                  <Checkbox checked={value.includes(service)} />
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography className={classes.listItem}>
                        {dictionary[service.name]}
                      </Typography>
                    }
                    className={classes.listItem}
                  />
                </MenuItem>
              ))}
            </CssTextField>
          );
        }}
      />
      {form.formState.errors.businessServices && (
        <Typography
          className={`form-error-text animate__animated animate__headShake`}
        >
          {dictionary.invalidBusinessServices}
        </Typography>
      )}
    </FormControl>
  );
}
