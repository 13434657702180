import React from "react";
import {
  AppBar,
  Container,
  Grid,
  Link,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  mainFooter: {
    position: "relative",
    bottom: 0,
    width: "100%",
    marginTop: 100,
  },
  text: {
    fontFamily: "Roboto-Bold",
    textAlign: "center",
  },
}));
const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.mainFooter}>
      <AppBar
        position="static"
        style={{
          backgroundColor: "#1b1e3e",
          textAlign: "center",
          height: 50,
        }}
      >
        <Container maxWidth="md">
          <Typography variant="body1" color="inherit" style={{ marginTop: 15 }}>
            © 2021 Beauty Connect Solutions
          </Typography>
        </Container>
      </AppBar>
    </div>
  );
};

export default Footer;
