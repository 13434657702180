import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { ShopSearchBox, Typewriter, WelcomeToolbar } from "..";
import { Images } from "../../themes";
const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    fontFamily: "NotoSansJP-Regular",
    fontSize: 60,
    color: "#1b1e3e",
    textAlign: "center",
    marginTop: 150,
    [theme.breakpoints.down(1000)]: {
      fontSize: 40,
    },
    [theme.breakpoints.down(500)]: {
      fontSize: 30,
    },
  },
  subtext: {
    fontFamily: "NotoSansJP-Light",
    fontSize: 30,
    color: "#1b1e3e",
    textAlign: "center",
    [theme.breakpoints.down(1000)]: {
      fontSize: 24,
    },
    [theme.breakpoints.down(500)]: {
      fontSize: 16,
    },
  },
}));
const TotallyFreeSection = ({ dictionary }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      spacing={1}
      style={{ marginTop: "5%", paddingRight: "10%", paddingLeft: "10%" }}
    >
      <Grid item sm={12}>
        <Typography className={classes.title}>
          It's totally free for business owners.
        </Typography>
      </Grid>
      <Grid item sm={12}>
        <Typography className={classes.subtext}>
          if you hate the complexity of managing your business online, we'll
          simplify it for you.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TotallyFreeSection;
