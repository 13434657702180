import { app, db } from "..";

export const getBusiness = async (
  id,
  { dispatch, history, toggleContinue, toggleActivity }
) => {
  dispatch({ type: "SET_SINGLE_BUSINESS", payload: null });

  console.log("ID IS : " + id);
  toggleActivity(true, "fetching");
  db.collection("links")
    .doc(id)
    .get()
    .then((doc) => {
      if (!doc.exists) return history.replace("/404");
      db.collection("businesses")
        .doc(doc.data()!.bid)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            return history.replace("/404");
          }
          toggleActivity(false);
          dispatch({ type: "SET_SINGLE_BUSINESS", payload: doc.data() });
        })
        .catch(function (error) {
          toggleActivity(false);
          toggleContinue("requestFail", error.code, true);
        });
    })
    .catch(function (error) {
      toggleActivity(false);
      toggleContinue("requestFail", error.code, true);
    });
};
