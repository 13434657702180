import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { Grid, Divider, Link, IconButton } from "@material-ui/core";
import { Images } from "../../../themes";
import BasicInfoForm from "./EditBasicInfo";
import ShareIcon from "@material-ui/icons/Share";
import LinkIcon from "@material-ui/icons/Link";
import { Button } from "@material-ui/core";
import {
  EditBusinessLocation,
  EditBusinessServices,
  EditBusinessPrices,
  BusinessStatusSwitch,
  EditBasicInfo,
  EditOpeningHours,
  EditMediaLinks,
  BusinessTypeSelect,
  QRGenerator,
} from "../..";
import { NotificationContext } from "../../../contexts";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: 30,
    [theme.breakpoints.down(850)]: {
      display: "none",
    },
  },
  cardRoot: {
    minWidth: 275,
    maxWidth: 700,
    width: "90vw",
    //height: 600,
    borderWidth: 0,
    boxShadow: "0 0 10px rgba(33,33,33,.2);",
    borderRadius: 30,
    //padding: 20,
    paddingLeft: 20,
    paddingBottom: 20,

    transition: "box-shadow .3s",
  },
  editButton: {
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    //backgroundColor: "transparent",
    borderColor: "black",
    color: "black",
    borderWidth: 2,
    borderRadius: 5,
    marginTop: 10,
    //opacity: 0.7,
    height: 30,
    fontSize: 13,
    textTransform: "none",
  },
  logo: {
    width: 150,
    height: 150,
    // [theme.breakpoints.down(850)]: {
    //   display: "none",
    // },
  },
  divider: {
    backgroundColor: "black",
    opacity: 0.2,
    height: 250,
    marginLeft: 20,
    width: 2,
    borderRadius: 50,
    [theme.breakpoints.down(850)]: {
      display: "none",
    },
  },
  informationContainer: {
    height: 330,
    width: 480,
    marginLeft: 20,
    [theme.breakpoints.down(850)]: {
      height: 230,
      width: 380,
      marginLeft: 10,
    },
  },
  horiDivider: {
    marginTop: 30,
    marginBottom: 30,
    backgroundColor: "black",
    opacity: 0.2,
    width: 300,
    marginLeft: 90,
    height: 2,
    borderRadius: 50,
    textAlign: "center",
    [theme.breakpoints.down(850)]: {
      width: 100,
      marginLeft: 45,
    },
  },
  title: {
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    fontSize: 13,
    //textAlign: "center",
    opacity: 0.5,
    [theme.breakpoints.down(850)]: {
      fontSize: 10,
    },
  },
  details: {
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    //textAlign: "center",
    fontSize: 16,
    [theme.breakpoints.down(850)]: {
      fontSize: 12,
    },
  },
  link: {
    fontFamily: theme.fonts.name,
    fontWeight: theme.fonts.bold,
    cursor: "pointer",
    fontSize: 16,
    display: "inline-block",
    width: "230px",
    whiteSpace: "nowrap",
    overflow: "hidden !important",
    textOverflow: "ellipsis",
    [theme.breakpoints.down(850)]: {
      fontSize: 12,
    },
  },
  basicInfoEditButtonContainer: {
    position: "absolute",
    minWidth: 275,
    maxWidth: 710,
    width: "90vw",
    [theme.breakpoints.down(850)]: {
      fontSize: 17,
      maxWidth: 500,
    },
  },
  basicInfoEditButton: {
    backgroundColor: "#66bb6a",
    "&:hover": {
      backgroundColor: "#66bb6a",
    },
  },
  rightButtonsContainer: {
    position: "absolute",
    minWidth: 10,
    maxWidth: 150,
    marginTop: 10,
  },
  shareButton: {
    width: 50,
    height: 50,
    marginRight: 80,
    backgroundColor: "#70caee",
    "&:hover": {
      backgroundColor: "#70caee",
    },
  },
  qrButton: {
    width: 50,
    height: 50,
    marginRight: 80,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

export default function BusinessInformationDesktop({
  business,
  dictionary,
  history,
}) {
  const classes = useStyles();
  let url = window.location.host;
  const { toggleToast } = useContext(NotificationContext);

  return (
    <Grid
      direction="column"
      container
      className={classes.root}
      justify="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        <Card className={classes.cardRoot} variant="outlined">
          <EditBasicInfo business={business} dictionary={dictionary} />
          {/* <BusinessStatusSwitch business={business} dictionary={dictionary} /> */}
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing={0}
            className={classes.rightButtonsContainer}
          >
            <Grid item sm={6}>
              <QRGenerator
                link={"https://" + url + "/" + business.link}
                dictionary={dictionary}
              />
            </Grid>
            <Grid item sm={6}>
              <IconButton
                aria-label="edit"
                className={classes.shareButton}
                onClick={() => {
                  navigator.clipboard.writeText(
                    "https://" + url + "/" + business.link
                  );
                  toggleToast("copied", "success");
                }}
              >
                <LinkIcon style={{ color: "white", width: 30, height: 30 }} />
              </IconButton>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            style={{ minHeight: "75%", paddingTop: 30 }}
            spacing={0}
          >
            <Grid item>
              <img
                src={Images[business.businessType + "_icon"]}
                className={classes.logo}
              />
            </Grid>
            <Grid item>
              <Divider
                orientation="vertical"
                flexItem
                className={classes.divider}
              />
            </Grid>
            <Grid item className={classes.informationContainer}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                spacing={8}
                style={{ paddingLeft: 0, paddingTop: 10 }}
              >
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="flex-start"
                    spacing={3}
                  >
                    <Grid item>
                      <Typography className={classes.title}>
                        {dictionary.businessNameAr}
                      </Typography>

                      <Typography className={classes.details}>
                        <span
                          style={{ fontFamily: "Almarai", fontWeight: 700 }}
                        >
                          {business.businessNameAr}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.title}>
                        {dictionary.businessNameEn}
                      </Typography>

                      <Typography className={classes.details}>
                        {business.businessNameEn}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.title}>
                        {dictionary.businessPhone}
                      </Typography>

                      <Typography className={classes.details}>
                        {business.businessPhone}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.title}>
                        {dictionary.BusinessEmailAddress}
                      </Typography>
                      <Typography className={classes.details}>
                        {business.businessEmail || dictionary.notAvailable}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.title}>
                        {dictionary.alluraLink}
                      </Typography>
                      <Link
                        onClick={() => history.push(business.link)}
                        className={classes.link}
                      >
                        {url + "/" + business.link}
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="flex-start"
                    spacing={3}
                  >
                    <Grid item>
                      <Typography className={classes.title}>
                        {dictionary.businessLocation}
                      </Typography>
                      <EditBusinessLocation
                        business={business}
                        dictionary={dictionary}
                      />
                    </Grid>
                    {/* <Grid item>
                      <Typography className={classes.title}>
                        Business Prices
                      </Typography>
                      <EditBusinessPrices
                        business={business}
                        dictionary={dictionary}
                      />
                    </Grid> */}
                    <Grid item>
                      <Typography className={classes.title}>
                        {dictionary.businessServicesTitleOnly}
                      </Typography>
                      <EditBusinessServices
                        business={business}
                        dictionary={dictionary}
                      />
                    </Grid>
                    <Grid item>
                      <Typography className={classes.title}>
                        {dictionary.openingTimes}
                      </Typography>

                      <EditOpeningHours
                        business={business}
                        dictionary={dictionary}
                      />
                    </Grid>
                    <Grid item>
                      <Typography className={classes.title}>
                        {dictionary.mediaLinks}
                      </Typography>

                      <EditMediaLinks
                        business={business}
                        dictionary={dictionary}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}
