import {
  Button,
  Card,
  createStyles,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import {
  EmailTextfield,
  NameTextfield,
  PasswordAgainTextfield,
  PasswordTextfield,
  PersonalNumberTextfield,
} from "../";
import { AccountContext, LanguageContext } from "../../contexts";
const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      color: "black",
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.bold,
      fontSize: 30,
      marginBottom: 5,
      textAlign: "center",
    },
    description: {
      color: "black",
      fontFamily: theme.fonts.name,
      fontWeight: theme.fonts.regular,
      fontSize: 14,
      marginBottom: 5,
      paddingLeft: 20,
      paddingRight: 20,
      textAlign: "center",
    },
  })
);
const NewBusinessRegister = ({ dictionary }) => {
  const { toggleBusinessRegisterStep, multiStepFormData, onSetMultForm } =
    useContext(AccountContext);
  const classes = useStyles();
  const form = useForm({
    defaultValues: multiStepFormData,
    reValidateMode: "onBlur",
    // shouldUnregister: false,
  });
  const onSubmit = (data) => {
    onSetMultForm(data);
    toggleBusinessRegisterStep(2);
  };
  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        style={{ minHeight: "100%" }}
        spacing={3}
      >
        <Grid item style={{ width: "90%" }}>
          <Typography className={classes.title}>
            {dictionary.businessOwnerRegister}
          </Typography>
          <Typography className={classes.description}>
            {dictionary.businessOwnerRegisterDescription}
          </Typography>
        </Grid>
        <Grid item style={{ width: "90%", marginTop: 10 }}>
          <NameTextfield form={form} dictionary={dictionary} />
        </Grid>
        <Grid item style={{ width: "90%" }}>
          <PersonalNumberTextfield form={form} dictionary={dictionary} />
        </Grid>
        <Grid item style={{ width: "90%" }}>
          <EmailTextfield form={form} dictionary={dictionary} />
        </Grid>
        <Grid item style={{ width: "90%" }}>
          <PasswordTextfield form={form} dictionary={dictionary} />
        </Grid>
        <Grid item style={{ width: "90%" }}>
          <PasswordAgainTextfield form={form} dictionary={dictionary} />
        </Grid>
        <Grid item style={{ width: "70%" }}>
          <Button type="submit" variant="outlined" className="btn-primary">
            {dictionary.ok}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default NewBusinessRegister;
