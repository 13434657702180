import React, { createContext, useReducer, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  addBusiness,
  deleteBusiness,
  editInfo,
  getBusinesses,
  addBookingService,
} from "../services";
import { LanguageContext, NotificationContext } from ".";
import { AuthContext } from "./AuthContext";
import { ThemeContext } from "./ThemeContext";
import { AccountContext } from "./AccountContext";

const initialState = {
  isLoading: false,
  businesses: [] as any,
  isAddBusinessModal: false,
  isViewBookingsModal: false,
  isMoreItems: true,
  onGetBusinesses: () => {},
  onAddBusiness: (params) => {},
  onAddBookingService: (params) => {},
  onDeleteBusiness: (params) => {},
  onClear: () => {},
  onEditInfo: (params) => {},
  onToggleAddBusinessModal: () => {},
  onCopyToClipboard: (params) => {},
};

export const DashboardContext = createContext(initialState);

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "REQUEST_SUCCESS":
      return {
        ...state,
        isLoading: false,
        businesses: action.payload,
        isMoreItems: action.isMoreItems,
        isAddBusinessModal: false,
      };
    case "REQUEST_FAIL":
      return {
        ...state,
        isLoading: false,
      };
    case "REQUEST":
      return { ...state, isLoading: true };
    // case "BUSSINESS_ADD_SUCCESS":
    //   return {
    //     ...state,
    //     isAddBusinessModal: false,
    //   };
    case "UPDATE_LIST":
      return { ...state, businesses: action.payload, isLoading: false };
    case "TOGGLE_ADD_BUSINESS_MODAL":
      return { ...state, isAddBusinessModal: !state.isAddBusinessModal };
    case "RESET":
      return initialState;
    default:
      break;
  }
  return state;
};

export const DashboardContextProvider = (props: any) => {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { checkTokenStatus, clearToken } = useContext(AuthContext);
  const { dictionary, language } = useContext(LanguageContext);
  const { isDarkMode } = useContext(ThemeContext);
  const { toggleContinue, toggleToast, toggleActivity, toggleConfirm } =
    useContext(NotificationContext);
  let defaultParams = {
    dispatch,
    state,
    dictionary,
    history,
    toggleContinue,
    toggleToast,
    toggleActivity,
    checkTokenStatus,
    language,
    isDarkMode,
  };
  state.onToggleAddBusinessModal = () => {
    if (state.businesses.length >= 3)
      return toggleContinue("warn", "limitExceeded", true);
    dispatch({ type: "TOGGLE_ADD_BUSINESS_MODAL" });
  };
  state.onClear = () => dispatch({ type: "RESET" });
  state.onGetBusinesses = () => getBusinesses(defaultParams);
  state.onAddBusiness = (submit) =>
    addBusiness(submit, state.onGetBusinesses, defaultParams);
  state.onAddBookingService = (submit) =>
    addBookingService(submit, state.onGetBusinesses, defaultParams);
  state.onEditInfo = (submit) => editInfo(submit, defaultParams);
  state.onDeleteBusiness = (submit) =>
    toggleConfirm("deleteBusiness", "confirmDeleteBusiness", () =>
      deleteBusiness(submit, defaultParams)
    );
  state.onCopyToClipboard = (link) => {
    console.log(JSON.stringify(link));
    navigator.clipboard.writeText(link);
    toggleToast("copied", "success");
  };
  return (
    <DashboardContext.Provider
      value={{
        ...state,
      }}
    >
      {props.children}
    </DashboardContext.Provider>
  );
};
