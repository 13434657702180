import { app, db } from "../config";

export const updateContact = async (
  submit,
  { dispatch, toggleContinue, toggleActivity, toggleToast, state }
) => {
  if (
    state.businessOwner.personalPhone === submit.data.personalPhone &&
    state.businessOwner.email === submit.data.email
  )
    return toggleContinue("warn", "noChanges", true);

  toggleActivity(true, "updating");
  console.log(submit.data);
  dispatch({ type: "REQUEST" });
  let date = Date.now().toString();
  let body = {
    uid: app.auth().currentUser!.uid,
    email: submit.data.email.toLowerCase(),
    personalPhone: submit.data.personalPhone,
    updatedAt: date,
  };
  const contact = app
    .auth()
    .signInWithEmailAndPassword(
      app.auth().currentUser!.email as any,
      submit.data.password
    )
    .then(function (userCredential) {
      console.log(userCredential);
      //if (state.businessOwner.email === submit.data.email) return;
      userCredential!.user!.updateEmail(submit.data.email);
      db.collection("business_owners")
        .doc(app.auth().currentUser!.uid)
        .update(body);
      db.collection("business_owners_history").add(body);
    });

  contact
    .then(() => {
      toggleActivity(false);
      dispatch({
        type: "REQUEST_SUCCESS",
        payload: {
          ...state.businessOwner,
          personalPhone: submit.data.personalPhone,
          email: submit.data.email,
        },
      });
      toggleToast("updated", "success");
      submit.form.setValue("password", "");
    })
    .catch(function (error) {
      toggleActivity(false);
      dispatch({ type: "REQUEST_FAIL" });
      submit.form.setValue("password", "");
      console.log(JSON.stringify(error));
      toggleContinue("requestFail", error.code, true);
    });
};
