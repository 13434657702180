import { app } from "..";

export const signin = async (
  submit,
  { dispatch, state, toggleContinue, toggleActivity, history }
) => {
  dispatch({ type: "REQUEST" });
  toggleActivity(true, "loggingIn");
  app
    .auth()
    .signInWithEmailAndPassword(submit.data.email, submit.data.password)
    .then((user) => {
      toggleActivity(false);
      setTimeout(() => {
        history.replace(`/dashboard`);
      }, 500);
      dispatch({
        type: "RESET",
      });
      submit.form.reset();
    })
    .catch((error) => {
      toggleActivity(false);
      submit.form.setValue("password", "");
      dispatch({ type: "REQUEST_FAIL" });
      toggleContinue("requestFail", error.code, true);
    });
};
