import salon_bg from "../assets/images/salon-bg.jpg";
import salon_icon from "../assets/images/salon-icon.png";
import photography_bg from "../assets/images/photography-bg.jpg";
import photography_icon from "../assets/images/photography-icon.png";
import spa_bg from "../assets/images/spa-bg.jpg";
import spa_icon from "../assets/images/spa-icon.png";
import pin from "../assets/images/pin.png";
import logo from "../assets/images/logo.png";
import shop from "../assets/images/shop.png";
import appointment from "../assets/images/appointment.png";
import user from "../assets/images/user.png";
import exit from "../assets/images/exit.png";
import snapchat from "../assets/images/snapchat.png";
import bannerV1 from "../assets/images/bannerV1.jpg";
import bannerV2 from "../assets/images/bannerV2.jpg";
import bannerV3 from "../assets/images/bannerV3.jpg";
import bannerV4 from "../assets/images/bannerV4.jpg";
import bannerV5 from "../assets/images/bannerV5.jpg";
import diamond from "../assets/images/diamond.png";
import search from "../assets/images/search.png";
import notFound from "../assets/images/not-found.png";
import social from "../assets/images/social.png";
import location from "../assets/images/location.png";
import qr1 from "../assets/images/qr1.png";
import qr2 from "../assets/images/qr2.png";
import qr3 from "../assets/images/qr3.png";
import qr_ar from "../assets/images/qr-ar.png";
import qr_en from "../assets/images/qr-en.png";
import success from "../assets/images/success.png";

export default {
  success,
  qr_en,
  qr_ar,
  qr1,
  qr2,
  qr3,
  social,
  location,
  notFound,
  search,
  diamond,
  bannerV1,
  bannerV2,
  bannerV3,
  bannerV4,
  bannerV5,
  photography_bg,
  spa_bg,
  salon_bg,
  photography_icon,
  spa_icon,
  salon_icon,
  snapchat,
  pin,
  logo,
  shop,
  appointment,
  user,
  exit,
};
